import React, { useState } from "react";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogTitle from "@mui/material/DialogTitle";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import { makeStyles } from "@mui/styles";
import SettingsMenu from "../components/SettingsMenu";
import { useMutation, useApolloClient } from "@apollo/client";
import {
  ACTIVATE_POI,
  SYNC_POI_DELIVERY_PARTNERS,
  CREATE_ORDER_PARTNER,
  CREATE_POI,
  DEACTIVATE_POI,
  DELETE_POI,
  EDIT_MULTIPLE_POI,
  UPDATE_ORDER_PARTNER,
  UPDATE_POI,
  GENERATE_POI_QR_CODE,
  SYNC_POIS_DELIVERY_PARTNERS,
  ATTACH_POIS_DELIVERY_PARTNERS,
  DETACH_POIS_DELIVERY_PARTNERS,
  UPDATE_POIS_DELIVERY_PARTNERS_PRIORITY,
  UPDATE_POIS_DELIVERY_PARTNERS,
} from "./../graphql/mutations";
import { GET_POI } from "./../graphql/queries";
import { Alert, Box, Snackbar, Tooltip, Typography } from "@mui/material";

import PoiDetail from "./PoiDetail";
import PoiConfig from "./PoiConfig";
import PoiDeliveryTabs from "./PoiDeliveryTabs";
import AssignedTeams from "./AssignedTeams/AssignedTeams";
import Button from "@mui/material/Button";
import { useDispatch, useSelector } from "react-redux";
import PoiService from "../services/PoiService";
import { Spinner } from "reactstrap";
import { dropdownOptions, formDetailsTab } from "../reducers/detailTab";
import { detailsFormErrorValidations } from "../reducers/detailsFormValidations";
import { deliveryAreas } from "../reducers/deliveryArea";
import { teamAssign } from "../reducers/assignedTeam";
import { delBoundary } from "../reducers/deliveryBoundary";
import ConfirmationDialog from "../components/ConfirmationDiolog";
import AlertDialog from "../components/AlertDiolog";
import { poiList, reloadPoiList } from "../reducers/poiLists";
import {
  configDefaultValues,
  initializeMultiEditDp,
  configTab,
} from "../reducers/configTab";
import PoiTabsService from "../services/PoiTabsService";
import MultiEditDialog from "../components/MultiEditDialog";
import ReviewTable from "../components/ReviewTable";

const useStyles = makeStyles({
  dialog: {
    position: "absolute",
    maxWidth: "60%",
    right: 0,
  },
});

const tabs = {
  textTransform: "capitalize",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PoiTabs = ({ childFunc }) => {
  const poiService = new PoiService();
  const poiTabsService = new PoiTabsService();
  const classes = useStyles();
  const client = useApolloClient();

  const [value, setValue] = React.useState(0);
  const [tabIndex, setTabIndex] = React.useState(0);
  const [isLoading, setIsLoading] = useState("false");

  const dispatch = useDispatch();
  const user = useSelector((state) => state.userInfo.value);
  const detailsTabSelector = useSelector((state) => state.detailTab.value);
  const poiListSelector = useSelector((state) => state.poiLists.value);
  const configTabSelector = useSelector((state) => state.configTab.value);
  const configDropdownSelector = useSelector(
    (state) => state.configTab.dropdownOptions
  );

  const handleChange = (event, newValue) => {
    switch (event.target.innerText) {
      case "Details":
        setValue(0);
        break;
      case "Configuration":
        setValue(1);
        break;
      case "Assigned Teams":
        setValue(2);
        break;
      case "Delivery Area/Boundary":
        setValue(3);
        break;

      default:
        break;
    }
    setTabIndex(newValue);
  };

  React.useEffect(() => {
    childFunc.current = handleClickOpen;
    if (user.permissions) {
      if (
        user.permissions.poi_viewPoiDetails ||
        user.permissions.poi_editPoiDetails
      ) {
        setValue(0);
      } else if (
        user.permissions.poi_viewPoiConfiguration ||
        user.permissions.poi_editPoiConfiguration
      ) {
        setValue(1);
      } else if (
        user.permissions.poi_viewAssignedTeamsTab ||
        user.permissions.poi_editAssignedTeamsTab
      ) {
        setValue(2);
      } else if (
        user.permissions.poi_editDeliveryAreaTab ||
        user.permissions.poi_viewDeliveryAreaTab ||
        user.permissions.poi_editDeliveryBoundaryTab ||
        user.permissions.poi_viewDeliveryBoundaryTab
      ) {
        setValue(3);
      }
    }
  }, [user]);

  const [open, setOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState("paper");
  const [confirmProps, setConfirmProps] = useState({
    title: "",
    id: "",
    description: "",
    display: false,
  });
  const [alertProps, setAlertProps] = useState({
    title: "",
    description: "",
    display: false,
  });
  const [createPoi, { data, error, loading }] = useMutation(CREATE_POI);
  const [updatePoi] = useMutation(UPDATE_POI);
  const [editMultiplePoi] = useMutation(EDIT_MULTIPLE_POI);
  const [updateDeliveryPartnerMutuate] = useMutation(
    SYNC_POI_DELIVERY_PARTNERS
  );
  const [updateMultipleDeliveryPartnerMutuate] = useMutation(
    SYNC_POIS_DELIVERY_PARTNERS
  );
  const [attachPoisDeliveryPartnersMutation] = useMutation(
      ATTACH_POIS_DELIVERY_PARTNERS
  );
  const [detachPoisDeliveryPartnersMutation] = useMutation(
      DETACH_POIS_DELIVERY_PARTNERS
  );
  const [updatePoisDeliveryPartnersPriorityMutation] = useMutation(
      UPDATE_POIS_DELIVERY_PARTNERS_PRIORITY
  );
  const [updatePoisDeliveryPartnersMutation] = useMutation(
      UPDATE_POIS_DELIVERY_PARTNERS
  );

  const [updateOrderPartnerMutuate] = useMutation(UPDATE_ORDER_PARTNER);
  const [createOrderPartnerMutuate] = useMutation(CREATE_ORDER_PARTNER);
  const [generatePoiQrCode] = useMutation(GENERATE_POI_QR_CODE);
  const [openMultiEditDialog, setOpenMultiEditDialog] = useState(false);
  const [snackBarState, setSnackBarState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
    message: "",
  });

  const handleClickOpen = () => {
    setOpen(true);
    setScroll("paper");
  };

  const handleClose = () => {
    setConfirmProps({
      title: "Cancel",
      id: "cancel",
      description: "All the changes will be discarded. Do you want to proceed?",
      display: true,
    });
  };

  const checkRequiredValidation = () => {
    const checkValidation = poiService.checkRequiredValidation();
    if (checkValidation.message) {
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: checkValidation.message,
      });
    }
    return checkValidation.isValid;
  };

  const checkRequiredValidationMultiEdit = () => {
    const checkValidation = poiService.checkRequiredValidationMultiEdit();
    if (!checkValidation) {
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: "Please fill in the mandatory fields\n",
      });
    }
    return checkValidation;
  };


  const closeSnackBar = () => {
    setSnackBarState({
      open: false,
      vertical: "bottom",
      horizontal: "center",
      message: "",
    });
  };

  const submitMultiEdit = () => {
    const checkForValidation = checkRequiredValidationMultiEdit();
    if (!checkForValidation) return;

    let dataVariable = poiTabsService.prepareDataForMultiEdit();
    dataVariable["ids"] = poiListSelector.multiEditIds.map((item) => item.id).join();

    editMultiplePoi({
      variables: dataVariable,
      onCompleted(successRes) {
        updateMultipleDeliveryPartners();
        handleMultiEditClose();
        dispatch(initializeMultiEditDp());
        updateOrderPartners();
        dispatch(reloadPoiList(true));
        setAlertProps({
          title: "Save",
          description: "POI has been updated successfully",
          display: true,
        });
        setIsLoading("false");
      },
      onError(err) {
        const errMessage = err.graphQLErrors.map((er) => er.message);
        handleMultiEditClose();
        setAlertProps({
          title: "Error",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
      },
    });
  };

  const handleMultiEditClose = () => {
    setOpenMultiEditDialog(false);
  };

  const onClickSave = () => {
    if (poiListSelector.viewType === "multiEdit") {
      setConfirmProps({
        title: "Save",
        id: "multiEdit",
        description:
            "Are you sure to save the changes made to the selected configurations for all the selected POIs?",
        display: true,
      });

    } else {
      const checkForValidation = checkRequiredValidation();
      if (checkForValidation) {
        let dataVariable = poiTabsService.prepareSaveDataForAddEdit();
        setIsLoading("true");

        if (poiListSelector.viewType == "add") {
          if (
            configTabSelector.delivery_partner &&
            configTabSelector.delivery_partner[0].preference
          ) {
            dataVariable["delivery_partner"] =
              configTabSelector.delivery_partner
                .filter((el) => el.preference)
                .map((dp) => ({
                  dp_id: dp.id,
                  preference: dp.preference,
                  switching_time: Number(dp.switching_time) || null,
                }));
          }

          if (configTabSelector.order_partner.partner_type) {
            dataVariable["order_partner"] = {
              partner_type: configTabSelector.order_partner.partner_type,
              partner_store_id:
                configTabSelector.order_partner.partner_reference_id,
            };
          }

          createPoi({
            variables: dataVariable,

            onCompleted(successRes) {
              dispatch(reloadPoiList(true));
              setAlertProps({
                title: "Save",
                description: "POI has been created successfully",
                display: true,
              });
              const poiListFields = poiService.getDeepCopy(poiListSelector);
              poiListFields["viewType"] = "edit";
              poiListFields["selectedPoi"] = successRes.createPoi.data;
              dispatch(poiList(poiListFields));
              setIsLoading("false");
            },
            onError(err) {
              const errMessage = err.graphQLErrors.map((er) => er.message);
              setAlertProps({
                title: "Error",
                description: errMessage,
                display: true,
              });
              setIsLoading("false");
            },
          });
        } else {
          dataVariable["id"] = poiListSelector.selectedPoi;
          updatePoi({
            variables: dataVariable,

            onCompleted(successRes) {
              updateDeliveryPartners();
              updateOrderPartners();

              if (
                (!configTabSelector.delivery_partner ||
                  !configTabSelector.delivery_partner[0].preference) &&
                !configTabSelector.order_partner.partner_type
              ) {
                dispatch(reloadPoiList(true));
                setAlertProps({
                  title: "Save",
                  description: "All changes have been saved successfully",
                  display: true,
                });
                setIsLoading("false");
              }
            },
            onError(err) {
              const errMessage = err.graphQLErrors.map((er) => er.message);
              setAlertProps({
                title: "Error",
                description: errMessage,
                display: true,
              });
              setIsLoading("false");
            },
          });
        }
      }
    }
  };

  const handleGetQR = (qrCode, poiName) => {
    poiService.generateQrCodeFile(qrCode, poiName);
  };

  const handleGenerateQR = (id) => {
    let dataVariable = [];
    dataVariable["id"] = id;
    setIsLoading("true");
    generatePoiQrCode({
      variables: dataVariable,

      onCompleted(successRes) {
        getPoiDetails(id);
      },
      onError(err) {
        const errMessage = err.graphQLErrors.map((er) => er.message);
        setAlertProps({
          title: "Error",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
      },
    });
  };

  const getPoiDetails = async (inPoiId) => {
    setIsLoading("true");
    const tempGetPoi = await client.query({
      query: GET_POI,
      variables: { poiId: inPoiId },
      fetchPolicy: "network-only",
      errorPolicy: "all",
    });
    if (tempGetPoi.errors) {
      const errMessage = tempGetPoi.errors.map((er) => er.message);
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: errMessage,
      });
      setIsLoading("false");
    } else {
      poiService.initializeGetPoi(tempGetPoi);
      setIsLoading("false");
      childFunc.current();
    }
  };
  const updateMultipleDeliveryPartners = () => {
    if (poiListSelector.viewType === "multiEdit") {
      if (configTabSelector.multiEditDp.edit_delivery_partner_sections.checked === "template" && configTabSelector.delivery_partner){
        let partners = configTabSelector.delivery_partner
            .filter((el) => el.preference)
            .map((dp) => ({
              dp_id: dp.id,
              preference: dp.preference,
              switching_time: dp.switching_time || null,
            }));

        const data = {
          client_id: detailsTabSelector.client_id.id,
          pois: poiListSelector.multiEditIds.map((item) => {
            return {
              poi_id: Number(item.id),
              partners: partners,
            };
          }),
        };
        updateMultipleDeliveryPartnerMutuate({
          variables: data,
          onCompleted(successResp) {
            dispatch(reloadPoiList(true));
            setAlertProps({
              title: "Save",
              description: "POI has been updated successfully",
              display: true,
            });
            setIsLoading("false");
          },
          onError(errorResp) {
            const errMessage = errorResp.graphQLErrors.map((er) => er.message);
            setAlertProps({
              title: "Error",
              description: errMessage,
              display: true,
            });
            setIsLoading("false");
            return;
          },
        });
      } else if (configTabSelector.multiEditDp.edit_delivery_partner_sections.checked === "customization"){

          if (configTabSelector.multiEditDp.add_delivery_partner.checked === true){
            attachPoisDeliveryPartners();
          }

          if (configTabSelector.multiEditDp.delete_delivery_partner.checked === true){
            detachPoisDeliveryPartners();
          }

          if (configTabSelector.multiEditDp.update_delivery_partner_priority.checked === true){
            updatePoisDeliveryPartnersPriority();
          }

          if (configTabSelector.multiEditDp.update_delivery_partner_switching_time.checked === true){
            updatePoisDeliveryPartners();
          }

      }
    }
  };

  const attachPoisDeliveryPartners = () => {
      let partners = configTabSelector.delivery_partners_customization.new_dps
          .filter((el) => el.preference)
          .map((dp) => ({
            dp_id: dp.id,
            preference: dp.preference,
            switching_time: dp.switching_time || null,
          }));

      let data = {
        client_id: detailsTabSelector.client_id.id,
        pois: poiListSelector.multiEditIds.map((item) => item.id),
        partners: partners,
      };

    attachPoisDeliveryPartnersMutation({
        variables: data,
        onCompleted(successResp) {
          dispatch(reloadPoiList(true));
          setAlertProps({
            title: "Save",
            description: "POI has been updated successfully",
            display: true,
          });
          setIsLoading("false");
        },
        onError(errorResp) {
          const errMessage = errorResp.graphQLErrors.map((er) => er.message);
          setAlertProps({
            title: "Error",
            description: errMessage,
            display: true,
          });
          setIsLoading("false");
          return;
        },
      });

  };

  const detachPoisDeliveryPartners = () => {
    let data = {
      pois: poiListSelector.multiEditIds.map((item) => item.id),
      partners: configTabSelector.delivery_partners_customization.delete_dps.map((item) => item.id),
    };

    detachPoisDeliveryPartnersMutation({
      variables: data,
      onCompleted(successResp) {
        dispatch(reloadPoiList(true));
        setAlertProps({
          title: "Save",
          description: "POI has been updated successfully",
          display: true,
        });
        setIsLoading("false");
      },
      onError(errorResp) {
        const errMessage = errorResp.graphQLErrors.map((er) => er.message);
        setAlertProps({
          title: "Error",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
        return;
      },
    });
  };

  const updatePoisDeliveryPartnersPriority = () => {

    let partners = configTabSelector.delivery_partners_customization.change_priority_dps
        .filter((el) => el.preference)
        .map((dp) => ({
          dp_id: dp.id,
          preference: dp.preference,
        }));

    let data = {
      pois: poiListSelector.multiEditIds.map((item) => item.id),
      partners: partners,
    };

    updatePoisDeliveryPartnersPriorityMutation({
      variables: data,
      onCompleted(successResp) {
        dispatch(reloadPoiList(true));
        setAlertProps({
          title: "Save",
          description: "POI has been updated successfully",
          display: true,
        });
        setIsLoading("false");
      },
      onError(errorResp) {
        const errMessage = errorResp.graphQLErrors.map((er) => er.message);
        setAlertProps({
          title: "Error",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
        return;
      },
    });
  };

  const updatePoisDeliveryPartners = () => {

    let partners = configTabSelector.delivery_partners_customization.update_dps
        .map((dp) => ({
          dp_id: dp.id,
          switching_time: dp.switching_time,
        }));

    let data = {
      pois: poiListSelector.multiEditIds.map((item) => item.id),
      partners: partners,
    };

    updatePoisDeliveryPartnersMutation({
      variables: data,
      onCompleted(successResp) {
        dispatch(reloadPoiList(true));
        setAlertProps({
          title: "Save",
          description: "POI has been updated successfully",
          display: true,
        });
        setIsLoading("false");
      },
      onError(errorResp) {
        const errMessage = errorResp.graphQLErrors.map((er) => er.message);
        setAlertProps({
          title: "Error",
          description: errMessage,
          display: true,
        });
        setIsLoading("false");
        return;
      },
    });
  };

  const updateDeliveryPartners = () => {
    if (
      configTabSelector.delivery_partner &&
      configTabSelector.delivery_partner[0].preference
    ) {
      let partners = configTabSelector.delivery_partner
        .filter((el) => el.preference)
        .map((dp) => ({
          dp_id: dp.id,
          preference: dp.preference,
          switching_time: dp.switching_time || null,
        }));

      let data = {
        client_id: detailsTabSelector.client_id.id,
        poi_id: poiListSelector.selectedPoi,
        partners: partners,
      };

      updateDeliveryPartnerMutuate({
        variables: data,
        onCompleted(successResp) {
          dispatch(reloadPoiList(true));
          setAlertProps({
            title: "Save",
            description: "POI has been updated successfully",
            display: true,
          });
          setIsLoading("false");
        },
        onError(errorResp) {
          const errMessage = errorResp.graphQLErrors.map((er) => er.message);
          setAlertProps({
            title: "Error",
            description: errMessage,
            display: true,
          });
          setIsLoading("false");
          return;
        },
      });
    }
  };

  const updateOrderPartners = () => {
    if (
      configTabSelector.order_partner.partner_type &&
      configTabSelector.order_partner.id
    ) {
      const tempVariable = {
        id: configTabSelector.order_partner.id,
        partner_reference_id:
          configTabSelector.order_partner.partner_reference_id,
        partner_type: configTabSelector.order_partner.partner_type,
        pickup_number: configTabSelector.number_gln,
      };
      updateOrderPartnerMutuate({
        variables: tempVariable,
        onCompleted(successResp) {
          if (successResp.updateOrderPartner.success == true) {
            dispatch(reloadPoiList(true));
            setAlertProps({
              title: "Save",
              description: "POI has been updated successfully",
              display: true,
            });
            setIsLoading("false");
          } else {
            setAlertProps({
              title: "Error",
              description: successResp.updateOrderPartner.data,
              display: true,
            });
            setIsLoading("false");
          }
        },
        onError(errorResp) {
          const errMessage = errorResp.graphQLErrors.map((er) => er.message);
          setAlertProps({
            title: "Error",
            description: errMessage,
            display: true,
          });
          setIsLoading("false");
        },
      });
    } else {
      if (
        configTabSelector.order_partner.partner_reference_id &&
        configDropdownSelector.orderPartnersList.length > 0
      ) {
        const tempVariable = {
          client_id: detailsTabSelector.client_id.id,
          partner_reference_id:
            configTabSelector.order_partner.partner_reference_id,
          partner_type: configDropdownSelector.orderPartnersList[0].partner_type
            ? configDropdownSelector.orderPartnersList[0].partner_type
            : 0,
          pickup_number: detailsTabSelector.number_gln || 0,
          poi_id: poiListSelector.selectedPoi,
        };
        createOrderPartnerMutuate({
          variables: tempVariable,
          onCompleted(successResp) {
            if (successResp.createOrderPartner.success == true) {
              dispatch(reloadPoiList(true));
              setAlertProps({
                title: "Save",
                description: "POI has been updated successfully",
                display: true,
              });
              setIsLoading("false");
            } else {
              setAlertProps({
                title: "Error",
                description: successResp.createOrderPartner.data,
                display: true,
              });
              setIsLoading("false");
            }
          },
          onError(errorResp) {
            const errMessage = errorResp.graphQLErrors.map((er) => er.message);
            setAlertProps({
              title: "Error",
              description: errMessage,
              display: true,
            });
            setIsLoading("false");
          },
        });
      }
    }
  };

  const selectedSettingsOption = (inOption) => {
    if (inOption !== "close") {
      setConfirmProps(poiService.selectedSettingsOptionsForPoiList(inOption));
    }
  };

  const onAlertClose = () => {
    setAlertProps({ title: "", description: "", display: false });
  };

  const closeTab = (ev) => {
    setValue(0);
    setTabIndex(0);
    dispatch(dropdownOptions({}));
    dispatch(initializeMultiEditDp());
    if (poiListSelector.selectedAccount && poiListSelector.selectedAccount.id) {
      dispatch(
        formDetailsTab({
          client_id: poiListSelector.selectedAccount,
          latitude: 25.2048,
          longitude: 55.2708,
          open_t: "05:00",
          close_t: "04:59",
        })
      );
    } else {
      dispatch(
        formDetailsTab({
          latitude: 25.2048,
          longitude: 55.2708,
          open_t: "05:00",
          close_t: "04:59",
        })
      );
    }
    dispatch(detailsFormErrorValidations({}));
    dispatch(deliveryAreas([]));
    dispatch(teamAssign([]));
    dispatch(delBoundary([]));
    dispatch(configTab(configDefaultValues));
    setOpen(false);
  };

  const onConfirmClick = (ev) => {
    setConfirmProps({ title: "", id: "", description: "", display: false });
    const tempId = poiListSelector.selectedPoi;
    switch (ev.id) {
      case "cancel":
        closeTab();
        break;
      case "activate":
        activatePoi(tempId);
        break;
      case "deactivate":
        deactivatePoi(tempId);
        break;
      case "delete":
        deletePoi(tempId);
        break;
      case "multiEdit":
        submitMultiEdit();
        break;
      default:
        return;
    }
  };

  const activatePoi = async (inPoiIds) => {
    setIsLoading("true");
    await client.mutate({
      mutation: ACTIVATE_POI,
      variables: { ids: inPoiIds },
    });
    dispatch(reloadPoiList({ isReload: true }));
    const tempFields = poiService.getDeepCopy(detailsTabSelector);
    tempFields["is_active"] = 1;
    dispatch(formDetailsTab(tempFields));
    setAlertProps({
      title: "Activate",
      description: "The POI(s) have been activated successfully.",
      display: true,
    });
    setIsLoading("false");
  };

  const deactivatePoi = async (inPoiIds) => {
    setIsLoading("true");
    await client.mutate({
      mutation: DEACTIVATE_POI,
      variables: { ids: inPoiIds },
    });
    dispatch(reloadPoiList({ isReload: true }));
    const tempFields = poiService.getDeepCopy(detailsTabSelector);
    tempFields["is_active"] = 0;
    dispatch(formDetailsTab(tempFields));
    setAlertProps({
      title: "Deactivate",
      description: "The POI(s) have been deactivated successfully.",
      display: true,
    });
    setIsLoading("false");
  };

  const deletePoi = async (inPoiIds) => {
    setIsLoading("true");
    await client.mutate({ mutation: DELETE_POI, variables: { ids: inPoiIds } });
    dispatch(reloadPoiList({ isReload: true }));
    setAlertProps({
      title: "Delete",
      description: "The POI(s) have been deleted successfully.",
      display: true,
    });
    closeTab();
    setIsLoading("false");
  };

  const onCancelClick = (ev) => {
    setConfirmProps({ title: "", id: "", description: "", display: false });
  };

  const getTabsList = () => {
    return poiTabsService.getTabsList();
  };

  const getComponent = (idx) => {
    switch (idx) {
      case 0:
        return <PoiDetail />;
      case 1:
        return <PoiConfig />;
      case 2:
        return <AssignedTeams />;
      case 3:
        return <PoiDeliveryTabs />;
      default:
        return null;
    }
  };

  const descriptionElementRef = React.useRef(null);
  React.useEffect(() => {
    if (open) {
      const { current: descriptionElement } = descriptionElementRef;
      if (descriptionElement !== null) {
        descriptionElement.focus();
      }
    }
  }, [open]);

  return (
    <>
      <Snackbar
        anchorOrigin={{
          vertical: snackBarState.vertical,
          horizontal: snackBarState.horizontal,
        }}
        autoHideDuration={6000}
        open={snackBarState.open}
        key={snackBarState.vertical + snackBarState.horizontal}
        onClose={closeSnackBar}
      >
        <Alert onClose={closeSnackBar} severity="error" sx={{ width: "30%" }}>
          {snackBarState.message}
        </Alert>
      </Snackbar>
      <ConfirmationDialog
        confirmProps={confirmProps}
        onConfirmClick={onConfirmClick}
        onCancelClick={onCancelClick}
      />
      {/*{openMultiEditDialog && (*/}
      {/*  <MultiEditDialog*/}
      {/*    onConfirmClick={submitMultiEdit}*/}
      {/*    open={openMultiEditDialog}*/}
      {/*    handleClose={handleMultiEditClose}*/}
      {/*  >*/}
      {/*    <ReviewTable />*/}
      {/*  </MultiEditDialog>*/}
      {/*)}*/}
      <AlertDialog alertProps={alertProps} onAlertClose={onAlertClose} />
      <Dialog
        fullScreen
        classes={{
          paper: classes.dialog,
        }}
        open={open}
        onClose={handleClose}
        scroll={scroll}
        aria-labelledby="scroll-dialog-title"
        aria-describedby="scroll-dialog-description"
      >
        <DialogTitle id="scroll-dialog-title">
          <Box sx={{ display: "flex", justifyContent: "space-between" }}>
            <Typography
              component={"div"}
              sx={{ fontSize: "1rem !important", fontWeight: "500" }}
            >
              {poiListSelector.viewType == "edit" ||
              poiListSelector.viewType == "multiEdit"
                ? "Edit POI"
                : "Add POI"}
            </Typography>
            {poiListSelector.viewType == "edit" &&
              user.permissions &&
              (user.permissions.poi_activatePois ||
                user.permissions.poi_deactivatePois ||
                user.permissions.poi_deletePois) && (
                <SettingsMenu
                  pageType={"edit"}
                  onSelectSettingsOption={(ev) => selectedSettingsOption(ev)}
                ></SettingsMenu>
              )}
          </Box>

          <Box>
            <Tabs
              value={tabIndex}
              onChange={handleChange}
              aria-label="POI Tabs"
            >
              {poiListSelector.viewType === "multiEdit" ? (
                <Tab style={tabs} label={"Configuration"} {...a11yProps(0)} />
              ) : (
                getTabsList().map((el, idx) =>
                  el.isDisabled ? (
                    <Tooltip
                      key={idx}
                      arrow
                      title={
                        el.isDisabled &&
                        "Please select Account, Country, City to enable this"
                      }
                    >
                      <div>
                        <Tab
                          style={tabs}
                          label={el.name}
                          disabled={el.isDisabled}
                          {...a11yProps(el.idx)}
                        />
                      </div>
                    </Tooltip>
                  ) : (
                    <Tab
                      key={idx}
                      style={tabs}
                      label={el.name}
                      {...a11yProps(0)}
                    />
                  )
                )
              )}
            </Tabs>
          </Box>
        </DialogTitle>
        <DialogContent
          dividers={scroll === "paper"}
          sx={{ paddingRight: 0, paddingLeft: 0 }}
          tabIndex={-1}
          style={{ opacity: isLoading === "true" ? "0" : "1" }}
        >
          {poiListSelector.viewType === "multiEdit" ? (
            <TabPanel value={0} index={0}>
              <PoiConfig />
            </TabPanel>
          ) : (
            getTabsList().map((el) => (
              <TabPanel value={value} index={el.idx} key={el.idx}>
                {getComponent(value)}
              </TabPanel>
            ))
          )}
        </DialogContent>
        <Spinner
          color="dark"
          style={{
            position: "absolute",
            margin: "auto",
            top: "50%",
            left: "50%",
            display: isLoading === "true" ? "block" : "none",
          }}
        />
        <DialogActions style={{ justifyContent: "space-between" }}>
          <Button variant="outlined" onClick={handleClose}>
            Cancel
          </Button>
          {poiListSelector.viewType !== "add" &&
            user.permissions &&
            (user.permissions.poi_editPoiDetails ||
              user.permissions.poi_editPoiConfiguration ||
              user.permissions.poi_editAssignedTeamsTab ||
              user.permissions.poi_editDeliveryAreaTab ||
              user.permissions.poi_editDeliveryBoundaryTab) && (
              <div style={{ display: "flex", gap: "8px" }}>
                {user.permissions.poi_getQrCode &&
                  detailsTabSelector.qr_code !== null &&
                  detailsTabSelector.qr_code !== "" &&
                  poiListSelector.viewType !== "multiEdit" && (
                    <Button
                      variant="outlined"
                      onClick={() =>
                        handleGetQR(
                          detailsTabSelector.qr_code,
                          detailsTabSelector.name
                        )
                      }
                    >
                      Get QR
                    </Button>
                  )}
                {user.permissions.poi_generateQrCode && poiListSelector.viewType !== "multiEdit" && (
                  <Button
                    variant="outlined"
                    onClick={() => handleGenerateQR(detailsTabSelector.id)}
                  >
                    Generate QR
                  </Button>
                )}
                <Button variant="outlined" onClick={onClickSave}>
                  Save
                </Button>
              </div>
            )}
          {poiListSelector.viewType === "add" && (
            <Button variant="outlined" onClick={onClickSave}>
              Save
            </Button>
          )}
        </DialogActions>
      </Dialog>
    </>
  );
};

export default PoiTabs;
