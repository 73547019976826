import userInfoReducer from "./user";
import assignedTeamReducer from "./assignedTeam";
import deliveryAreaReducer from "./deliveryArea";
import detailsTabReducer from "./detailTab";
import detailsFormValidationsReducer from "./detailsFormValidations";
import deliveryBoundaryReducer from "./deliveryBoundary";
import poiListReducer from "./poiLists";
import configTabReducer from "./configTab";
import { combineReducers } from "redux";

const rootReducers = combineReducers({
  userInfo: userInfoReducer,
  assignedTeams: assignedTeamReducer,
  deliveryAreas: deliveryAreaReducer,
  detailTab: detailsTabReducer,
  detailsFormValidations: detailsFormValidationsReducer,
  deliveryBoundary: deliveryBoundaryReducer,
  poiLists: poiListReducer,
  configTab: configTabReducer
});

export default rootReducers;
