import {
  Alert,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Snackbar,
  Tooltip,
  tooltipClasses,
  Typography,
} from "@mui/material";
import {
  DrawingManager,
  GoogleMap,
  LoadScript,
  Polygon,
} from "@react-google-maps/api";
import React, { useEffect } from "react";
import InfoIcon from "@mui/icons-material/Info";

import "./DeliveryBoundary.css";
import { styled } from "@mui/material/styles";
import { useDispatch, useSelector } from "react-redux";
import { delBoundary } from "../../reducers/deliveryBoundary";

const containerStyleForMap = {
  width: "100%",
  height: "100%",
};

const HtmlTooltip = styled(({ className, ...props }) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(({ theme }) => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: "#f5f5f9",
    color: "rgba(0, 0, 0, 0.87)",
    maxWidth: 220,
    fontSize: theme.typography.pxToRem(12),
    border: "1px solid #dadde9",
  },
}));

const DeliveryBoundary = () => {
  const dispatch = useDispatch();
  const delBoundarySelector = useSelector(
    (state) => state.deliveryBoundary.value
  );
  const user = useSelector((state) => state.userInfo.value);

  const [latLong, setLatLong] = React.useState({ lat: 25.2048, lng: 55.2708 });
  const [mapZoom, setMapZoom] = React.useState(12);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [polygonRef, setPolygonRef] = React.useState(null);
  const [polyPaths, setPolyPaths] = React.useState(null);
  const [dialogSnackBarState, setDialogSnackBarState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    type: "success",
    message: "",
  });
  const [snackBarState, setSnackBarState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    type: "success",
    message: "",
  });
  const selectedFileName = React.useRef(null);
  const uploadedFile = React.useRef(null);
  const mapOptions = {
    fillColor: "lightblue",
    fillOpacity: 0.5,
    strokeColor: "red",
    strokeOpacity: 1,
    strokeWeight: 2,
    clickable: true,
    draggable: user.permissions.poi_editDeliveryBoundaryTab ? true : false,
    editable: user.permissions.poi_editDeliveryBoundaryTab ? true : false,
    geodesic: true,
    zIndex: 1,
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
    uploadedFile.current = null;
    selectedFileName.current = null;
  };

  const onClickInitialUploadKML = () => {
    closeSnackBar();
    closeDialogSnackBar();
    setOpenDialog(true);
  };

  const onPolygonComplete = (inPolygon) => {
    const polygonBounds = inPolygon.getPath();
    const bounds = [];
    for (let i = 0; i < polygonBounds.length; i++) {
      const point = {
        lat: polygonBounds.getAt(i).lat(),
        lng: polygonBounds.getAt(i).lng(),
      };
      bounds.push(point);
    }
    setPolyPaths(bounds);
    inPolygon.setMap(null);
  };

  const onPolygonLoad = (inPolygon) => {
    setPolygonRef(inPolygon);
    const polygonBounds = inPolygon.getPath();
    const bounds = [];
    for (let i = 0; i < polygonBounds.length; i++) {
      const point = {
        lat: polygonBounds.getAt(i).lat(),
        lng: polygonBounds.getAt(i).lng(),
      };
      bounds.push(point);
      // set center point for zoom
      if (i === 0){
        setLatLong({ lat: polygonBounds.getAt(i).lat(), lng: polygonBounds.getAt(i).lng() });
      }
    }
    setPolyPaths(bounds);
    dispatch(delBoundary(bounds));
  };

  const onPolygonMouseUp = (ev) => {
    const polygonBounds = polygonRef.getPath();
    const bounds = [];
    for (let i = 0; i < polygonBounds.length; i++) {
      const point = {
        lat: polygonBounds.getAt(i).lat(),
        lng: polygonBounds.getAt(i).lng(),
      };
      bounds.push(point);
    }
    setPolyPaths(bounds);
    dispatch(delBoundary(bounds));
  };

  const handleKMLInputChange = (e) => {
    closeSnackBar();
    closeDialogSnackBar();
    const extension = e.target.value
      .substring(e.target.value.lastIndexOf(".") + 1)
      .toLowerCase();

    if (extension === "kml") {
      uploadedFile.current = e;
      selectedFileName.current = e.target.files[0].name;
    } else {
      uploadedFile.current = null;
      setDialogSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        type: "error",
        message: "This file type is not supported.",
      });
    }
  };

  const uploadKML = () => {
    const file = uploadedFile.current.target.files[0];
    let fileReader = new FileReader();
    fileReader.onload = async (e) => {
      let result = await extractGoogleCoords(e.target.result);
      if (result) {
        if (result.length === 1) {
          setPolyPaths(result[0]);
          setLatLong({ lat: result[0][0].lat, lng: result[0][0].lng });
          setMapZoom(12);
          dispatch(delBoundary(result[0]));
          setSnackBarState({
            open: true,
            vertical: "top",
            horizontal: "center",
            type: "success",
            message: "File Uploaded",
          });
          handleDialogClose();
        } else {
          setDialogSnackBarState({
            open: true,
            vertical: "top",
            horizontal: "center",
            type: "error",
            message: "KML file should not have more than one polygon",
          });
        }
      } else {
        setDialogSnackBarState({
          open: true,
          vertical: "top",
          horizontal: "center",
          type: "error",
          message: "Something went wrong! Please try other file.",
        });
      }
    };
    fileReader.readAsText(file);
  };

  const extractGoogleCoords = async (plainText) => {
    let parser = new DOMParser();
    let xmlDoc = parser.parseFromString(plainText, "text/xml");
    let googlePolygons = [];

    if (xmlDoc.documentElement.nodeName == "kml") {
      for (const item of xmlDoc.getElementsByTagName("Placemark")) {
        let placeMarkName = item
          .getElementsByTagName("name")[0]
          .childNodes[0].nodeValue.trim();
        let polygons = item.getElementsByTagName("Polygon");
        let markers = item.getElementsByTagName("Point");

        for (const polygon of polygons) {
          let coords = polygon
            .getElementsByTagName("coordinates")[0]
            .childNodes[0].nodeValue.trim();
          let points = coords.split(" ");

          let googlePolygonsPaths = [];
          for (const point of points) {
            let coord = point.split(",");
            if (coord[1] && coord[0]) {
              googlePolygonsPaths.push({ lat: +coord[1], lng: +coord[0] });
            }
          }
          googlePolygons.push(googlePolygonsPaths);
        }
      }
    } else {
      throw "error while parsing";
    }

    return googlePolygons;
  };

  const closeDialogSnackBar = () => {
    setDialogSnackBarState({
      open: false,
      vertical: "top",
      horizontal: "center",
      type: "success",
      message: "",
    });
  };

  const closeSnackBar = () => {
    setSnackBarState({
      open: false,
      vertical: "top",
      horizontal: "center",
      type: "success",
      message: "",
    });
  };

  useEffect(() => {
    if (delBoundarySelector && delBoundarySelector.length) {
      const newPoints = delBoundarySelector.map((boundary) => ({
        lat: boundary.lat,
        lng: boundary.lng,
      }));
      setPolyPaths(newPoints);
    }
  }, []);

  return (
    <div className="boundary-wrapper">
      <Snackbar
        anchorOrigin={{
          vertical: snackBarState.vertical,
          horizontal: snackBarState.horizontal,
        }}
        autoHideDuration={6000}
        onClose={closeSnackBar}
        open={snackBarState.open}
        key={snackBarState.vertical + snackBarState.horizontal}
      >
        <Alert
          onClose={closeSnackBar}
          severity={snackBarState.type}
          sx={{ width: "100%" }}
        >
          {snackBarState.message}
        </Alert>
      </Snackbar>
      <Dialog
        open={openDialog}
        onClose={handleDialogClose}
        sx={{ width: "100%" }}
        maxWidth="xs"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="align-center"
      >
        <DialogTitle id="alert-dialog-title">Upload KML</DialogTitle>
        <DialogContent>
          <Snackbar
            anchorOrigin={{
              vertical: dialogSnackBarState.vertical,
              horizontal: dialogSnackBarState.horizontal,
            }}
            autoHideDuration={6000}
            onClose={closeDialogSnackBar}
            open={dialogSnackBarState.open}
            key={dialogSnackBarState.vertical + dialogSnackBarState.horizontal}
          >
            <Alert
              onClose={closeDialogSnackBar}
              severity={dialogSnackBarState.type}
              sx={{ width: "100%" }}
            >
              {dialogSnackBarState.message}
            </Alert>
          </Snackbar>

          <input
            style={{ display: "none" }}
            id="contained-button-file"
            accept=".kml"
            type="file"
            onChange={handleKMLInputChange}
          />
          <label htmlFor="contained-button-file">
            <Button variant="contained" color="primary" component="span">
              {selectedFileName.current ? selectedFileName.current : "Upload"}
            </Button>

            <HtmlTooltip
              title={
                <React.Fragment>
                  <Typography color="inherit">Pre-upload checklist:</Typography>
                  <ul sx={{ paddingLeft: "1rem" }}>
                    <li>Only KML file is supported</li>
                    <li>KML file should not have more than one polygon.</li>
                  </ul>
                </React.Fragment>
              }
            >
              <InfoIcon sx={{ marginLeft: 1 }}></InfoIcon>
            </HtmlTooltip>
          </label>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleDialogClose}>Cancel</Button>
          <Button
            onClick={uploadKML}
            autoFocus
            disabled={!user.permissions.poi_editDeliveryBoundaryTab}
          >
            Apply
          </Button>
        </DialogActions>
      </Dialog>
      <div className="upload-kml-container">
        <Button
          variant="contained"
          onClick={onClickInitialUploadKML}
          disabled={!user.permissions.poi_editDeliveryBoundaryTab}
        >
          Upload KML
        </Button>
      </div>
      <LoadScript
        googleMapsApiKey="AIzaSyDT4BxUMab89jQzhUfnxl8XUZNORb_0tLg"
        libraries={["drawing"]}
      >
        <GoogleMap
          mapContainerStyle={containerStyleForMap}
          center={latLong}
          zoom={mapZoom}
        >
          {polyPaths ? (
            <Polygon
              onLoad={onPolygonLoad}
              paths={polyPaths}
              options={mapOptions}
              onMouseUp={onPolygonMouseUp}
            />
          ) : (
            <DrawingManager
              options={{
                drawingControl: user.permissions.poi_editDeliveryBoundaryTab
                  ? true
                  : false,
                drawingControlOptions: {
                  drawingModes: ["polygon"],
                },
              }}
              onPolygonComplete={onPolygonComplete}
            />
          )}
        </GoogleMap>
      </LoadScript>
    </div>
  );
};

export default DeliveryBoundary;
