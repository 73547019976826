import React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import SettingsIcon from "@mui/icons-material/Settings";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import { useSelector } from "react-redux";

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function SettingsMenu(props) {
  const user = useSelector((state) => state.userInfo.value);
  const detailsTabSelector = useSelector((state) => state.detailTab.value);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    props.onSettingsClick();
  };
  const handleClose = (ev) => {
    setAnchorEl(null);
    props.onSelectSettingsOption(ev);
  };

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        size="small"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
        disabled={props.isDisabledState}
      >
        <SettingsIcon></SettingsIcon>
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={() => handleClose("close")}
      >
        {props.pageType === "edit" &&
          user.permissions &&
          user.permissions.poi_activatePois && (
            <MenuItem
              onClick={() =>
                handleClose(
                  detailsTabSelector.is_active == 1 ? "deactivate" : "activate"
                )
              }
              disableRipple
            >
              {detailsTabSelector.is_active == 1 ? "Deactivate" : "Activate"}
            </MenuItem>
          )}
        {props.pageType === "dashboard" &&
          user.permissions &&
          user.permissions.poi_activatePois && props.hideOptions.showActivate && (
            <MenuItem onClick={() => handleClose("activate")} disableRipple>
              Activate
            </MenuItem>
          )}
        {props.pageType === "dashboard" &&
          user.permissions &&
          user.permissions.poi_deactivatePois && props.hideOptions.showDeactivate && (
            <MenuItem onClick={() => handleClose("deactivate")} disableRipple>
              Deactivate
            </MenuItem>
          )}
        {user.permissions && user.permissions.poi_deletePois && (
          <MenuItem onClick={() => handleClose("delete")} disableRipple>
            Delete
          </MenuItem>
        )}
      </StyledMenu>
    </div>
  );
}
