import React from "react";
import ReactDOM from "react-dom";
import "./index.css";
import App from "./App";
import { ApolloClient, ApolloProvider, InMemoryCache } from "@apollo/client";
import { Provider } from "react-redux";
import { configureStore } from "@reduxjs/toolkit";
import rootReducers from "./reducers";
import Cookies from "js-cookie";

const apiKey = Cookies.get("symfony");
const uri = process.env.REACT_APP_GRAPHQL_URL;

const client = new ApolloClient({
  fetchOptions: {
    credentials: "include",
  },
  headers: {
    // "x-api-key": apiKey,
    "Authorization": apiKey,
  },
  uri: uri,
  cache: new InMemoryCache()
});

const appStore = configureStore({
  reducer: rootReducers,
});

ReactDOM.render(
  <ApolloProvider client={client}>
    <Provider store={appStore}>
      <React.StrictMode>
        <App />
      </React.StrictMode>
    </Provider>
  </ApolloProvider>,
  document.getElementById("root")
);
