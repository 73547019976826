import Box from "@mui/material/Box";
import Tab from "@mui/material/Tab";
import Tabs from "@mui/material/Tabs";
import PropTypes from "prop-types";
import * as React from "react";
import { useSelector } from "react-redux";
import ConfigurationsService from "../services/ConfigurationsService";
import PoiAdvancedConfig from "./Configurations/PoiAdvancedConfig";
import PoiBasicConfig from "./Configurations/PoiBasicConfig";

const tabs = {
  textTransform: "capitalize",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PoiConfig = () => {
  const [value, setValue] = React.useState(0);
  const [enable, setEnable] = React.useState(true);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  const handleEnableChange = () => {
    setEnable((prev) => !prev);
  };

  const user = useSelector((state) => state.userInfo.value);
  const configurationService = new ConfigurationsService();

  return (
    <>
      {configurationService.isDeliveryPartnerView(user.permissions) ? (
        <PoiAdvancedConfig
          enabled={enable}
          handleEnableChange={handleEnableChange}
        />
      ) : (
        <>
          <Tabs
            value={value}
            onChange={handleChange}
            aria-label="Configuration Tabs"
          >
            <Tab style={tabs} label="Basic" {...a11yProps(0)} />
            <Tab style={tabs} label="Advanced" {...a11yProps(1)} />
          </Tabs>
          <TabPanel value={value} index={0}>
            <PoiBasicConfig />
          </TabPanel>
          <TabPanel value={value} index={1}>
            <PoiAdvancedConfig
              handleEnableChange={handleEnableChange}
              enabled={!enable}
            />
          </TabPanel>
        </>
      )}
    </>
  );
};

export default PoiConfig;
