import React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';

const ConfirmationDialog = (props) => {

    React.useEffect(() => {
    }, []);

    const handleClose = () => {
        props.onCancelClick();
    };

    const handleAlerClose = () => {
        props.onConfirmClick(props.confirmProps);
    };

    return (
        <div>
            <Dialog
                open={props.confirmProps.display}
                onClose={handleClose}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
                    {props.confirmProps.title}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText id="alert-dialog-description">
                        {props.confirmProps.description}
                    </DialogContentText>
                </DialogContent>
                <DialogActions sx={{ display: 'flex', justifyContent: 'space-between' }}>
                    <Button onClick={handleClose}>No</Button>
                    <Button onClick={handleAlerClose} autoFocus>
                        Yes
                    </Button>
                </DialogActions>
            </Dialog>
        </div >
    );
}

export default ConfirmationDialog;