import * as React from "react";
import PropTypes from "prop-types";
import Tabs from "@mui/material/Tabs";
import Tab from "@mui/material/Tab";
import Box from "@mui/material/Box";
import DeliveryBoundary from "./DeliveryBoundary/DeliveryBoundary";
import DeliveryArea from "./DeliveryArea/DeliveryArea";
import { Alert } from "@mui/material";

const tabs = {
  textTransform: "capitalize",
};

function TabPanel(props) {
  const { children, value, index, ...other } = props;

  return (
    <div
      role="tabpanel"
      hidden={value !== index}
      id={`simple-tabpanel-${index}`}
      aria-labelledby={`simple-tab-${index}`}
      {...other}
    >
      {value === index && (
        <Box sx={{ p: 3 }}>
          <>{children}</>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  value: PropTypes.number.isRequired,
};

function a11yProps(index) {
  return {
    id: `simple-tab-${index}`,
    "aria-controls": `simple-tabpanel-${index}`,
  };
}

const PoiDeliveryTabs = () => {
  const [value, setValue] = React.useState(0);

  const handleChange = (event, newValue) => {
    setValue(newValue);
  };

  return (
    <div>
      <Alert icon={false} sx={{ marginBottom: 2 }}>
        It is essential to either configure delivery area or delivery boundary
        so that the system can use this configuration to improve the accuracy of
        customer location (lat, long).
      </Alert>
      <Tabs
        value={value}
        onChange={handleChange}
        aria-label="Configuration Tabs"
      >
        <Tab style={tabs} label="Delivery Areas" {...a11yProps(0)} />
        <Tab style={tabs} label="Delivery Boundary" {...a11yProps(1)} />
      </Tabs>
      <TabPanel value={value} index={0}>
        <DeliveryArea />
      </TabPanel>
      <TabPanel value={value} index={1} className="del-panels">
        <DeliveryBoundary />
      </TabPanel>
    </div>
  );
};

export default PoiDeliveryTabs;
