import React, { useEffect, useState } from "react";
import {
  Alert,
  Autocomplete,
  Box,
  Button,
  Grid,
  Input,
  MenuItem,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import InfoIcon from "@mui/icons-material/Info";
import Tooltip from "@mui/material/Tooltip";
import PersonIcon from "@mui/icons-material/Person";
import {
  GoogleMap,
  LoadScript,
  Marker,
  StandaloneSearchBox,
} from "@react-google-maps/api";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  GET_ACCOUNTS,
  GET_CITY_LIST,
  GET_DETAILS_TAB_EDIT_DROPDOWNS_WITHOUT_COUNTRY,
  GET_DETAILS_TAB_EDIT_DROPDOWNS_WITH_COUNTRY,
  GET_DETAILS_TAB_FILTERS,
} from "./../graphql/queries";
import { Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { dropdownOptions, formDetailsTab } from "../reducers/detailTab";
import { detailsFormErrorValidations } from "../reducers/detailsFormValidations";
import PoiService from "../services/PoiService";
import { poiList } from "../reducers/poiLists";

const containerStyleForMap = {
  width: "100%",
  height: "300px",
};

const mapLibraries = ["places"];

const googleMapAPIKey = "AIzaSyDT4BxUMab89jQzhUfnxl8XUZNORb_0tLg";

const PoiDetail = (props) => {
  const poiService = new PoiService();

  const detailsTabSelector = useSelector((state) => state.detailTab.value);
  const dropdownOptionsSelector = useSelector(
    (state) => state.detailTab.dropdownOptions
  );
  const errorValidationSelector = useSelector(
    (state) => state.detailsFormValidations.value
  );
  const user = useSelector((state) => state.userInfo.value);
  const poiListSelector = useSelector((state) => state.poiLists.value);

  const client = useApolloClient();
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState("false");
  const [account, setAccount] = useState([]);
  const [vertical, setVertical] = useState([]);
  const [mapRef, setMapRef] = useState(null);
  const [mapZoom, setMapZoom] = useState(12);
  const [snackBarState, setSnackBarState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    type: "success",
    message: "",
  });
  const tempImage = React.useRef("");

  const onMapLoad = (ev) => {
    setMapRef(ev);
  };

  const onPlacesChanged = () => {
    const tempPlace = mapRef.getPlaces() ? mapRef.getPlaces()[0] : null;
    if (tempPlace && tempPlace.geometry && tempPlace.geometry.location) {
      const tempFields = poiService.getDeepCopy(detailsTabSelector);
      tempFields["latitude"] = tempPlace.geometry.location.lat();
      tempFields["longitude"] = tempPlace.geometry.location.lng();
      setMapZoom(18);
      dispatch(formDetailsTab(tempFields));
    }
  };

  const handleFileInputChange = (e) => {
    if (!e.target.value) {
      return false;
    }
    const extension = e.target.value
      .substring(e.target.value.lastIndexOf(".") + 1)
      .toLowerCase();

    if (extension !== "png" && extension !== "jpeg" && extension !== "jpg") {
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        type: "error",
        message: "Invalid File",
      });
      return false;
    }

    closeSnackBar();
    const file = e.target.files[0];
    let baseURL;
    let reader = new FileReader();

    reader.readAsDataURL(file);

    reader.onload = () => {
      baseURL = reader.result;
      const tempFields = poiService.getDeepCopy(detailsTabSelector);
      tempFields["logo"] = baseURL;
      dispatch(formDetailsTab(tempFields));
    };
    tempImage.current = URL.createObjectURL(file);
    reader.addEventListener("load", () => {
      tempImage.current = reader.result;
    });
    reader.readAsDataURL(e.target.files[0]);
  };

  const onMarkerClick = (ev) => {
    if (ev && ev.latLng) {
      const tempFields = poiService.getDeepCopy(detailsTabSelector);
      tempFields["latitude"] = ev.latLng.lat();
      tempFields["longitude"] = ev.latLng.lng();
      dispatch(formDetailsTab(tempFields));
    }
  };

  async function getDropdownsOptions(inClientId) {
    setIsLoading("true");
    const tempGetFilterList = await client.query({
      query: GET_DETAILS_TAB_FILTERS,
      variables: { clientId: inClientId },
      fetchPolicy: "network-only",
    });
    const tempOptions = poiService.getDeepCopy(dropdownOptionsSelector);
    tempOptions["brandLists"] = tempGetFilterList.data.listBrands;
    tempOptions["countryLists"] = tempGetFilterList.data.listCountries;
    tempOptions["currencyLists"] = tempGetFilterList.data.listCurrencies;
    tempOptions["timezoneLists"] = tempGetFilterList.data.listTimezones;
    if (account) {
      tempOptions["accountsList"] = account;
    }
    tempOptions["verticalLists"] = tempGetFilterList.data.listVerticals;
    await dispatch(dropdownOptions(tempOptions));
    setVertical(tempGetFilterList.data.listVerticals);
    setIsLoading("false");
  }

  async function getCityList(inCountryId) {
    setIsLoading("true");
    const tempGetCityList = await client.query({
      query: GET_CITY_LIST,
      variables: { country_id: inCountryId },
      fetchPolicy: "network-only",
    });
    const tempCityOptions = poiService.getDeepCopy(dropdownOptionsSelector);
    tempCityOptions["cityLists"] = tempGetCityList.data.listCities;
    await dispatch(dropdownOptions(tempCityOptions));
    setIsLoading("false");
  }

  const handleChange = (value, inField) => {
    const tempFields = poiService.getDeepCopy(detailsTabSelector);
    tempFields[inField] = value;
    dispatch(formDetailsTab(tempFields));

    const tempValidation = poiService.getDeepCopy(errorValidationSelector);
    if (inField === "contact_email") {
      if (value) {
        const validate = poiService.validateMail(value);
        tempValidation["contact_email"] = validate ? "" : "Invalid Email";
      } else {
        tempValidation["contact_email"] = "";
      }
    } else if(inField === "store_manager_phone"){
      if (value) {
        const validate = poiService.validatePhoneNumber(value);
        tempValidation["store_manager_phone"] = validate ? "" : "Invalid phone number";
      } else {
        tempValidation["store_manager_phone"] = "";
      }

    } else if(inField === "area_manager_phone"){
      if (value) {
        const validate = poiService.validatePhoneNumber(value);
        tempValidation["area_manager_phone"] = validate ? "" : "Invalid phone number";
      } else {
        tempValidation["area_manager_phone"] = "";
      }

    } else if(inField === "contact_phone"){
      if (value) {
        const validate = poiService.validatePhoneNumber(value);
        tempValidation["contact_phone"] = validate ? "" : "Invalid phone number";
      } else {
        tempValidation["contact_phone"] = "";
      }

    } else {
      tempValidation[inField] = value ? "" : "Field is required";
    }

    dispatch(detailsFormErrorValidations(tempValidation));
  };

  const onDropdownSelection = (value, type) => {
    const tempFields = poiService.getDeepCopy(detailsTabSelector);
    const tempValidation = poiService.getDeepCopy(errorValidationSelector);
    if (type === "client_id") {
      if (value) {
        const dependentFields = [
          "order_type",
          "brand_ids",
          "country_id",
          "city_id",
          "currency",
          "timezone",
        ];
        dependentFields.forEach((field) => {
          tempFields[field] = "";
          tempValidation[field] = "Field is required";
        });
        getDropdownsOptions(value.id);
      } else {
        tempFields[type] = value;
        const tempOptions = {
          brandLists: [],
          countryLists: [],
          cityLists: [],
          currencyLists: [],
          timezoneLists: [],
        };
        dispatch(dropdownOptions(tempOptions));
      }
      const poiListFields = poiService.getDeepCopy(poiListSelector);
      poiListFields["selectedAccount"] = value;
      dispatch(poiList(poiListFields));
    } else if (type === "brand_ids") {
      value = !value || !value.length ? "" : value;
      tempFields[type] = value;
    } else if (type === "country_id") {
      if (value) {
        getCityList(value.id);
        tempFields["city_id"] = "";
        tempValidation["city_id"] = "Field is required";
      } else {
        tempFields[type] = value;
        const tempOptions = poiService.getDeepCopy(dropdownOptionsSelector);
        tempOptions["cityLists"] = [];
        dispatch(dropdownOptions(tempOptions));
      }
    }
    tempFields[type] = value;
    tempValidation[type] = value ? "" : "Field is required";
    dispatch(formDetailsTab(tempFields));
    dispatch(detailsFormErrorValidations(tempValidation));
  };

  const getAccountsList = async () => {
    if (!dropdownOptionsSelector || !dropdownOptionsSelector.accountsList) {
      const tempGetAccounts = await client.query({
        query: GET_ACCOUNTS,
        variables: { userId: user.id },
        fetchPolicy: "network-only",
      });
      setAccount(tempGetAccounts.data.listAccounts);
    }

    setIsLoading("false");

    if (
      poiListSelector.selectedAccount &&
      poiListSelector.selectedAccount.id &&
      poiListSelector.viewType === "add"
    ) {
      await getDropdownsOptions(poiListSelector.selectedAccount.id);
    }
  };

  const getDropdownsForEditView = async () => {
    if (
      !dropdownOptionsSelector ||
      !dropdownOptionsSelector.brandLists ||
      !dropdownOptionsSelector.countryLists ||
      !dropdownOptionsSelector.currencyLists ||
      !dropdownOptionsSelector.timezoneLists ||
      !dropdownOptionsSelector.cityLists
    ) {
      let tempDropdowns;
      if (detailsTabSelector.country_id) {
        tempDropdowns = await client.query({
          query: GET_DETAILS_TAB_EDIT_DROPDOWNS_WITH_COUNTRY,
          variables: {
            clientId: detailsTabSelector.client_id.id,
            country_id: detailsTabSelector.country_id.id,
            userId: user.id,
          },
          fetchPolicy: "network-only",
          errorPolicy: "all",
        });
      } else {
        tempDropdowns = await client.query({
          query: GET_DETAILS_TAB_EDIT_DROPDOWNS_WITHOUT_COUNTRY,
          variables: {
            clientId: detailsTabSelector.client_id.id,
            userId: user.id,
          },
          fetchPolicy: "network-only",
          errorPolicy: "all",
        });
      }
      if (tempDropdowns.errors) {
        const errMessage = tempDropdowns.errors.map((er) => er.message);
        setSnackBarState({
          open: true,
          vertical: "top",
          horizontal: "center",
          type: "error",
          message: errMessage,
        });
        setIsLoading("false");
      } else {
        const tempOptions = poiService.getDeepCopy(dropdownOptionsSelector);
        tempOptions["accountsList"] = tempDropdowns.data.listAccounts;
        tempOptions["brandLists"] = tempDropdowns.data.listBrands;
        tempOptions["countryLists"] = tempDropdowns.data.listCountries;
        tempOptions["currencyLists"] = tempDropdowns.data.listCurrencies;
        tempOptions["timezoneLists"] = tempDropdowns.data.listTimezones;
        if (detailsTabSelector.country_id) {
          tempOptions["cityLists"] = tempDropdowns.data.listCities;
        }
        tempOptions["verticalLists"] = tempDropdowns.data.listVerticals;
        await dispatch(dropdownOptions(tempOptions));
        if (tempDropdowns.data.listVerticals.length) {
          const checkExist = tempDropdowns.data.listVerticals.filter(
            (vert) => vert == detailsTabSelector.order_type
          );
          if (!checkExist.length) {
            const tempFields = poiService.getDeepCopy(detailsTabSelector);
            tempFields["order_type"] = tempDropdowns.data.listVerticals[0];
            dispatch(formDetailsTab(tempFields));
          }
        }
        setVertical(tempDropdowns.data.listVerticals);
      }
      setIsLoading("false");
    }
  };

  const closeSnackBar = () => {
    setSnackBarState({
      open: false,
      vertical: "top",
      horizontal: "center",
      type: "success",
      message: "",
    });
  };

  useEffect(() => {
    if (poiListSelector.viewType == "edit") {
      if (dropdownOptionsSelector && dropdownOptionsSelector.accountsList) {
        setAccount(dropdownOptionsSelector.accountsList);
      }
      getDropdownsForEditView();
    } else {
      if (dropdownOptionsSelector && dropdownOptionsSelector.accountsList) {
        setAccount(dropdownOptionsSelector.accountsList);
      } else {
        setIsLoading("true");
        getAccountsList();
      }
    }
  }, []);

  return (
    <>
      <Spinner
        color="dark"
        style={{
          position: "absolute",
          margin: "auto",
          top: "50%",
          left: "50%",
          display: isLoading === "true" ? "block" : "none",
        }}
      />
      <div style={{ display: isLoading === "true" ? "none" : "block" }}>
        <Snackbar
          anchorOrigin={{
            vertical: snackBarState.vertical,
            horizontal: snackBarState.horizontal,
          }}
          autoHideDuration={6000}
          open={snackBarState.open}
          key={snackBarState.vertical + snackBarState.horizontal}
        >
          <Alert
            onClose={closeSnackBar}
            severity={snackBarState.type}
            sx={{ width: "100%" }}
            sss
          >
            {snackBarState.message}
          </Alert>
        </Snackbar>
        <Grid container spacing={2}>
          <Grid container spacing={2} sx={{ marginLeft: 0 }}>
            <Grid item xs={3}>
              <Box sx={{ height: 300, width: "100%" }}>
                <div className="edit-profile-logo-container">
                  {tempImage.current ? (
                    <img
                      src={tempImage.current}
                      alt="Logo"
                      width="100%"
                      height="100%"
                    ></img>
                  ) : (
                    <>
                      {detailsTabSelector.poi_data &&
                      detailsTabSelector.poi_data.logo ? (
                        <img
                          src={
                            process.env.REACT_APP_STORAGE_URL +
                            detailsTabSelector.poi_data.logo
                          }
                          alt="Logo"
                          width="100%"
                          height="100%"
                        ></img>
                      ) : (
                        <PersonIcon className="edit-profile-icon" />
                      )}
                    </>
                  )}
                </div>
                <div className="edit-btn-container">
                  <label htmlFor="contained-button-file">
                    <Input
                      accept="image/*"
                      id="contained-button-file"
                      multiple
                      type="file"
                      onChange={handleFileInputChange}
                    />
                    <Button
                      variant="contained"
                      size="small"
                      component="span"
                      disabled={!user.permissions.poi_editPoiDetails}
                    >
                      Edit Logo
                    </Button>
                  </label>
                </div>
              </Box>
            </Grid>

            <Grid item xs={9}>
              <LoadScript
                googleMapsApiKey={googleMapAPIKey}
                libraries={mapLibraries}
              >
                <GoogleMap
                  mapContainerStyle={containerStyleForMap}
                  center={{
                    lat: Number(detailsTabSelector.latitude),
                    lng: Number(detailsTabSelector.longitude),
                  }}
                  zoom={mapZoom}
                  onClick={onMarkerClick}
                >
                  <Marker
                    position={{
                      lat: Number(detailsTabSelector.latitude),
                      lng: Number(detailsTabSelector.longitude),
                    }}
                  />
                  <div className="latitude-container">
                    <label>Latitude</label>
                    <input
                      type="text"
                      value={Number(detailsTabSelector.latitude)}
                      readOnly
                    />
                  </div>
                  <div className="longitude-container">
                    <label>Longitude</label>
                    <input
                      type="text"
                      value={Number(detailsTabSelector.longitude)}
                      readOnly
                    />
                  </div>
                </GoogleMap>
              </LoadScript>
              {poiListSelector.viewType == "edit" && (
                <div style={{ float: "right", marginTop: "5px" }}>
                  POI Status:
                  <span
                    style={{
                      marginLeft: "5px",
                      color:
                        detailsTabSelector.is_active == 1 ? "green" : "red",
                    }}
                  >
                    {detailsTabSelector.is_active == 1 ? "Active" : "Inactive"}
                  </span>
                </div>
              )}
            </Grid>
          </Grid>

          <Grid
            item
            xs={12}
            display={props.isView == "edit" ? "block" : "none"}
          >
            <Typography component={"div"} style={{ textAlign: "right" }}>
              <span style={{ fontWeight: "bold" }}>POI Status: </span>
              <span style={{ color: "green" }}>Active</span>
            </Typography>
          </Grid>

          <Grid item xs={6}>
            <Typography component={"div"} className="mb-1">
              POI Name <span style={{ color: "red" }}>*</span>
            </Typography>
            <TextField
              error={errorValidationSelector.name ? true : false}
              size="small"
              required
              variant="outlined"
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.name || ""}
              onChange={(ev) => handleChange(ev.target.value, "name")}
              helperText={errorValidationSelector.name}
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography component={"div"} className="mb-1">
              Location
            </Typography>
            <LoadScript
                googleMapsApiKey={googleMapAPIKey}
                libraries={mapLibraries}
            >
              <StandaloneSearchBox
                  onLoad={(e) => onMapLoad(e)}
                  onPlacesChanged={(place) => onPlacesChanged(place)}
              >
                <TextField
                    error={errorValidationSelector.location ? true : false}
                    size="small"
                    required
                    variant="outlined"
                    sx={{
                      width: "100%",
                      "& legend": { display: "none" },
                      "& fieldset": { top: 0 },
                    }}
                    disabled={!user.permissions.poi_editPoiDetails}
                    onChange={(ev) => handleChange(ev.target.value, "location")}
                    helperText={errorValidationSelector.location}
                    placeholder=""
                />
              </StandaloneSearchBox>
            </LoadScript>
          </Grid>

          <Grid item xs={6}>
            <Typography component={"div"}>
              Vertical <span style={{ color: "red" }}>*</span>
              <Tooltip arrow title="Select the business vertical of the POI.">
                <InfoIcon />
              </Tooltip>
            </Typography>
            <TextField
              size="small"
              select
              variant="outlined"
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.order_type || ""}
              error={errorValidationSelector.order_type ? true : false}
              onChange={(ev) => handleChange(ev.target.value, "order_type")}
              helperText={errorValidationSelector.order_type}
              disabled={
                !dropdownOptionsSelector.verticalLists || !user.permissions.poi_editPoiDetails
              }
            >
              {dropdownOptionsSelector.verticalLists && Object.keys(dropdownOptionsSelector.verticalLists).map((item, pos) => {
                return (
                  <MenuItem key={pos} value={dropdownOptionsSelector.verticalLists[item]}>
                    {dropdownOptionsSelector.verticalLists[item]}
                  </MenuItem>
                );
              })}
            </TextField>
          </Grid>

          <Grid item xs={6}>
            <Typography component={"div"}>
              Account <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              disablePortal
              size="small"
              sx={{ width: "100%" }}
              variant="outlined"
              options={account}
              getOptionLabel={(option) => option.name || ""}
              autoComplete
              value={detailsTabSelector.client_id || ""}
              onChange={(ev, val) => onDropdownSelection(val, "client_id")}
              disabled={!account || !user.permissions.poi_editPoiDetails}
              renderInput={(params) => (
                <TextField
                  placeholder="None"
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  {...params}
                  error={errorValidationSelector.client_id ? true : false}
                  helperText={errorValidationSelector.client_id}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography component={"div"}>
              Brand <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              disablePortal
              size="small"
              multiple
              sx={{
                width: "100%",
              }}
              variant="outlined"
              options={dropdownOptionsSelector.brandLists || []}
              getOptionLabel={(option) => option.name || ""}
              autoComplete
              value={detailsTabSelector.brand_ids || []}
              onChange={(ev, val) => onDropdownSelection(val, "brand_ids")}
              disabled={
                !dropdownOptionsSelector.brandLists ||
                !user.permissions.poi_editPoiDetails
              }
              renderInput={(params) => (
                <TextField
                  placeholder={detailsTabSelector.brand_ids ? "" : "None"}
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  {...params}
                  error={errorValidationSelector.brand_ids ? true : false}
                  helperText={errorValidationSelector.brand_ids}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography component={"div"}>
              Country <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              disablePortal
              size="small"
              sx={{
                width: "100%",
              }}
              variant="outlined"
              options={dropdownOptionsSelector.countryLists || []}
              getOptionLabel={(option) => option.name || ""}
              autoComplete
              value={detailsTabSelector.country_id || ""}
              onChange={(ev, val) => onDropdownSelection(val, "country_id")}
              disabled={
                !dropdownOptionsSelector.countryLists ||
                !user.permissions.poi_editPoiDetails
              }
              renderInput={(params) => (
                <TextField
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  {...params}
                  error={errorValidationSelector.country_id ? true : false}
                  helperText={errorValidationSelector.country_id}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography component={"div"}>
              City <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              disablePortal
              size="small"
              sx={{
                width: "100%",
              }}
              variant="outlined"
              options={dropdownOptionsSelector.cityLists || []}
              getOptionLabel={(option) => option.name || ""}
              autoComplete
              value={detailsTabSelector.city_id || ""}
              onChange={(ev, val) => onDropdownSelection(val, "city_id")}
              disabled={
                !dropdownOptionsSelector.cityLists ||
                !user.permissions.poi_editPoiDetails
              }
              renderInput={(params) => (
                <TextField
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  {...params}
                  error={errorValidationSelector.city_id ? true : false}
                  helperText={errorValidationSelector.city_id}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography component={"div"}>
              Currency <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              disablePortal
              size="small"
              sx={{ width: "100%" }}
              variant="outlined"
              options={dropdownOptionsSelector.currencyLists || []}
              getOptionLabel={(option) => option || ""}
              autoComplete
              value={detailsTabSelector.currency || ""}
              onChange={(ev, val) => onDropdownSelection(val, "currency")}
              disabled={
                !dropdownOptionsSelector.currencyLists ||
                !user.permissions.poi_editPoiDetails
              }
              renderInput={(params) => (
                <TextField
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  {...params}
                  error={errorValidationSelector.currency ? true : false}
                  helperText={errorValidationSelector.currency}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography component={"div"}>
              Time zone <span style={{ color: "red" }}>*</span>
            </Typography>
            <Autocomplete
              disablePortal
              size="small"
              sx={{
                width: "100%",
              }}
              variant="outlined"
              options={dropdownOptionsSelector.timezoneLists || []}
              getOptionLabel={(option) => option || ""}
              autoComplete
              value={detailsTabSelector.timezone || ""}
              onChange={(ev, val) => onDropdownSelection(val, "timezone")}
              disabled={
                !dropdownOptionsSelector.timezoneLists ||
                !user.permissions.poi_editPoiDetails
              }
              renderInput={(params) => (
                <TextField
                  sx={{
                    "& legend": { display: "none" },
                    "& fieldset": { top: 0 },
                  }}
                  {...params}
                  error={errorValidationSelector.timezone ? true : false}
                  helperText={errorValidationSelector.timezone}
                />
              )}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography component={"div"}>
              Open Time <span style={{ color: "red" }}>*</span>
              <Tooltip arrow title="POI operation start/open time.">
                <InfoIcon />
              </Tooltip>
            </Typography>
            <TextField
              size="small"
              margin="dense"
              type={"time"}
              variant="outlined"
              label=""
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.open_t || ""}
              error={errorValidationSelector.open_t ? true : false}
              onChange={(ev) => handleChange(ev.target.value, "open_t")}
              helperText={errorValidationSelector.open_t}
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography component={"div"}>
              Close Time <span style={{ color: "red" }}>*</span>
              <Tooltip arrow title="POI operation end/close time.">
                <InfoIcon />
              </Tooltip>
            </Typography>
            <TextField
              size="small"
              margin="dense"
              type={"time"}
              variant="outlined"
              label=""
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.close_t || ""}
              error={errorValidationSelector.close_t ? true : false}
              onChange={(ev) => handleChange(ev.target.value, "close_t")}
              helperText={errorValidationSelector.close_t}
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography>Address Line 1</Typography>
            <TextField
              size="small"
              margin="dense"
              variant="outlined"
              label=""
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.street || ""}
              onChange={(ev) => handleChange(ev.target.value, "street")}
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography>Address Line 2</Typography>
            <TextField
              size="small"
              margin="dense"
              variant="outlined"
              label=""
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.bulding_nr || ""}
              onChange={(ev) => handleChange(ev.target.value, "bulding_nr")}
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography className="mb-1">Zip/Postal Code</Typography>
            <TextField
              size="small"
              margin="dense"
              variant="outlined"
              label=""
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.zip_code || ""}
              onChange={(ev) => handleChange(ev.target.value, "zip_code")}
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography>
              Operation Zone
              <Tooltip
                arrow
                title="Enter a operation zone/place from which this POI is operating."
              >
                <InfoIcon />
              </Tooltip>
            </Typography>
            <TextField
              size="small"
              margin="dense"
              variant="outlined"
              label=""
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.operation_zone || ""}
              onChange={(ev) => handleChange(ev.target.value, "operation_zone")}
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography>Email</Typography>
            <TextField
              size="small"
              type={"email"}
              margin="dense"
              variant="outlined"
              label=""
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.contact_email || ""}
              onChange={(ev) => handleChange(ev.target.value, "contact_email")}
              error={errorValidationSelector.contact_email ? true : false}
              helperText={errorValidationSelector.contact_email}
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography>Phone Number</Typography>
            <TextField
              size="small"
              margin="dense"
              variant="outlined"
              label=""
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.contact_phone || ""}
              onChange={(ev) => handleChange(ev.target.value, "contact_phone")}
              error={errorValidationSelector.contact_phone ? true : false}
              helperText={errorValidationSelector.contact_phone}
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography>POI Manager Name</Typography>
            <TextField
              size="small"
              margin="dense"
              variant="outlined"
              label=""
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.contact_person || ""}
              onChange={(ev) => handleChange(ev.target.value, "contact_person")}
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography>POI Manager Phone Number</Typography>
            <TextField
              size="small"
              margin="dense"
              variant="outlined"
              label=""
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.store_manager_phone || ""}
              onChange={(ev) =>
                handleChange(ev.target.value, "store_manager_phone")
              }
              error={errorValidationSelector.store_manager_phone ? true : false}
              helperText={errorValidationSelector.store_manager_phone}
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography>Area Manager Name</Typography>
            <TextField
              size="small"
              margin="dense"
              variant="outlined"
              label=""
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.area_manager_name || ""}
              onChange={(ev) =>
                handleChange(ev.target.value, "area_manager_name")
              }
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography>Area Manager Phone Number</Typography>
            <TextField
              size="small"
              margin="dense"
              variant="outlined"
              label=""
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.area_manager_phone || ""}
              onChange={(ev) =>
                handleChange(ev.target.value, "area_manager_phone")
              }
              error={errorValidationSelector.area_manager_phone ? true : false}
              helperText={errorValidationSelector.area_manager_phone}
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography>
              POI ID
              <Tooltip arrow title="Enter a POI ID for your reference.">
                <InfoIcon />
              </Tooltip>
            </Typography>
            <TextField
              size="small"
              margin="dense"
              variant="outlined"
              label=""
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.postal_code || ""}
              onChange={(ev) => handleChange(ev.target.value, "postal_code")}
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>

          <Grid item xs={6}>
            <Typography className="mb-1">Go Live Date</Typography>
            <TextField
              size="small"
              margin="dense"
              type={"date"}
              variant="outlined"
              label=""
              sx={{
                width: "100%",
                "& legend": { display: "none" },
                "& fieldset": { top: 0 },
              }}
              value={detailsTabSelector.golivedate || ""}
              onChange={(ev) => handleChange(ev.target.value, "golivedate")}
              disabled={!user.permissions.poi_editPoiDetails}
            />
          </Grid>
        </Grid>
      </div>
    </>
  );
};

export default React.memo(PoiDetail);
