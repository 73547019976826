import { createSlice } from "@reduxjs/toolkit";

export const deliveryAreaSlice = createSlice({
  name: "deliveryAreas",
  initialState: { value: [] },
  reducers: {
    deliveryAreas: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { deliveryAreas } = deliveryAreaSlice.actions;

export default deliveryAreaSlice.reducer;
