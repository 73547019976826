import React, { useState, useEffect, useRef } from "react";
import InfoIcon from "@mui/icons-material/Info";
import { styled } from "@mui/material/styles";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Tooltip,
  tooltipClasses,
  Typography,
  DialogContentText,
  Input,
} from "@mui/material";
import { POST_IMPORT_POIS } from "./../graphql/mutations";
import { useMutation } from "@apollo/client";
import { Spinner } from "reactstrap";
import { useDispatch, useSelector } from "react-redux";
import { reloadPoiList } from "../reducers/poiLists";

const ImportDialog = ({ childFunc }) => {
  const dispatch = useDispatch();

  const supportExt = ["xlsx", "xls"];
  const [open, setOpen] = useState(false);
  const [isLoading, setIsLoading] = useState("false");
  const [selectedFile, setSelectedFile] = useState(null);
  const [message, setMessage] = useState({ type: "", message: "" });
  const [importMutation] = useMutation(POST_IMPORT_POIS);
  const user = useSelector((state) => state.userInfo.value);
  const detailsTabSelector = useSelector((state) => state.detailTab.value);
  const selectedFileName = useRef(null);
  const [isInvalidFile, setIsInvalidFile] = useState(false);

  useEffect(() => {
    childFunc.current = handleClickOpen;
    setMessage({ type: "", message: "" });
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const handleClickOpen = () => {
    setOpen(true);
    setMessage({ type: "", message: "" });
  };

  const handleClose = (ev) => {
    if (message.type === "success" || ev.target.innerText === "CANCEL") {
      setOpen(false);
    }
    setMessage({ type: "", message: "" });
    setIsInvalidFile(false);
    setSelectedFile(null);
    selectedFileName.current = null;
  };

  const HtmlTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
  ))(({ theme }) => ({
    [`& .${tooltipClasses.tooltip}`]: {
      backgroundColor: "#f5f5f9",
      color: "rgba(0, 0, 0, 0.87)",
      maxWidth: 220,
      fontSize: theme.typography.pxToRem(12),
      border: "1px solid #dadde9",
    },
  }));

  // On file select (from the pop up).
  const onFileChange = (event) => {
    let fileName = event?.target?.files[0]?.name;
    let fileExtension = fileName.split(".").pop();
    setIsInvalidFile(false);

    selectedFileName.current = fileName;
    if (supportExt.includes(fileExtension)) {
      // Update the state.
      convertBase64(event.target.files[0]);
    } else {
      setIsInvalidFile(true);
    }
  };

  //Base64 Converter function.
  const convertBase64 = (file) => {
    let reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = (e) => {
      setSelectedFile(e.target.result);
    };
  };

  // On file upload (click the upload button).
  const uploadExcel = (event) => {
    if (selectedFile) {
      setIsLoading("true");
      importMutation({
        variables: {
          client_id: detailsTabSelector.client_id.id,
          user_id: user.id,
          xls_file: selectedFile,
        },
        onCompleted(successResp) {
          if (successResp.importPois.success) {
            if (successResp.importPois.data.includes("error")) {
              setMessage({
                type: "importFail",
                message: successResp.importPois.data,
              });
            } else {
              dispatch(reloadPoiList({ isReload: true }));
              setMessage({
                type: "success",
                message: successResp.importPois.data,
              });
            }
          } else {
            setMessage({
              type: "error",
              message: successResp.importPois.data,
            });
          }
          setIsLoading("false");
        },
        onError(errorResp) {
          setMessage({
            type: "error",
            message: "Something went wrong! Please try again",
          });
          setIsLoading("false");
        },
      });
    }
  };

  return (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        sx={{ width: "100%" }}
        maxWidth="sm"
        fullWidth
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className="align-center"
      >
        <DialogTitle id="alert-dialog-title" sx={{ textAlign: "center" }}>
          {"Import POIs"}
        </DialogTitle>
        {isLoading === "true" ? (
          <DialogContent sx={{ minHeight: 100 }}>
            <Spinner
              color="dark"
              style={{
                position: "absolute",
                margin: "auto",
                top: "50%",
                left: "50%",
              }}
            />
          </DialogContent>
        ) : (
          <>
            {message.type ? (
              <>
                <DialogContent>
                  <DialogContentText id="alert-dialog-description">
                    {message.type === "importFail" ? (
                      <>
                        {"Import failed "}
                        <a
                          href={
                            process.env.REACT_APP_STORAGE_URL + message.message
                          }
                          target="blank"
                        >
                          {message.message}
                        </a>
                      </>
                    ) : (
                      <>{message.message}</>
                    )}
                  </DialogContentText>
                </DialogContent>
                <DialogActions
                  sx={{ display: "flex", justifyContent: "center" }}
                >
                  <Button onClick={handleClose}>OK</Button>
                </DialogActions>
              </>
            ) : (
              <>
                <DialogContent>
                  <div className="edit-btn-container">
                    <label htmlFor="contained-button-file">
                      <Input
                        accept="image/*"
                        id="contained-button-file"
                        multiple
                        type="file"
                        onChange={onFileChange}
                      />
                      <Button variant="contained" size="small" component="span">
                        {selectedFileName.current
                          ? selectedFileName.current
                          : "Choose File"}
                      </Button>
                    </label>
                    <label htmlFor="contained-button-file">
                      <HtmlTooltip
                        title={
                          <React.Fragment>
                            <Typography color="inherit">
                              Pre-import checklist:
                            </Typography>
                            <ul sx={{ paddingLeft: "1rem" }}>
                              <li> It will support on the xlsx/xls file.</li>
                              <li> Fill in all the mandatorv flelds.</li>
                              <li> Delete any duplicates.</li>
                              <li>
                                {" "}
                                Default value will be considered if a filed has
                                been kept blank.
                              </li>
                              <li>
                                {" "}
                                Enter on the allowed values in the proper
                                format.
                              </li>
                            </ul>
                          </React.Fragment>
                        }
                      >
                        <InfoIcon sx={{ marginLeft: 1 }}></InfoIcon>
                      </HtmlTooltip>
                    </label>
                  </div>
                  {isInvalidFile && (
                    <p style={{ marginBottom: 0, color: "red" }}>
                      This file type is not supported
                    </p>
                  )}
                </DialogContent>
                <DialogActions
                  sx={{ display: "flex", justifyContent: "space-between" }}
                >
                  <Button onClick={handleClose}>Cancel</Button>
                  <Button
                    onClick={uploadExcel}
                    autoFocus
                    disabled={!selectedFile}
                  >
                    Import
                  </Button>
                </DialogActions>
              </>
            )}
          </>
        )}
      </Dialog>
    </div>
  );
};

export default ImportDialog;
