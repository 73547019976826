import { gql } from "@apollo/client";

export const GET_ACCOUNTS = gql`
  query AccountListQuery($userId: Int!) {
    listAccounts(user_id: $userId) {
      id
      name
    }
  }
`;

export const GET_DETAILS_TAB_FILTERS = gql`
  query DetailTabQuery($clientId: Int!) {
    listVerticals(client_id: $clientId)
    listBrands(client_id: $clientId) {
      id
      name
    }
    listCountries(client_id: $clientId) {
      id
      name
    }
    listCurrencies(client_id: $clientId)
    listTimezones(client_id: $clientId)
  }
`;

export const GET_DETAILS_TAB_EDIT_DROPDOWNS_WITHOUT_COUNTRY = gql`
  query EditDropdownQuery($clientId: Int!, $userId: Int!) {
    listAccounts(user_id: $userId) {
      id
      name
    }
    listVerticals(client_id: $clientId)
    listBrands(client_id: $clientId) {
      id
      name
    }
    listCountries(client_id: $clientId) {
      id
      name
    }
    listCurrencies(client_id: $clientId)
    listTimezones(client_id: $clientId)
  }
`;

export const GET_DETAILS_TAB_EDIT_DROPDOWNS_WITH_COUNTRY = gql`
  query EditDropdownQuery($clientId: Int!, $country_id: Int!, $userId: Int!) {
    listAccounts(user_id: $userId) {
      id
      name
    }
    listVerticals(client_id: $clientId)
    listBrands(client_id: $clientId) {
      id
      name
    }
    listCountries(client_id: $clientId) {
      id
      name
    }
    listCities(country_id: $country_id) {
      id
      name
    }
    listCurrencies(client_id: $clientId)
    listTimezones(client_id: $clientId)
  }
`;

export const GET_CONFIG_TAB_DROPDOWNS = gql`
  query EditDropdownQuery($clientId: Int!) {
    listOrderPartner(client_id: $clientId) {
      partner_name
      partner_type
    }
    listDeliveryPartners(client_id: $clientId) {
      id
      name
    }
  }
`;

export const GET_CONFIG_EDIT_APIS = gql`
  query EditConfigEditAPIQuery($poiId: Int) {
    getPoiDeliveryPartner(poi_id: $poiId) {
      client_id
      dp_mapping
      id
      name
      switching_time
      preference
    }
    getPoiOrderPartner(poi_id: $poiId) {
      id
      partner_reference_id
      partner_type
      store_id
    }
  }
`;

export const GET_CITY_LIST = gql`
  query listCities($country_id: Int!) {
    listCities(country_id: $country_id) {
      id
      name
    }
  }
`;

export const VALIDATE_POI_REF_ID = gql`
  query validatePartnerReferenceId(
    $account_id: Int!
    $partner_reference_id: String!
    $partner_type: Int!
  ) {
    validatePartnerReferenceId(
      account_id: $account_id
      partner_reference_id: $partner_reference_id
      partner_type: $partner_type
    ) {
      data
      success
    }
  }
`;

export const GET_POIS_LIST = gql`
  query PoiQuery(
    $client_id: String
    $name: String
    $page_no: Int!
    $status: String
    $assignment: String
    $cities: String
    $brands: String
    $countries: String
    $poi_id: String
    $reference_id_order_creation: String
    $reference_id_wayanaq: String
    $teams: String
  ) {
    listPois(
      filters: {
        name: $name
        page_no: $page_no
        client_id: $client_id
        status: $status
        assignment: $assignment
        cities: $cities
        brands: $brands
        countries: $countries
        poi_id: $poi_id
        reference_id_order_creation: $reference_id_order_creation
        reference_id_wayanaq: $reference_id_wayanaq
        teams: $teams
      }
    ) {
      data {
        name
        is_active
        brands {
          id
          name
        }
        teams {
          id
          name
        }
        country {
          id
          name
        }
        city {
          id
          name
        }
        id
        number_gln
        reference_id_waynaq
        settings {
          is_new_autoassign
          is_autoassign_v1
        }
      }
      total
    }
  }
`;

export const GET_TEAMS_LIST = gql`
  query TeamsQuery($countryId: Int!, $userId: Int!) {
    listUserTeams(country_id: $countryId, user_id: $userId) {
      id
      name
      team_type
      city {
        id
        name
      }
    }
  }
`;

export const GET_ZONES_LIST = gql`
  query ZonesQuery($cityId: Int!) {
    listZones(city_id: $cityId) {
      id
      name
      partner_reference_id
    }
  }
`;
export const GET_POI_DEL_AREAS = gql`
  query ListPoiDelAreaQuery($cityId: Int!, $poiId: Int) {
    listPoiDeliveryAreas(city_id: $cityId, poi_id: $poiId) {
      id
      name
      partner_reference_id
      is_checked
      connection_name
    }
  }
`;

export const GET_FILTERS = gql`
  query FiltersQuery($clientId: Int!) {
    listTeamsFilter(client_id: $clientId) {
      name
      id
    }
    listCities(client_id: $clientId) {
      id
      name
    }
    listCountries(client_id: $clientId) {
      id
      name
    }
    listBrands(client_id: $clientId) {
      id
      name
    }
  }
`;

export const GET_CLIENT_CONFIGS = gql`
  query ClientQuery($clientId: Int!, $userId: Int!) {
    getClient(client_id: $clientId, user_id: $userId) {
      config {
        track_api_new
        track_share_eta
        return_track_id
        upfront_payment_by_driver
        is_allowd_location
        is_convert_address
        order_acceptance_check
        order_fields
        start_tracking
        enable_delivery_partner
        dod_request_time
        dod_withdrawal
        dod_target_assign
        distance_app
        eta_for_broadcast
        is_show_waiting_eta
        is_show_return_eta
        is_show_reach_eta
        orders_reach_eta
        orders_reach_dod_eta
        eta_tracking_url
        route_tracking_url
        is_scan_orders
        enable_pickup_after
        is_photo
        is_emirateId
        is_signature
        is_return
        order_partner
        sos_compliance
        expected_sos_start
        expected_sos_end
        sos_compared_against
        arrived_for_pickup_check
        zc_waiting_orders
        compliance_pickup
        compliance_delivery
        is_delivery_partner_auto_assignment_enabled
        is_mandatory_scanning_pickup
        is_mandatory_scanning_delivery
        is_full_acceptance_or_return
      }
    }
  }
`;

export const GET_POI = gql`
  query GetPoiQuery($poiId: ID!) {
    getPoi(id: $poiId) {
      aggrigators {
        access_token
        enabled_account
        owner_account_id
        poi_id
      }
      area_manager_name
      area_manager_phone
      brands {
        id
        name
      }
      bulding_nr
      city {
        name
        id
      }
      city_id
      client_id
      contact_email
      contact_person
      contact_phone
      country {
        id
        name
      }
      country_id
      delivery_areas {
        connection_name
        zone_id
      }
      edge_points {
        lat
        lng
      }
      id
      is_active
      latitude
      longitude
      name
      operation_zone
      order_type
      poi_data {
        logo
      }
      postal_code
      reference_id_waynaq
      store_manager_phone
      street
      teams {
        id
        name
        team_type
      }
      type
      zip_code
      number_gln
      radius
      qr_code
      is_force_del_area
      settings {
        track_api_new
        track_share_eta
        return_track_id
        upfront_payment_by_driver
        Location_Algorithm_2
        is_convert_address
        order_acceptance_check
        Check_for_Delivery_Boundary
        Check_for_Delivery_Area
        Check_for_Team_Boundary
        order_fields
        start_tracking
        dod_request_time
        dod_withdrawal
        target_dod_arrival_time
        dod_target_assign
        distance_app
        eta_for_broadcast
        is_show_waiting
        is_show_waiting_eta
        is_show_return_eta
        is_show_reach_eta
        orders_reach_eta
        orders_reach_dod_eta
        eta_tracking_url
        route_tracking_url
        is_new_autoassign
        is_autoassign_v1
        is_scan_orders
        arrived_for_pickup_radius
        enable_pickup_after
        is_photo_on_pickup
        is_photo
        is_emirateId
        is_signature
        is_return
        sos_compliance
        sos_compared_against
        arrived_for_pickup_check
        zc_waiting_orders
        compliance_pickup
        compliance_delivery
        is_cash_center
        expected_sos_start
        expected_sos_end
        target_assign
        target_order
        target_wait
        target_customer
        target_ins
        target_drive
        target_sos
        currency
        open_t
        close_t
        go_live_date
        timezone
        is_delivery_partner_auto_assignment_enabled
        dod_request_time
        time_to_switch_delivery_partner
        is_dp_enabled
        is_mandatory_scanning_pickup
        is_mandatory_scanning_delivery
        is_full_acceptance_or_return
      }
      reference_id_waynaq
    }
  }
`;

export const GET_ZONES_CONNECTIONS = gql`
  query ZonesConnectionsQuery {
    listZoneConnections {
      color
      name
    }
  }
`;

export const GET_USER = gql`
  query GetUserQuery($userId: ID!) {
    getUserByID(id: $userId) {
      id
      lastName
      firstName
      clients
      clientId
      AccConfig
      roleType
      permissions
      timezone
    }
  }
`;

export const GET_INITIAL_VALUES = gql`
  query GetPoisConfigurations($poiIds: String) {
    getPoisConfigurations(poi_ids: $poiIds) {
      id
      settings {
        is_dp_enabled
        is_delivery_partner_auto_assignment_enabled
        dod_request_time
        target_dod_arrival_time
        dod_target_assign
      }
      partners {
        id
        name
        preference
        switching_time
      }
    }
  }
`;

export const GET_DP_ACCOUNT = gql`
  query GetClientDeliveryPartners($client_id: Int!) {
    getClientDeliveryPartners(client_id: $client_id) {
      id
      name
      preference
      switching_time
    }
  }
`;
