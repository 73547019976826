import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";

import "./DeliveryArea.css";
import { useApolloClient, useQuery } from "@apollo/client";
import {
  GET_POI_DEL_AREAS,
  GET_ZONES_CONNECTIONS,
  GET_ZONES_LIST,
} from "../../graphql/queries";
import { Spinner } from "reactstrap";
import { deliveryAreas } from "../../reducers/deliveryArea";
import { useDispatch, useSelector } from "react-redux";
import PoiService from "../../services/PoiService";

const DeliveryArea = () => {
  const poiService = new PoiService();
  const dispatch = useDispatch();
  const deliveryAreaSelector = useSelector(
    (state) => state.deliveryAreas.value
  );
  const user = useSelector((state) => state.userInfo.value);
  const detailsTabSelector = useSelector((state) => state.detailTab.value);
  const poiListSelector = useSelector((state) => state.poiLists.value);

  const [deliveryAreaList, setDeliveryAreaList] = useState([]);
  const [zonesConnectionsList, setZonesConnectionsList] = useState([]);
  const [isLoading, setIsLoading] = useState("false");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [selectedDelAreaCount, setSelectedDelAreaCount] = useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [assignedDelArea, setAssignedDelArea] = React.useState("");
  const {
    loading: getZonesConnectionsLoading,
    error: getZonesConnectionsError,
    data: zonesConnectionsData,
  } = useQuery(GET_ZONES_CONNECTIONS);
  const [snackBarState, setSnackBarState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    type: "success",
    message: "",
  });

  const currentPage = useRef(0);
  const copyDeliveryAreaList = useRef([]);
  const zonesListData = useRef([]);
  const selectedFilters = useRef({});
  const client = useApolloClient();

  const tableColumns = [
    { id: "name", label: "Delivery Area", minWidth: 100 },
    { id: "deliveryZone", label: "Delivery Zone", minWidth: 100 },
    { id: "partnerId", label: "Partner ID", minWidth: 100 },
  ];

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const onChangeDelZoneType = (ev) => {
    const tempData = JSON.parse(JSON.stringify(assignedDelArea));
    tempData.deliveryZone = ev;
    setAssignedDelArea(tempData);
  };

  const checkIfIndeterminate = useCallback(() => {
    const tempSelectedCount = copyDeliveryAreaList.current.filter(
      (t) => t["checked"] == true
    ).length;
    if (tempSelectedCount !== 0) {
      setSelectedDelAreaCount(tempSelectedCount);
    } else {
      setSelectedDelAreaCount(0);
    }
  });

  const applyDelZone = () => {
    const newTempArr = JSON.parse(JSON.stringify(copyDeliveryAreaList.current));
    newTempArr.forEach((row) => {
      if (row.id === assignedDelArea.id) {
        row.deliveryZone = assignedDelArea.deliveryZone;
        row["checked"] = assignedDelArea.deliveryZone ? true : false;
      }
    });
    setDeliveryAreaList([...newTempArr]);
    copyDeliveryAreaList.current = newTempArr;
    checkIfIndeterminate();
    const tempDispatchData = copyDeliveryAreaList.current.map((data) => ({
      id: data.id,
      connection_name: data.deliveryZone,
    }));
    dispatch(deliveryAreas(tempDispatchData));
    setOpenDialog(false);
  };

  const search = (searchString, field) => {
    selectedFilters.current[field] = searchString;
    currentPage.current = 0;
    pageChanged();
  };

  const pageChanged = () => {
    const offset = currentPage.current * rowsPerPage;
    const filteredDataSource = poiService.onFilterChanges(
      copyDeliveryAreaList.current,
      selectedFilters.current
    );
    const paginatedData = filteredDataSource
      .slice(offset)
      .slice(0, rowsPerPage);
    setDeliveryAreaList(paginatedData);
  };

  const onDelAreaSelect = (ev, inRow) => {
    if (ev) {
      setOpenDialog(true);
      setAssignedDelArea(inRow);
    } else {
      const tempRow = JSON.parse(JSON.stringify(inRow));
      tempRow.deliveryZone = null;
      setAssignedDelArea(tempRow);
      const newTempArr = JSON.parse(
        JSON.stringify(copyDeliveryAreaList.current)
      );
      newTempArr.forEach((row) => {
        if (row.id === inRow.id) {
          row.deliveryZone = null;
          row["checked"] = false;
        }
      });
      setDeliveryAreaList([...newTempArr]);
      copyDeliveryAreaList.current = newTempArr;
      checkIfIndeterminate();
      const tempDispatchData = copyDeliveryAreaList.current.map((data) => ({
        id: data.id,
        connection_name: data.deliveryZone,
      }));
      dispatch(deliveryAreas(tempDispatchData));
    }
  };

  const handleChangePage = (event, newPage) => {
    currentPage.current = newPage;
    pageChanged(newPage);
  };

  const checkAreaExist = (inVal) => {
    let tempVal = false;
    deliveryAreaSelector.forEach((area) => {
      if (area.connection_name && area.zone_id == inVal.id) {
        tempVal = area;
      }
    });
    return tempVal;
  };

  const getZonesList = async () => {
    if (detailsTabSelector.city_id) {
      setIsLoading("true");
      const tempGetZonesListData = await client.query({
        query: GET_ZONES_LIST,
        variables: { cityId: detailsTabSelector.city_id.id },
        fetchPolicy: "network-only",
      });

      if (
        !tempGetZonesListData ||
        !tempGetZonesListData.data ||
        !tempGetZonesListData.data.listZones
      ) {
        setIsLoading("false");
        setSnackBarState({
          open: true,
          vertical: "top",
          horizontal: "center",
          type: "error",
          message: "Something went wrong with the List Zones Query",
        });
      } else if (!tempGetZonesListData.data.listZones.length) {
        setIsLoading("false");
        setSnackBarState({
          open: true,
          vertical: "top",
          horizontal: "center",
          type: "error",
          message: "There are no zones to display",
        });
      } else {
        const copyListData = JSON.parse(JSON.stringify(tempGetZonesListData));

        setTotalItemCount(tempGetZonesListData.data.listZones.length);

        if (deliveryAreaSelector.length) {
          copyListData.data.listZones.forEach((listData) => {
            listData.deliveryZone = listData.deliveryZone
              ? listData.deliveryZone
              : "";
            const checkExist = checkAreaExist(listData);
            if (checkExist || listData.deliveryZone) {
              listData["deliveryZone"] = listData.deliveryZone
                ? listData.deliveryZone
                : checkExist.connection_name;
              listData["checked"] = true;
            }
            listData["partnerId"] = listData.partner_reference_id;
          });
        } else {
          copyListData.data.listZones.forEach((listData) => {
            listData.deliveryZone = listData.deliveryZone
              ? listData.deliveryZone
              : "";
            listData["checked"] = false;
            listData["partnerId"] = listData.partner_reference_id;
          });
        }
        zonesListData.current = copyListData.data.listZones;
        copyDeliveryAreaList.current = zonesListData.current;
        copyDeliveryAreaList.current.sort((a, b) => {
          const isCheckedA = a.checked === true;
          const isCheckedB = b.checked === true;

          // Check if both are checked or unchecked
          if (isCheckedA === isCheckedB) {
            // Sort alphabetically by name if both are checked or unchecked
            const string1 = /[a-zA-Z]+/.exec(a.name);
            const string2 = /[a-zA-Z]+/.exec(b.name);
            if (string1 && string2) {
              return string1[0].localeCompare(string2[0]);
            } else {
              return a - b;
            }
          } else {
            // Prioritize checked items
            return isCheckedA ? -1 : 1;
          }
        });
        pageChanged();
        setIsLoading("false");
      }
    } else {
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        type: "error",
        message: "Please select city to continue",
      });
    }
  };

  const getPoiDeliveryAreas = async () => {
    if (detailsTabSelector.city_id) {
      setIsLoading("true");
      const tempPoiDelAreas = await client.query({
        query: GET_POI_DEL_AREAS,
        variables: {
          cityId: detailsTabSelector.city_id.id,
          poiId: poiListSelector.selectedPoi,
        },
        fetchPolicy: "network-only",
      });

      if (
        !tempPoiDelAreas ||
        !tempPoiDelAreas.data ||
        !tempPoiDelAreas.data.listPoiDeliveryAreas
      ) {
        setIsLoading("false");
        setSnackBarState({
          open: true,
          vertical: "top",
          horizontal: "center",
          type: "error",
          message: "Something went wrong with the List Zones Query",
        });
      } else if (!tempPoiDelAreas.data.listPoiDeliveryAreas.length) {
        setIsLoading("false");
        setSnackBarState({
          open: true,
          vertical: "top",
          horizontal: "center",
          type: "error",
          message: "There are no zones to display",
        });
      } else {
        const copyListData = JSON.parse(JSON.stringify(tempPoiDelAreas));

        setTotalItemCount(tempPoiDelAreas.data.listPoiDeliveryAreas.length);
        copyListData.data.listPoiDeliveryAreas.forEach((listData) => {
          listData.deliveryZone = listData.connection_name;
          listData["checked"] = listData.is_checked === true ? true : false;
          listData["partnerId"] = listData.partner_reference_id;
        });
        zonesListData.current = copyListData.data.listPoiDeliveryAreas;
        copyDeliveryAreaList.current = zonesListData.current;
        copyDeliveryAreaList.current.sort((a, b) => {
          const isCheckedA = a.checked === true;
          const isCheckedB = b.checked === true;

          // Check if both are checked or unchecked
          if (isCheckedA === isCheckedB) {
            // Sort alphabetically by name if both are checked or unchecked
            const string1 = /[a-zA-Z]+/.exec(a.name);
            const string2 = /[a-zA-Z]+/.exec(b.name);
            if (string1 && string2) {
              return string1[0].localeCompare(string2[0]);
            } else {
              return a - b;
            }
          } else {
            // Prioritize checked items
            return isCheckedA ? -1 : 1;
          }
        });

        pageChanged();
        setIsLoading("false");
      }
    } else {
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        type: "error",
        message: "Please select city to continue",
      });
    }
  };

  const closeSnackBar = () => {
    setSnackBarState({
      open: false,
      vertical: "top",
      horizontal: "center",
      type: "success",
      message: "",
    });
  };

  useEffect(() => {
    if (poiListSelector.viewType == "edit") {
      getPoiDeliveryAreas();
    } else {
      getZonesList();
    }

    if (zonesConnectionsData) {
      setZonesConnectionsList(zonesConnectionsData.listZoneConnections);
    }
  }, [zonesConnectionsData]);

  useEffect(() => {
    checkIfIndeterminate();
  }, [checkIfIndeterminate]);

  return (
    <>
      {isLoading === "true" ? (
        <Spinner
          color="dark"
          style={{
            position: "absolute",
            margin: "auto",
            top: "50%",
            left: "50%",
          }}
        />
      ) : (
        <>
          <div className="wrapper">
            <Snackbar
              anchorOrigin={{
                vertical: snackBarState.vertical,
                horizontal: snackBarState.horizontal,
              }}
              autoHideDuration={6000}
              open={snackBarState.open}
              key={snackBarState.vertical + snackBarState.horizontal}
            >
              <Alert
                onClose={closeSnackBar}
                severity={snackBarState.type}
                sx={{ width: "100%" }}
              >
                {snackBarState.message}
              </Alert>
            </Snackbar>
            <Dialog
              open={openDialog}
              onClose={handleDialogClose}
              sx={{ width: "100%" }}
              maxWidth="sm"
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="align-center"
            >
              <DialogTitle id="alert-dialog-title">
                Assign Delivery Area
              </DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  className="dialog-content"
                >
                  <p>Delivery Area: {assignedDelArea.name}</p>
                  <p>Select a Delivery Zone:</p>
                  <FormControl sx={{ minWidth: 80 }} variant="standard">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      autoWidth
                      value={assignedDelArea.deliveryZone || ""}
                      onChange={(e) => onChangeDelZoneType(e.target.value)}
                      disabled={!user.permissions.poi_editDeliveryAreaTab}
                    >
                      {zonesConnectionsList.map((list) => (
                        <MenuItem value={list.name} key={list.color}>
                          {list.name}
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={applyDelZone} autoFocus>
                  Apply
                </Button>
              </DialogActions>
            </Dialog>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className="delivery-area-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    {tableColumns.map((column) => (
                      <React.Fragment key={column.id}>
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <label className="pb-1 d-block">{column.label}</label>
                          <TextField
                            id="search"
                            label="Search"
                            variant="outlined"
                            size="small"
                            onChange={(ev) =>
                              search(ev.target.value, column.id)
                            }
                          />
                        </TableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {deliveryAreaList.map((row, rowIndex) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={rowIndex}
                      >
                        <TableCell key={rowIndex}>
                          <Checkbox
                            checked={row.checked === true}
                            onChange={(e) =>
                              onDelAreaSelect(e.target.checked, row)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            disabled={!user.permissions.poi_editDeliveryAreaTab}
                          />
                        </TableCell>
                        <TableCell key={rowIndex + "edit"}>
                          {row.deliveryZone ? (
                            <CreateIcon
                              className="edit-icon"
                              onClick={() => onDelAreaSelect(true, row)}
                            ></CreateIcon>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        {tableColumns.map((column) => {
                          const value = row[column.id];
                          return (
                            <React.Fragment key={column.id}>
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            </React.Fragment>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="pagination-container">
              <div className="selected-count-wrapper">
                Total {totalItemCount} | Selected {selectedDelAreaCount}
              </div>
              <div className="pagination-wrapper">
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={totalItemCount}
                  rowsPerPage={rowsPerPage}
                  page={currentPage.current}
                  showFirstButton
                  showLastButton
                  onPageChange={handleChangePage}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default DeliveryArea;
