import { gql } from "@apollo/client";

const TeamsType = {
  name: String,
  zone_id: String,
};

export const CREATE_POI = gql`
  mutation createPoi(
    $area_manager_name: String
    $area_manager_phone: String
    $brand_ids: String
    $bulding_nr: String
    $city_id: Int!
    $client_id: Int!
    $contact_email: String
    $contact_person: String
    $contact_phone: String
    $country_id: Int!
    $edge_points: String
    $latitude: String
    $longitude: String
    $name: String!
    $operation_zone: String
    $order_type: String
    $postal_code: String
    $store_manager_phone: String
    $street: String
    $zip_code: String
    $currency: String!
    $timezone: String!
    $open_t: String!
    $close_t: String!
    $go_live_date: String
    $zip_code: String
    $teams: [CreatePoiTeam]
    $delivery_areas: [CreateDeliveryArea]
    $track_api_new: Boolean
    $track_share_eta: Boolean
    $return_track_id: Boolean
    $number_gln: String!
    $upfront_payment_by_driver: Boolean
    $Location_Algorithm_2: Boolean
    $is_convert_address: Boolean
    $order_acceptance_check: String
    $Check_for_Delivery_Boundary: String
    $Check_for_Delivery_Area: String
    $Check_for_Team_Boundary: String
    $order_fields: String
    $start_tracking: String
    $dod_request_time: String
    $dod_request_time: String
    $time_to_switch_delivery_partner: String
    $dod_withdrawal: String
    $target_dod_arrival_time: String
    $dod_target_assign: String
    $distance_app: String
    $eta_for_broadcast: Boolean
    $is_show_waiting_eta: Boolean
    $is_show_return_eta: Boolean
    $is_show_reach_eta: Boolean
    $orders_reach_eta: Boolean
    $orders_reach_dod_eta: Boolean
    $eta_tracking_url: Boolean
    $route_tracking_url: Boolean
    $is_new_autoassign: Boolean
    $is_autoassign_v1: Boolean
    $is_scan_orders: Boolean
    $radius: Int
    $arrived_for_pickup_radius: String
    $enable_pickup_after: Boolean
    $is_photo_on_pickup: Boolean
    $is_photo: Boolean
    $is_emirateId: Boolean
    $is_signature: Boolean
    $is_return: Boolean
    $is_show_waiting: Boolean
    $sos_compliance: String
    $sos_compared_against: String
    $arrived_for_pickup_check: String
    $zc_waiting_orders: Boolean
    $is_delivery_partner_auto_assignment_enabled: Boolean
    $compliance_pickup: Boolean
    $compliance_delivery: Boolean
    $is_cash_center: Boolean
    $is_force_del_area: Boolean
    $aggregators: String
    $delivery_partner: [deliveryPartnerInput]
    $expected_sos_start: String
    $expected_sos_end: String
    $target_assign: String
    $target_order: String
    $target_wait: String
    $target_customer: String
    $target_ins: String
    $target_drive: String
    $target_sos: String
    $is_dp_enabled: String
    $order_partner: OrderPartnerInput
    $logo: String
    $user_id: Int!
    $is_mandatory_scanning_pickup: Boolean
    $is_mandatory_scanning_delivery: Boolean
    $is_full_acceptance_or_return: Boolean
  ) {
    createPoi(
      input: {
        details: {
          area_manager_name: $area_manager_name
          area_manager_phone: $area_manager_phone
          brand_ids: $brand_ids
          bulding_nr: $bulding_nr
          city_id: $city_id
          client_id: $client_id
          contact_email: $contact_email
          contact_person: $contact_person
          contact_phone: $contact_phone
          country_id: $country_id
          edge_points: $edge_points
          latitude: $latitude
          longitude: $longitude
          name: $name
          operation_zone: $operation_zone
          order_type: $order_type
          postal_code: $postal_code
          store_manager_phone: $store_manager_phone
          street: $street
          zip_code: $zip_code
          number_gln: $number_gln
          aggregators: $aggregators
          delivery_partner: $delivery_partner
          order_partner: $order_partner
        }
        settings: {
          track_api_new: $track_api_new
          track_share_eta: $track_share_eta
          return_track_id: $return_track_id
          upfront_payment_by_driver: $upfront_payment_by_driver
          Location_Algorithm_2: $Location_Algorithm_2
          is_convert_address: $is_convert_address
          order_acceptance_check: $order_acceptance_check
          Check_for_Delivery_Boundary: $Check_for_Delivery_Boundary
          Check_for_Delivery_Area: $Check_for_Delivery_Area
          Check_for_Team_Boundary: $Check_for_Team_Boundary
          order_fields: $order_fields
          start_tracking: $start_tracking
          dod_request_time: $dod_request_time
          dod_request_time: $dod_request_time
          time_to_switch_delivery_partner: $time_to_switch_delivery_partner
          dod_withdrawal: $dod_withdrawal
          target_dod_arrival_time: $target_dod_arrival_time
          dod_target_assign: $dod_target_assign
          distance_app: $distance_app
          eta_for_broadcast: $eta_for_broadcast
          is_show_waiting_eta: $is_show_waiting_eta
          is_show_return_eta: $is_show_return_eta
          is_show_reach_eta: $is_show_reach_eta
          orders_reach_eta: $orders_reach_eta
          orders_reach_dod_eta: $orders_reach_dod_eta
          eta_tracking_url: $eta_tracking_url
          route_tracking_url: $route_tracking_url
          is_new_autoassign: $is_new_autoassign
          is_autoassign_v1: $is_autoassign_v1
          is_scan_orders: $is_scan_orders
          arrived_for_pickup_radius: $arrived_for_pickup_radius
          enable_pickup_after: $enable_pickup_after
          is_photo_on_pickup: $is_photo_on_pickup
          is_photo: $is_photo
          is_emirateId: $is_emirateId
          is_signature: $is_signature
          is_return: $is_return
          is_show_waiting: $is_show_waiting
          sos_compliance: $sos_compliance
          sos_compared_against: $sos_compared_against
          arrived_for_pickup_check: $arrived_for_pickup_check
          zc_waiting_orders: $zc_waiting_orders
          is_delivery_partner_auto_assignment_enabled: $is_delivery_partner_auto_assignment_enabled
          compliance_pickup: $compliance_pickup
          compliance_delivery: $compliance_delivery
          compliance_delivery: $compliance_delivery
          is_cash_center: $is_cash_center
          is_force_del_area: $is_force_del_area
          expected_sos_start: $expected_sos_start
          expected_sos_end: $expected_sos_end
          target_assign: $target_assign
          target_order: $target_order
          target_wait: $target_wait
          target_customer: $target_customer
          target_ins: $target_ins
          target_drive: $target_drive
          target_sos: $target_sos
          currency: $currency
          timezone: $timezone
          open_t: $open_t
          close_t: $close_t
          go_live_date: $go_live_date
          is_dp_enabled: $is_dp_enabled
          radius: $radius
          is_mandatory_scanning_pickup: $is_mandatory_scanning_pickup
          is_mandatory_scanning_delivery: $is_mandatory_scanning_delivery
          is_full_acceptance_or_return: $is_full_acceptance_or_return
        }
        teams: $teams
        delivery_areas: $delivery_areas
        logo: $logo
        user_id: $user_id
      }
    ) {
      data
      success
    }
  }
`;

export const UPDATE_POI = gql`
  mutation updatePoi(
    $id: ID!
    $area_manager_name: String
    $area_manager_phone: String
    $brand_ids: String
    $bulding_nr: String
    $city_id: Int!
    $client_id: Int!
    $contact_email: String
    $contact_person: String
    $contact_phone: String
    $country_id: Int!
    $edge_points: String
    $latitude: String
    $longitude: String
    $name: String!
    $operation_zone: String
    $order_type: String
    $postal_code: String
    $store_manager_phone: String
    $street: String
    $zip_code: String
    $currency: String!
    $timezone: String!
    $open_t: String!
    $close_t: String!
    $go_live_date: String
    $zip_code: String
    $teams: [CreatePoiTeam]
    $delivery_areas: [CreateDeliveryArea]
    $track_api_new: Boolean
    $track_share_eta: Boolean
    $return_track_id: Boolean
    $number_gln: String!
    $upfront_payment_by_driver: Boolean
    $Location_Algorithm_2: Boolean
    $is_convert_address: Boolean
    $order_acceptance_check: String
    $Check_for_Delivery_Boundary: String
    $Check_for_Delivery_Area: String
    $Check_for_Team_Boundary: String
    $order_fields: String
    $start_tracking: String
    $dod_request_time: String
    $dod_request_time: String
    $time_to_switch_delivery_partner: String
    $dod_withdrawal: String
    $target_dod_arrival_time: String
    $dod_target_assign: String
    $distance_app: String
    $eta_for_broadcast: Boolean
    $is_show_waiting_eta: Boolean
    $is_show_return_eta: Boolean
    $is_show_reach_eta: Boolean
    $orders_reach_eta: Boolean
    $orders_reach_dod_eta: Boolean
    $eta_tracking_url: Boolean
    $route_tracking_url: Boolean
    $is_new_autoassign: Boolean
    $is_autoassign_v1: Boolean
    $is_scan_orders: Boolean
    $radius: Int
    $arrived_for_pickup_radius: String
    $enable_pickup_after: Boolean
    $is_photo_on_pickup: Boolean
    $is_photo: Boolean
    $is_emirateId: Boolean
    $is_signature: Boolean
    $is_return: Boolean
    $is_show_waiting: Boolean
    $sos_compliance: String
    $sos_compared_against: String
    $arrived_for_pickup_check: String
    $zc_waiting_orders: Boolean
    $is_delivery_partner_auto_assignment_enabled: Boolean
    $compliance_pickup: Boolean
    $compliance_delivery: Boolean
    $is_cash_center: Boolean
    $is_force_del_area: Boolean
    $expected_sos_start: String
    $expected_sos_end: String
    $target_assign: String
    $target_order: String
    $target_wait: String
    $target_customer: String
    $target_ins: String
    $target_drive: String
    $aggregators: String
    $delivery_partner: [deliveryPartnerInput]
    $target_sos: String
    $order_partner: OrderPartnerInput
    $logo: String
    $user_id: Int!
    $is_dp_enabled: String
    $is_mandatory_scanning_pickup: Boolean
    $is_mandatory_scanning_delivery: Boolean
    $is_full_acceptance_or_return: Boolean
  ) {
    updatePoi(
      id: $id
      input: {
        details: {
          area_manager_name: $area_manager_name
          area_manager_phone: $area_manager_phone
          brand_ids: $brand_ids
          bulding_nr: $bulding_nr
          city_id: $city_id
          client_id: $client_id
          contact_email: $contact_email
          contact_person: $contact_person
          contact_phone: $contact_phone
          country_id: $country_id
          edge_points: $edge_points
          latitude: $latitude
          longitude: $longitude
          name: $name
          operation_zone: $operation_zone
          order_type: $order_type
          postal_code: $postal_code
          store_manager_phone: $store_manager_phone
          street: $street
          zip_code: $zip_code
          number_gln: $number_gln
          aggregators: $aggregators
          delivery_partner: $delivery_partner
          order_partner: $order_partner
        }
        settings: {
          track_api_new: $track_api_new
          track_share_eta: $track_share_eta
          return_track_id: $return_track_id
          upfront_payment_by_driver: $upfront_payment_by_driver
          Location_Algorithm_2: $Location_Algorithm_2
          is_convert_address: $is_convert_address
          order_acceptance_check: $order_acceptance_check
          Check_for_Delivery_Boundary: $Check_for_Delivery_Boundary
          Check_for_Delivery_Area: $Check_for_Delivery_Area
          Check_for_Team_Boundary: $Check_for_Team_Boundary
          order_fields: $order_fields
          start_tracking: $start_tracking
          dod_request_time: $dod_request_time
          dod_request_time: $dod_request_time
          time_to_switch_delivery_partner: $time_to_switch_delivery_partner
          dod_withdrawal: $dod_withdrawal
          target_dod_arrival_time: $target_dod_arrival_time
          dod_target_assign: $dod_target_assign
          distance_app: $distance_app
          eta_for_broadcast: $eta_for_broadcast
          is_show_waiting_eta: $is_show_waiting_eta
          is_show_return_eta: $is_show_return_eta
          is_show_reach_eta: $is_show_reach_eta
          orders_reach_eta: $orders_reach_eta
          orders_reach_dod_eta: $orders_reach_dod_eta
          eta_tracking_url: $eta_tracking_url
          route_tracking_url: $route_tracking_url
          is_new_autoassign: $is_new_autoassign
          is_autoassign_v1: $is_autoassign_v1
          is_scan_orders: $is_scan_orders
          arrived_for_pickup_radius: $arrived_for_pickup_radius
          enable_pickup_after: $enable_pickup_after
          is_photo_on_pickup: $is_photo_on_pickup
          is_photo: $is_photo
          is_emirateId: $is_emirateId
          is_signature: $is_signature
          is_return: $is_return
          is_show_waiting: $is_show_waiting
          sos_compliance: $sos_compliance
          sos_compared_against: $sos_compared_against
          arrived_for_pickup_check: $arrived_for_pickup_check
          zc_waiting_orders: $zc_waiting_orders
          is_delivery_partner_auto_assignment_enabled: $is_delivery_partner_auto_assignment_enabled
          compliance_pickup: $compliance_pickup
          compliance_delivery: $compliance_delivery
          is_cash_center: $is_cash_center
          is_force_del_area: $is_force_del_area
          expected_sos_start: $expected_sos_start
          expected_sos_end: $expected_sos_end
          target_assign: $target_assign
          target_order: $target_order
          target_wait: $target_wait
          target_customer: $target_customer
          target_ins: $target_ins
          target_drive: $target_drive
          target_sos: $target_sos
          currency: $currency
          timezone: $timezone
          open_t: $open_t
          close_t: $close_t
          go_live_date: $go_live_date
          radius: $radius
          is_dp_enabled: $is_dp_enabled
          is_mandatory_scanning_pickup: $is_mandatory_scanning_pickup
          is_mandatory_scanning_delivery: $is_mandatory_scanning_delivery
          is_full_acceptance_or_return: $is_full_acceptance_or_return
        }
        teams: $teams
        delivery_areas: $delivery_areas
        logo: $logo
        user_id: $user_id
      }
    ) {
      data
      success
    }
  }
`;

export const EDIT_MULTIPLE_POI = gql`
  mutation updateMultiplePois(
    $ids: String
    $track_api_new: Boolean
    $track_share_eta: Boolean
    $return_track_id: Boolean
    $upfront_payment_by_driver: Boolean
    $Location_Algorithm_2: Boolean
    $is_convert_address: Boolean
    $order_acceptance_check: String
    $Check_for_Delivery_Boundary: String
    $Check_for_Delivery_Area: String
    $Check_for_Team_Boundary: String
    $order_fields: String
    $start_tracking: String
    $dod_request_time: String
    $dod_request_time: String
    $time_to_switch_delivery_partner: String
    $dod_withdrawal: String
    $target_dod_arrival_time: String
    $dod_target_assign: String
    $distance_app: String
    $eta_for_broadcast: Boolean
    $is_show_waiting_eta: Boolean
    $is_show_return_eta: Boolean
    $is_show_reach_eta: Boolean
    $orders_reach_eta: Boolean
    $orders_reach_dod_eta: Boolean
    $eta_tracking_url: Boolean
    $route_tracking_url: Boolean
    $is_new_autoassign: Boolean
    $is_autoassign_v1: Boolean
    $is_scan_orders: Boolean
    $radius: Int
    $arrived_for_pickup_radius: String
    $enable_pickup_after: Boolean
    $is_photo_on_pickup: Boolean
    $is_photo: Boolean
    $is_emirateId: Boolean
    $is_signature: Boolean
    $is_return: Boolean
    $is_show_waiting: Boolean
    $sos_compliance: String
    $sos_compared_against: String
    $arrived_for_pickup_check: String
    $zc_waiting_orders: Boolean
    $is_delivery_partner_auto_assignment_enabled: Boolean
    $is_dp_enabled: String
    $compliance_pickup: Boolean
    $compliance_delivery: Boolean
    $is_cash_center: Boolean
    $is_force_del_area: Boolean
    $expected_sos_start: String
    $expected_sos_end: String
    $target_assign: String
    $target_order: String
    $target_wait: String
    $target_customer: String
    $target_ins: String
    $target_drive: String
    $target_sos: String
    $user_id: Int!
  ) {
    updateMultiplePois(
      ids: $ids
      input: {
        track_api_new: $track_api_new
        track_share_eta: $track_share_eta
        return_track_id: $return_track_id
        upfront_payment_by_driver: $upfront_payment_by_driver
        Location_Algorithm_2: $Location_Algorithm_2
        is_convert_address: $is_convert_address
        order_acceptance_check: $order_acceptance_check
        Check_for_Delivery_Boundary: $Check_for_Delivery_Boundary
        Check_for_Delivery_Area: $Check_for_Delivery_Area
        Check_for_Team_Boundary: $Check_for_Team_Boundary
        order_fields: $order_fields
        start_tracking: $start_tracking
        dod_request_time: $dod_request_time
        dod_request_time: $dod_request_time
        time_to_switch_delivery_partner: $time_to_switch_delivery_partner
        dod_withdrawal: $dod_withdrawal
        target_dod_arrival_time: $target_dod_arrival_time
        dod_target_assign: $dod_target_assign
        distance_app: $distance_app
        eta_for_broadcast: $eta_for_broadcast
        is_show_waiting_eta: $is_show_waiting_eta
        is_show_return_eta: $is_show_return_eta
        is_show_reach_eta: $is_show_reach_eta
        orders_reach_eta: $orders_reach_eta
        orders_reach_dod_eta: $orders_reach_dod_eta
        eta_tracking_url: $eta_tracking_url
        route_tracking_url: $route_tracking_url
        is_new_autoassign: $is_new_autoassign
        is_autoassign_v1: $is_autoassign_v1
        is_scan_orders: $is_scan_orders
        arrived_for_pickup_radius: $arrived_for_pickup_radius
        enable_pickup_after: $enable_pickup_after
        is_photo_on_pickup: $is_photo_on_pickup
        is_photo: $is_photo
        is_emirateId: $is_emirateId
        is_signature: $is_signature
        is_return: $is_return
        is_show_waiting: $is_show_waiting
        sos_compliance: $sos_compliance
        sos_compared_against: $sos_compared_against
        arrived_for_pickup_check: $arrived_for_pickup_check
        zc_waiting_orders: $zc_waiting_orders
        is_delivery_partner_auto_assignment_enabled: $is_delivery_partner_auto_assignment_enabled
        is_dp_enabled: $is_dp_enabled
        compliance_pickup: $compliance_pickup
        compliance_delivery: $compliance_delivery
        is_cash_center: $is_cash_center
        is_force_del_area: $is_force_del_area
        expected_sos_start: $expected_sos_start
        expected_sos_end: $expected_sos_end
        target_assign: $target_assign
        target_order: $target_order
        target_wait: $target_wait
        target_customer: $target_customer
        target_ins: $target_ins
        target_drive: $target_drive
        target_sos: $target_sos
        radius: $radius
      }
      user_id: $user_id
    ) {
      data
      success
    }
  }
`;

export const ADD_POI_DELIVERY_PARTNER = gql`
  mutation addPoiDeliveryPartner(
    $client_id: Int
    $dp_id: Int
    $poi_id: Int
    $preference: Int
  ) {
    addPoiDeliveryPartner(
      client_id: $client_id
      dp_id: $dp_id
      poi_id: $poi_id
      preference: $preference
    ) {
      data
      success
    }
  }
`;

export const SYNC_POI_DELIVERY_PARTNERS = gql`
  mutation syncPoiDeliveryPartners(
    $client_id: Int
    $poi_id: Int
    $partners: [deliveryPartnerInput]
  ) {
    syncPoiDeliveryPartners(
      client_id: $client_id
      poi_id: $poi_id
      partners: $partners
    ) {
      data
      success
    }
  }
`;

export const SYNC_POIS_DELIVERY_PARTNERS = gql`
  mutation syncPoisDeliveryPartners(
    $client_id: Int
    $pois: [poiDeliveryPartnerInput]
  ) {
    syncPoisDeliveryPartners(client_id: $client_id, pois: $pois) {
      data
      success
    }
  }
`;

export const ATTACH_POIS_DELIVERY_PARTNERS = gql`
  mutation attachPoisDeliveryPartners(
    $client_id: Int
    $pois: [Int]
    $partners: [deliveryPartnerInput]
  ) {
    attachPoisDeliveryPartners(client_id: $client_id, pois: $pois, partners: $partners) {
      data
      success
    }
  }
`;

export const DETACH_POIS_DELIVERY_PARTNERS = gql`
  mutation detachPoisDeliveryPartners(
    $pois: [Int]
    $partners: [Int]
  ) {
    detachPoisDeliveryPartners(pois: $pois, partners: $partners) {
      data
      success
    }
  }
`;

export const UPDATE_POIS_DELIVERY_PARTNERS_PRIORITY = gql`
  mutation updatePoisDeliveryPartnersPriority(
    $pois: [Int]
    $partners: [deliveryPartnerInput]
  ) {
    updatePoisDeliveryPartnersPriority(pois: $pois, partners: $partners) {
      data
      success
    }
  }
`;

export const UPDATE_POIS_DELIVERY_PARTNERS = gql`
  mutation updatePoisDeliveryPartners(
    $pois: [Int]
    $partners: [deliveryPartnerInput]
  ) {
    updatePoisDeliveryPartners(pois: $pois, partners: $partners) {
      data
      success
    }
  }
`;



export const GENERATE_POI_QR_CODE = gql`
  mutation generatePoiQrCode($id: ID!) {
    generatePoiQrCode(id: $id) {
      data
      success
    }
  }
`;

export const UPDATE_ORDER_PARTNER = gql`
  mutation updateOrderPartner(
    $id: String
    $partner_type: Int
    $partner_reference_id: String
    $pickup_number: String
  ) {
    updateOrderPartner(
      input: {
        id: $id
        partner_type: $partner_type
        partner_reference_id: $partner_reference_id
        pickup_number: $pickup_number
      }
    ) {
      data
      success
    }
  }
`;

export const CREATE_ORDER_PARTNER = gql`
  mutation createOrderPartner(
    $client_id: Int
    $partner_reference_id: String
    $partner_type: Int
    $pickup_number: String
    $poi_id: Int
    $store_info: AWSJSON
  ) {
    createOrderPartner(
      input: {
        client_id: $client_id
        partner_reference_id: $partner_reference_id
        partner_type: $partner_type
        pickup_number: $pickup_number
        poi_id: $poi_id
        store_info: $store_info
      }
    ) {
      data
      success
    }
  }
`;

export const CREATE_AGGREGATOR = gql`
  mutation createAggregator(
    $client_id: Int
    $enabled_client_id: Int
    $poi_id: Int
  ) {
    createAggregator(
      client_id: $client_id
      enabled_client_id: $enabled_client_id
      poi_id: $poi_id
    ) {
      data
      success
    }
  }
`;

export const ACTIVATE_POI = gql`
  mutation activatePoi($ids: String!) {
    activatePois(ids: $ids) {
      data
      success
    }
  }
`;

export const DEACTIVATE_POI = gql`
  mutation deactivatePoi($ids: String!) {
    deactivatePois(ids: $ids) {
      data
      success
    }
  }
`;

export const DELETE_POI = gql`
  mutation deletePoi($ids: String!) {
    deletePois(ids: $ids) {
      data
      success
    }
  }
`;

export const DELETE_AGGREGATOR = gql`
  mutation deleteAggregator($poi_id: Int, $enabled_client_id: Int) {
    deleteAggregator(poi_id: $poi_id, enabled_client_id: $enabled_client_id) {
      data
      success
    }
  }
`;

export const POST_IMPORT_POIS = gql`
  mutation importPois($client_id: Int!, $user_id: Int!, $xls_file: String!) {
    importPois(client_id: $client_id, user_id: $user_id, xls_file: $xls_file) {
      data
      success
    }
  }
`;

export const EXPORT_POIS = gql`
  mutation exportPois(
    $client_id: String
    $name: String
    $status: String
    $assignment: String
    $cities: String
    $brands: String
    $countries: String
    $poi_id: String
    $reference_id_order_creation: String
    $reference_id_wayanaq: String
    $teams: String
    $userId: Int
  ) {
    exportPois(
      filters: {
        name: $name
        client_id: $client_id
        status: $status
        assignment: $assignment
        cities: $cities
        brands: $brands
        countries: $countries
        poi_id: $poi_id
        reference_id_order_creation: $reference_id_order_creation
        reference_id_wayanaq: $reference_id_wayanaq
        teams: $teams
        user_id: $userId
      }
    ) {
      data
      success
    }
  }
`;
