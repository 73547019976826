import { createSlice } from "@reduxjs/toolkit";

export const configDefaultValues = {
  target_assign: "5",
  target_order: "10",
  target_wait: "5",
  target_customer: "5",
  target_ins: "15",
  target_drive: "30",
  target_sos: "45",
  radius: "250",
  arrived_for_pickup_radius: "250",
  is_new_autoassign: "v2.0",
  Location_Algorithm_2: "true",
  order_partner: {
    partner_type: "",
    partner_reference_id: "",
    ref_id_err: false,
  },
  order_fields: { DELIVERY_AREA: false, DESTINATION_LAT_LONG: false },
  distance_app: { ACTUAL: false, PLANNED: false },
  multiEditDp: {
    is_dp_enabled: {
      id: "is_dp_enabled",
      name: "Delivery partner",
      checked: false,
    },
    is_delivery_partner_auto_assignment_enabled: {
      id: "is_delivery_partner_auto_assignment_enabled",
      name: "Delivery partner Auto Assignment",
      checked: false,
    },
    dod_request_time: {
      id: "dod_request_time",
      name: "Internal Driver Search Timeout",
      checked: false,
    },
    target_dod_arrival_time: {
      id: "target_dod_arrival_time",
      name: "Target Arrival to Poi (DOD)",
      checked: false,
    },
    dod_target_assign: {
      id: "dod_target_assign",
      name: "Target Assign (DOD)",
      checked: false,
    },
    is_edit_delivery_partner_sections: {
      id: "is_edit_delivery_partner_sections",
      name: "Is Edit Delivery Partner Sections",
      checked: false,
    },
    edit_delivery_partner_sections: {
      id: "edit_delivery_partner_sections",
      name: "Edit Delivery Partner Sections",
      checked: "template"
    },
    add_delivery_partner: {
      id: "add_delivery_partner",
      name: "Add Delivery Partner",
      checked: false,
    },
    delete_delivery_partner: {
      id: "delete_delivery_partner",
      name: "Delete Delivery Partner",
      checked: false,
    },
    update_delivery_partner_priority: {
      id: "update_delivery_partner_priority",
      name: "Update Delivery Partner Priority",
      checked: false,
    },
    update_delivery_partner_switching_time: {
      id: "update_delivery_partner_switching_time",
      name: "Update Delivery Partner Switching Time",
      checked: false,
    },
  },
};

export const configTabSlice = createSlice({
  name: "configTab",
  initialState: {
    value: configDefaultValues,
    dropdownOptions: { deliveryPartnersList: [], orderPartnersList: [] },
    clientConfigs: {},
  },
  reducers: {
    configTab: (state, action) => {
      state.value = action.payload;
    },
    initializeMultiEditDp: (state) => {
      if (state.value.viewType === "multiEdit") {
        state.value.multiEditDp.is_dp_enabled.checked = false;
        state.value.multiEditDp.is_delivery_partner_auto_assignment_enabled.checked = false;
        state.value.multiEditDp.dod_request_time.checked = false;
        state.value.multiEditDp.target_dod_arrival_time.checked = false;
        state.value.multiEditDp.dod_target_assign.checked = false;
        state.value.multiEditDp.is_edit_delivery_partner_sections.checked = false;
        state.value.multiEditDp.edit_delivery_partner_sections.value = "template";

        state.value.delivery_partner?.map((item) => {
          item.pty_checked = false;
          item.swt_cheked = false;
          return item;
        });
      }
    },
    configDropdownOptions: (state, action) => {
      state.dropdownOptions = action.payload;
    },
    clientConfigs: (state, action) => {
      state.clientConfigs = action.payload;
    },
  },
});

export const {
  configTab,
  configDropdownOptions,
  clientConfigs,
  initializeMultiEditDp,
} = configTabSlice.actions;

export default configTabSlice.reducer;
