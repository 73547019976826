import * as React from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import { FormControlLabel, Grid, MenuItem, Radio, RadioGroup, Typography } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import TextField from "@mui/material/TextField";
import InputAdornment from "@mui/material/InputAdornment";
import { configTab } from "../../reducers/configTab";
import PoiService from "../../services/PoiService";

const PoiBasicConfig = () => {
  const poiService = new PoiService();

  const configTabSelector = useSelector((state) => state.configTab.value);
  const user = useSelector((state) => state.userInfo.value);
  const clientConfigsSelector = useSelector(
    (state) => state.configTab.clientConfigs
  );
  const dispatch = useDispatch();

  const [expanded, setExpanded] = React.useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const handleInputChange = (value, inField) => {
    const tempFields = poiService.getDeepCopy(configTabSelector);
    tempFields[inField] = value;
    dispatch(configTab(tempFields));
  };

  return (
    <>
      {/* <Accordion expanded={expanded === 'panel1'} onChange={handleChange('panel1')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel1a-content"
                    id="panel1a-header"
                    sx={{
                        backgroundColor: "#ccc"
                    }}
                >

                    <Typography>Cashier
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography>
                                Cashier start date
                                <Tooltip title="Set a cashier start date. You can see orders in the Cashier module from this date onwards. Cashier module is used to collect the COD (Cash on Delivery) amount from the drivers.">
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Checkbox name="cashier_start_date" value={values.cashier_start_date} {...label} defaultChecked={values.cashier_start_date} />
                        </Grid>
                    </Grid>
                </AccordionDetails>
            </Accordion> */}
      {/* <Accordion expanded={expanded === 'panel2'} onChange={handleChange('panel2')}>
                <AccordionSummary
                    expandIcon={<ExpandMoreIcon />}
                    aria-controls="panel2a-content"
                    id="panel2a-header"
                    sx={{
                        backgroundColor: "#ccc"
                    }}
                >
                    <Typography>Order Assignment
                    </Typography>
                </AccordionSummary>
                <AccordionDetails>
                    <Grid container spacing={2}>
                        <Grid item xs={6}>
                            <Typography>
                                Enable auto assignment
                                <Tooltip title="When selected, the orders will be auto assigned to the drivers.">
                                    <IconButton>
                                        <InfoIcon />
                                    </IconButton>
                                </Tooltip>
                            </Typography>
                        </Grid>

                        <Grid item xs={6}>
                            <Checkbox name="enable_assignment" value={values.enable_assignment} {...label} defaultChecked={values.cashier_start_date} />
                        </Grid>
                    </Grid>

                </AccordionDetails>
            </Accordion> */}
      <Accordion
        expanded={expanded === "panel3"}
        onChange={handleChange("panel3")}
      >
        <AccordionSummary
          expandIcon={<ExpandMoreIcon />}
          aria-controls="panel2a-content"
          id="panel2a-header"
          sx={{
            backgroundColor: "#ccc",
          }}
        >
          <Typography>SLAs</Typography>
        </AccordionSummary>
        <AccordionDetails>
          <Grid container spacing={2}>
            <Grid item xs={6}>
              <Typography>
                Target assign time
                <Tooltip title="Enter target time in minutes for the assignment. It is the time from assignement trigger event until order assignment">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                size="small"
                type="number"
                value={configTabSelector.target_assign || ""}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                  endAdornment: (
                    <InputAdornment position="end"> mins</InputAdornment>
                  ),
                }}
                label="Enter"
                onChange={(ev) =>
                  handleInputChange(ev.target.value, "target_assign")
                }
                disabled={!user.permissions.poi_editPoiConfiguration}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography>
                Target arrival to POI time
                <Tooltip title="Enter target time in minutes for arrival to POI time. It is the time from order assignment until driver arrives at the POI for pickup">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                size="small"
                type="number"
                value={configTabSelector.target_order || ""}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                  endAdornment: (
                    <InputAdornment position="end"> mins</InputAdornment>
                  ),
                }}
                label="Enter"
                onChange={(ev) =>
                  handleInputChange(ev.target.value, "target_order")
                }
                disabled={!user.permissions.poi_editPoiConfiguration}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography>
                Target wait at POI
                <Tooltip title="Enter target time in minutes for wait at POI time. It is the time from driver arrives for pickup at the POI until order pickup.">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                size="small"
                type="number"
                value={configTabSelector.target_wait || ""}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                  endAdornment: (
                    <InputAdornment position="end"> mins</InputAdornment>
                  ),
                }}
                label="Enter"
                onChange={(ev) =>
                  handleInputChange(ev.target.value, "target_wait")
                }
                disabled={!user.permissions.poi_editPoiConfiguration}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography>
                Target at customer
                <Tooltip title="Enter target time in minutes for the at customer time. It is the time from driver reaching the customer premise until order delivery">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                size="small"
                type="number"
                value={configTabSelector.target_customer || ""}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                  endAdornment: (
                    <InputAdornment position="end"> mins</InputAdornment>
                  ),
                }}
                label="Enter"
                onChange={(ev) =>
                  handleInputChange(ev.target.value, "target_customer")
                }
                disabled={!user.permissions.poi_editPoiConfiguration}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography>
                Target in store time
                <Tooltip title="Enter target time in minutes for the in store/POI time. It is the time from SOS start time until order pickup.">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                size="small"
                type="number"
                value={configTabSelector.target_ins || ""}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                  endAdornment: (
                    <InputAdornment position="end"> mins</InputAdornment>
                  ),
                }}
                label="Enter"
                onChange={(ev) =>
                  handleInputChange(ev.target.value, "target_ins")
                }
                disabled={!user.permissions.poi_editPoiConfiguration}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography>
                Target drive time
                <Tooltip title="Enter target time in minutes for the drive time. It is the time from order pickup until order delivery">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                size="small"
                type="number"
                value={configTabSelector.target_drive || ""}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                  endAdornment: (
                    <InputAdornment position="end"> mins</InputAdornment>
                  ),
                }}
                label="Enter"
                onChange={(ev) =>
                  handleInputChange(ev.target.value, "target_drive")
                }
                disabled={!user.permissions.poi_editPoiConfiguration}
              />
            </Grid>

            <Grid item xs={6}>
              <Typography>
                Target SOS
                <Tooltip title="Enter target time in minutes for the SOS (Speed of Service). It is the time from SOS start time until order delivery.">
                  <IconButton>
                    <InfoIcon />
                  </IconButton>
                </Tooltip>
              </Typography>
            </Grid>

            <Grid item xs={6}>
              <TextField
                sx={{ width: "100%" }}
                size="small"
                type="number"
                name="target_sos"
                value={configTabSelector.target_sos || ""}
                InputProps={{
                  inputProps: {
                    max: 100,
                    min: 0,
                  },
                  endAdornment: (
                    <InputAdornment position="end"> mins</InputAdornment>
                  ),
                }}
                label="Enter"
                onChange={(ev) =>
                  handleInputChange(ev.target.value, "target_sos")
                }
                disabled={!user.permissions.poi_editPoiConfiguration}
              />
            </Grid>
          </Grid>
        </AccordionDetails>
      </Accordion>

      <Accordion
          expanded={expanded === "panel8"}
          onChange={handleChange("panel8")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel8a-content"
            id="panel8a-header"
            sx={{
              backgroundColor: "#ccc",
            }}
          >
            <Typography>Order Assignment</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography>
                  Use auto assignment
                  <Tooltip title="When selected, the orders will be auto assigned to the drivers using auto assignment algorithm v1.0.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          configTabSelector.is_new_autoassign === "v1.0" ||
                          false
                        }
                        value={configTabSelector.is_new_autoassign}
                        onChange={() =>
                          handleInputChange("v1.0", "is_new_autoassign")
                        }
                        disabled={!user.permissions.poi_editPoiConfiguration}
                      />
                    }
                    label="v1.0"
                  />
                  <FormControlLabel
                    value="v2.0"
                    control={
                      <Radio
                        checked={
                          configTabSelector.is_new_autoassign === "v2.0" ||
                          false
                        }
                        value={configTabSelector.is_new_autoassign}
                        onChange={() =>
                          handleInputChange("v2.0", "is_new_autoassign")
                        }
                        disabled={!user.permissions.poi_editPoiConfiguration}
                      />
                    }
                    label="v2.0"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>

        {/* <Accordion
          expanded={expanded === "panel4"}
          onChange={handleChange("panel4")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel4a-content"
            id="panel4a-header"
            sx={{
              backgroundColor: "#ccc",
            }}
          >
            <Typography>Customer View</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography>
                  Start order tracking in Customer View at
                  <Tooltip title="Order tracking in the Customer View will start at the selected event.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  size="small"
                  select
                  variant="outlined"
                  label="Select"
                  sx={{ width: "100%" }}
                  value={configTabSelector.start_tracking || ""}
                  onChange={(ev) =>
                    handleInputChange(ev.target.value, "start_tracking")
                  }
                  disabled={
                    !user.permissions.poi_editPoiConfiguration ||
                    clientConfigsSelector.start_tracking == ""
                  }
                >
                  <MenuItem value={"Order assigned"}>Order Assigned</MenuItem>
                  <MenuItem value={"Order picked"}>Order Picked</MenuItem>
                </TextField>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion> */}
    </>
  );
};

export default PoiBasicConfig;
