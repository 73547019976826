import { createSlice } from "@reduxjs/toolkit";

export const assignedTeamSlice = createSlice({
  name: "teamAssign",
  initialState: { value: [] },
  reducers: {
    teamAssign: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { teamAssign } = assignedTeamSlice.actions;

export default assignedTeamSlice.reducer;
