import React, { useCallback, useEffect, useRef, useState } from "react";
import Autocomplete from "@mui/material/Autocomplete";
import Button from "@mui/material/Button";
import TextField from "@mui/material/TextField";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TablePagination from "@mui/material/TablePagination";
import TableRow from "@mui/material/TableRow";
import Checkbox from "@mui/material/Checkbox";
import Tooltip from "@mui/material/Tooltip";
import {
  CheckBoxSelection,
  Inject,
  MultiSelectComponent,
} from "@syncfusion/ej2-react-dropdowns";

import "./PoiList.css";
import ImportMenu from "../../components/ImportMenu";
import SettingsMenu from "../../components/SettingsMenu";
import PoiTabs from "../PoiTabs";

import { useApolloClient } from "@apollo/client";
import {
  GET_ACCOUNTS,
  GET_FILTERS,
  GET_POI,
  GET_POIS_LIST,
  GET_USER,
} from "../../graphql/queries";
import { Spinner } from "reactstrap";
import { EXPORT_POIS } from "./../../graphql/mutations";
import { useMutation } from "@apollo/client";
import ConfirmationDialog from "../../components/ConfirmationDiolog";
import {
  ACTIVATE_POI,
  DEACTIVATE_POI,
  DELETE_POI,
} from "../../graphql/mutations";
import AlertDialog from "../../components/AlertDiolog";
import { useDispatch, useSelector } from "react-redux";
import PoiService from "../../services/PoiService";
import { userInfo } from "../../reducers/user";
import { formDetailsTab } from "../../reducers/detailTab";
import { poiList, reloadPoiList } from "../../reducers/poiLists";
import Cookies from "js-cookie";
import { Alert, Snackbar } from "@mui/material";

const PoiList = () => {
  const [isLoading, setIsLoading] = useState("false");
  const [accountsList, setAccountsList] = useState([]);
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [selectAllPOi, setSelectAllPoi] = useState(false);
  const [selectedPoiCount, setSelectedPoiCount] = useState(0);
  const [confirmProps, setConfirmProps] = useState({
    title: "",
    id: "",
    description: "",
    display: false,
  });
  const [alertProps, setAlertProps] = useState({
    title: "",
    description: "",
    display: false,
  });
  const [poisList, setPoisList] = useState([]);
  const [isIndeterminate, setIsIndeterminate] = useState(false);
  const [showImportTooltip, setShowImportTooltip] = useState(false);
  const [showSettingsTooltip, setShowSettingsTooltip] = useState(false);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [isFilterApplied, setFilterApplied] = useState(false);
  const [dropdownFilters, setDropdownFilters] = useState({
    is_active_new: [],
    brands: [],
    teams: [],
    city: [],
    country: [],
    assignment: [],
  });
  const client = useApolloClient();
  const dispatch = useDispatch();
  const poiService = new PoiService();
  const user = useSelector((state) => state.userInfo.value);
  const detailsTabSelector = useSelector((state) => state.detailTab.value);
  const poiListSelector = useSelector((state) => state.poiLists.value);
  const reloadPoiListSelector = useSelector(
    (state) => state.poiLists.reloadState
  );

  const copyPoisList = useRef([]);
  const currentPage = useRef(0);
  const selectedFilters = useRef({});
  const selectedAccount = useRef({ id: "", name: "" });
  const childFunc = React.useRef(null);
  const selectedPois = React.useRef([]);
  const hideOptions = React.useRef({
    showActivate: false,
    showDeactivate: false,
  });
  const [timerID, setTimerID] = useState(null);

  const [mutate] = useMutation(EXPORT_POIS);

  const columns = poiService.columnsForPoiList;

  const fields = { text: "name", value: "id" };
  const [snackBarState, setSnackBarState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    message: "",
  });

  const handleChangePage = (event, newPage) => {
    currentPage.current = newPage;
    getPoisList();
  };

  const onChangeSelectAll = (event) => {
    const newArr = copyPoisList.current;
    newArr.forEach((row) => {
      row["checked"] = event.target.checked;
    });
    selectedPois.current = event.target.checked
      ? newArr.map((el) => {
          return {
            id: el.id,
            name: el.name,
          };
        })
      : [];
    setSelectAllPoi(event.target.checked);
    setSelectedPoiCount(event.target.checked ? newArr.length : 0);
    setPoisList([...newArr]);
    copyPoisList.current = newArr;
    setIsIndeterminate(false);
    checkActivateDeactivateHideState();
  };

  const onPoiSelect = (ev, inRow) => {
    if (ev.target.checked) {
      selectedPois.current = [...selectedPois.current, { id: inRow.id, name: inRow.name }];
    } else {
      const index = selectedPois.current.findIndex(
        (ele) => inRow.id === ele.id
      );
      const tempArr = poiService.getDeepCopy(selectedPois.current);
      tempArr.splice(index, 1);
      selectedPois.current = tempArr;
    }
    const newTempArr = copyPoisList.current;
    newTempArr.forEach((row) => {
      if (row.id === inRow.id) {
        row["checked"] = ev.target.checked;
      }
    });
    setPoisList([...newTempArr]);
    copyPoisList.current = newTempArr;
    checkIfIndeterminate();
    checkActivateDeactivateHideState();
  };

  const checkActivateDeactivateHideState = () => {
    const checkedPois = copyPoisList.current.filter((el) => el.checked == true);
    if (checkedPois && checkedPois.length) {
      const checkForActivePois = checkedPois.filter(
        (elem) => elem.is_active == 1
      );
      const checkForInactivePois = checkedPois.filter(
        (elem) => elem.is_active == 0
      );
      if (checkForActivePois && checkForActivePois.length) {
        hideOptions.current.showDeactivate = true;
      } else {
        hideOptions.current.showDeactivate = false;
      }
      if (checkForInactivePois && checkForInactivePois.length) {
        hideOptions.current.showActivate = true;
      } else {
        hideOptions.current.showActivate = false;
      }
    } else {
      hideOptions.current = { showActivate: false, showDeactivate: false };
    }
  };

  const editPoiButtonClick = () => {
    if (selectedPois.current.length > 1) {
      if (
        user.permissions.poi_viewPoiConfiguration ||
        user.permissions.poi_editPoiConfiguration ||
        user.permissions.poi_viewDeliveryPartnerConfiguration ||
        user.permissions.poi_editDeliveryPartnerConfiguration
      ) {
        childFunc.current();
        const poiListFields = poiService.getDeepCopy(poiListSelector);
        poiListFields["viewType"] = "multiEdit";
        poiListFields["multiEditIds"] = selectedPois.current;
        dispatch(poiList(poiListFields));
      } else {
        setSnackBarState({
          open: true,
          vertical: "top",
          horizontal: "center",
          message: "You don't have the permission!",
        });
      }
    } else {
      if (
        user.permissions.poi_poiUsingHyperlink &&
        (user.permissions.poi_viewPoiDetails ||
          user.permissions.poi_editPoiDetails ||
          user.permissions.poi_viewPoiConfiguration ||
          user.permissions.poi_editPoiConfiguration ||
          user.permissions.poi_viewAssignedTeamsTab ||
          user.permissions.poi_editAssignedTeamsTab ||
          user.permissions.poi_editDeliveryAreaTab ||
          user.permissions.poi_viewDeliveryAreaTab ||
          user.permissions.poi_editDeliveryBoundaryTab ||
          user.permissions.poi_viewDeliveryBoundaryTab)
      ) {
        const poiListFields = poiService.getDeepCopy(poiListSelector);
        poiListFields["viewType"] = "edit";
        poiListFields["selectedPoi"] = selectedPois.current[0].id;
        dispatch(poiList(poiListFields));

        getPoiDetails(selectedPois.current[0].id);
      } else {
        setSnackBarState({
          open: true,
          vertical: "top",
          horizontal: "center",
          message: "You don't have the permission!",
        });
      }
    }
  };

  const getPoiDetails = async (inPoiId) => {
    setIsLoading("true");
    const tempGetPoi = await client.query({
      query: GET_POI,
      variables: { poiId: inPoiId },
      fetchPolicy: "network-only",
      errorPolicy: "all",
    });
    if (tempGetPoi.errors) {
      const errMessage = tempGetPoi.errors.map((er) => er.message);
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: errMessage,
      });
      setIsLoading("false");
    } else {
      poiService.initializeGetPoi(tempGetPoi);
      setIsLoading("false");
      childFunc.current();
    }
  };

  const checkIfIndeterminate = useCallback(() => {
    const tempSelectedCount = copyPoisList.current.filter(
      (t) => t["checked"] === true
    ).length;
    const tempTotalCount = copyPoisList.current.length;
    if (tempSelectedCount !== 0) {
      setSelectedPoiCount(tempSelectedCount);
      if (tempSelectedCount === tempTotalCount) {
        setSelectAllPoi(true);
        setIsIndeterminate(false);
      } else {
        setIsIndeterminate(true);
      }
    } else {
      setIsIndeterminate(false);
      setSelectAllPoi(false);
      setSelectedPoiCount(0);
    }
  });

  const selectedImportOption = (inOption) => {};

  const selectedSettingsOption = (inOption) => {
    if (inOption !== "close") {
      setConfirmProps(poiService.selectedSettingsOptionsForPoiList(inOption));
    }
  };

  const onImportClick = () => {
    // <ImportDialog childFunc={childFunc} />
    setShowImportTooltip(false);
  };

  const onSettingsClick = () => {
    setShowSettingsTooltip(false);
  };

  const onAccountSelection = (ev, value) => {
    selectedPois.current = [];
    selectedFilters.current = {};
    const tempFields = poiService.getDeepCopy(detailsTabSelector);
    const poiListFields = poiService.getDeepCopy(poiListSelector);
    if (value) {
      selectedAccount.current = value;
      currentPage.current = 0;
      getFilters();
      getPoisList();
    } else {
      selectedAccount.current = {};
      setFilterApplied(false);
      setTotalItemCount(0);
    }
    tempFields["client_id"] = value;
    poiListFields["selectedAccount"] = value;
    dispatch(formDetailsTab(tempFields));
    dispatch(poiList(poiListFields));
  };

  async function getPoisList() {
    setIsLoading("true");
    setPoisList([]);
    copyPoisList.current = [];
    const tempGetPoisList = await client.query({
      query: GET_POIS_LIST,
      variables: {
        client_id: selectedAccount.current.id,
        page_no: currentPage.current + 1,
        name: selectedFilters.current.name ? selectedFilters.current.name : "",
        status: selectedFilters.current.is_active_new
          ? selectedFilters.current.is_active_new.toString()
          : "",
        assignment: selectedFilters.current.is_new_autoassign
          ? selectedFilters.current.is_new_autoassign.toString()
          : "",
        cities: selectedFilters.current.city
          ? selectedFilters.current.city.toString()
          : "",
        brands: selectedFilters.current.brands
          ? selectedFilters.current.brands.toString()
          : "",
        countries: selectedFilters.current.country
          ? selectedFilters.current.country.toString()
          : "",
        poi_id: selectedFilters.current.postal_code
          ? selectedFilters.current.postal_code
          : "",
        reference_id_order_creation: selectedFilters.current.number_gln
          ? selectedFilters.current.number_gln
          : "",
        reference_id_wayanaq: selectedFilters.current.reference_id_waynaq
          ? selectedFilters.current.reference_id_waynaq
          : "",
        teams: selectedFilters.current.teams
          ? selectedFilters.current.teams.toString()
          : "",
      },
      fetchPolicy: "network-only",
    });
    setTotalItemCount(tempGetPoisList.data.listPois.total);
    copyPoisList.current = poiService.preparePoiList(
      tempGetPoisList.data.listPois.data
    );
    const checkFilterLength = Object.keys(selectedFilters.current).length;
    pageChanged(checkFilterLength ? true : false);
    setPoisList(copyPoisList.current);
    setIsLoading("false");
  }

  const getFilters = async () => {
    setIsLoading("true");
    setPoisList([]);
    copyPoisList.current = [];
    const tempGetFilters = await client.query({
      query: GET_FILTERS,
      variables: { clientId: selectedAccount.current.id },
      fetchPolicy: "network-only",
    });

    const prepareFilters = poiService.prepareFilters(tempGetFilters.data);
    setDropdownFilters(prepareFilters);
    setIsLoading("false");
  };

  const onFilterChange = (event, inColumn) => {
    const tempFilterArr = [];
    currentPage.current = 0;
    if (event.value) {
      event.value.forEach((src) => {
        tempFilterArr.push(src);
      });
      if (tempFilterArr.length) {
        selectedFilters.current[inColumn] = tempFilterArr;
      } else {
        delete selectedFilters.current[inColumn];
      }
    }

    getPoisList();
    const checkFilterLength = Object.keys(selectedFilters.current).length;
    pageChanged(checkFilterLength ? true : false);
  };

  const pageChanged = (isFilterApplied) => {
    if (isFilterApplied) {
      setFilterApplied(true);
    } else {
      setFilterApplied(false);
    }
  };

  const search = (searchString, field) => {
    selectedFilters.current[field] = searchString;
    currentPage.current = 0;

    clearTimeout(timerID);
    const id = setTimeout(() => getPoisList(), 1000);
    setTimerID(id);
  };

  const getUiElement = (column, value, id) => {
    if (column.id === "name") {
      return (
        <Tooltip title={"Click on the link to view/edit POI"} arrow>
          <span>
            <a
              href=""
              onClick={(e) => onEditPoiClick(e, id)}
              className="tab-font-wrap"
              title={value}
            >
              {value}
            </a>
          </span>
        </Tooltip>
      );
    } else {
      return poiService.getUiElement(column, value, id);
    }
  };

  //Export file.
  const onExportClick = () => {
    setIsLoading("true");
    mutate({
      variables: {
        client_id: selectedAccount.current.id,
        name: selectedFilters.current.name ? selectedFilters.current.name : "",
        status: selectedFilters.current.is_active_new
          ? selectedFilters.current.is_active_new.toString()
          : "",
        assignment: selectedFilters.current.is_new_autoassign
          ? selectedFilters.current.is_new_autoassign.toString()
          : "",
        cities: selectedFilters.current.city
          ? selectedFilters.current.city.toString()
          : "",
        brands: selectedFilters.current.brands
          ? selectedFilters.current.brands.toString()
          : "",
        countries: selectedFilters.current.country
          ? selectedFilters.current.country.toString()
          : "",
        poi_id: selectedFilters.current.postal_code
          ? selectedFilters.current.postal_code
          : "",
        reference_id_order_creation: selectedFilters.current.number_gln
          ? selectedFilters.current.number_gln
          : "",
        reference_id_wayanaq: selectedFilters.current.reference_id_waynaq
          ? selectedFilters.current.reference_id_waynaq
          : "",
        teams: selectedFilters.current.teams
          ? selectedFilters.current.teams.toString()
          : "",
        userId: user.id,
      },
      onCompleted(successResp) {
        console.log("successResp: ", successResp);
        if (successResp.exportPois.success) {
          setAlertProps({
            title: "Exported!",
            description: "The POI(s) have been exported successfully.",
            display: true,
          });
          const popUp = window.open(
            process.env.REACT_APP_BASE_API_URL + "?redirect=taskReports",
            "_blank"
          );
          if (popUp == null || typeof popUp == "undefined") {
            alert(
              'Please disable your pop-up blocker and click the "Export" link again.'
            );
          } else {
            popUp.focus();
          }
        } else {
          setSnackBarState({
            open: true,
            vertical: "top",
            horizontal: "center",
            message: "Something went wrong! Please try again ",
          });
        }
        setIsLoading("false");
      },
      onError(errRes) {
        console.log("successResp: ", errRes);
        setIsLoading("false");
        setSnackBarState({
          open: true,
          vertical: "top",
          horizontal: "center",
          message: "Something went wrong! Please try again ",
        });
      },
    });
  };

  const onConfirmClick = (ev) => {
    setConfirmProps({ title: "", id: "", description: "", display: false });
    const tempIds = copyPoisList.current
      .filter((data) => data.checked == true)
      .map((data) => data.id)
      .join();
    switch (ev.id) {
      case "activate":
        activatePoi(tempIds);
        break;
      case "deactivate":
        deactivatePoi(tempIds);
        break;
      case "delete":
        deletePoi(tempIds);
        break;
      default:
        return;
    }
  };

  const activatePoi = async (inPoiIds) => {
    setIsLoading("true");
    await client.mutate({
      mutation: ACTIVATE_POI,
      variables: { ids: inPoiIds },
    });
    setAlertProps({
      title: "Activate",
      description: "The POI(s) have been activated successfully.",
      display: true,
    });
  };

  const deactivatePoi = async (inPoiIds) => {
    setIsLoading("true");
    await client.mutate({
      mutation: DEACTIVATE_POI,
      variables: { ids: inPoiIds },
    });
    setAlertProps({
      title: "Deactivate",
      description: "The POI(s) have been deactivated successfully.",
      display: true,
    });
  };

  const deletePoi = async (inPoiIds) => {
    setIsLoading("true");
    await client.mutate({ mutation: DELETE_POI, variables: { ids: inPoiIds } });
    setAlertProps({
      title: "Delete",
      description: "The POI(s) have been deleted successfully.",
      display: true,
    });
  };

  const onCancelClick = (ev) => {
    setConfirmProps({ title: "", id: "", description: "", display: false });
  };

  const onAlertClose = (ev) => {
    setAlertProps({ title: "", description: "", display: false });
    if (ev === "Session Timeout") {
      window.location.href = process.env.REACT_APP_BASE_API_URL;
    } else {
      getPoisList();
      getFilters();
    }
  };

  const getUserData = async () => {
    setIsLoading("true");
    let userId = Cookies.get("user_id");
    if (userId) {
      const fetchUserData = await client.query({
        query: GET_USER,
        variables: { userId: userId },
        fetchPolicy: "network-only",
      });
      let userData = poiService.getDeepCopy(fetchUserData.data.getUserByID);
      userData["permissions"] = JSON.parse(
        fetchUserData.data.getUserByID.permissions
      );
      dispatch(userInfo(userData));
      getAccountsList(userId);
    } else {
      setAlertProps({
        title: "Session Timeout",
        description: "Your session timed out! Please login again",
        display: true,
      });
    }
  };

  const getAccountsList = async (inUserId) => {
    const tempGetAccounts = await client.query({
      query: GET_ACCOUNTS,
      variables: { userId: inUserId },
      fetchPolicy: "network-only",
    });
    const poiListFields = poiService.getDeepCopy(poiListSelector);
    poiListFields["accountsList"] = tempGetAccounts.data.listAccounts;
    dispatch(poiList(poiListFields));
    if (tempGetAccounts.data.listAccounts.length === 1) {
      onAccountSelection("", tempGetAccounts.data.listAccounts[0]);
    }
    setAccountsList(tempGetAccounts.data.listAccounts);
    setIsLoading("false");
  };

  const onAddPoiClick = () => {
    childFunc.current();
    const poiListFields = poiService.getDeepCopy(poiListSelector);
    poiListFields["viewType"] = "add";
    dispatch(poiList(poiListFields));
  };

  const onEditPoiClick = (e, id) => {
    if (
      user.permissions.poi_poiUsingHyperlink &&
      (user.permissions.poi_viewPoiDetails ||
        user.permissions.poi_editPoiDetails ||
        user.permissions.poi_viewPoiConfiguration ||
        user.permissions.poi_editPoiConfiguration ||
        user.permissions.poi_viewAssignedTeamsTab ||
        user.permissions.poi_editAssignedTeamsTab ||
        user.permissions.poi_editDeliveryAreaTab ||
        user.permissions.poi_viewDeliveryAreaTab ||
        user.permissions.poi_editDeliveryBoundaryTab ||
        user.permissions.poi_viewDeliveryBoundaryTab)
    ) {
      const poiListFields = poiService.getDeepCopy(poiListSelector);
      poiListFields["viewType"] = "edit";
      poiListFields["selectedPoi"] = id;
      dispatch(poiList(poiListFields));
      getPoiDetails(id);
    } else {
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        message: "You don't have the permission!",
      });
    }
    e.preventDefault();
  };

  const closeSnackBar = () => {
    setSnackBarState({
      open: false,
      vertical: "top",
      horizontal: "center",
      message: "",
    });
  };

  useEffect(() => {
    getUserData();
  }, []);

  useEffect(() => {
    if (reloadPoiListSelector.isReload) {
      getFilters();
      getPoisList();
      dispatch(reloadPoiList(false));
    }
  }, [reloadPoiListSelector]);

  useEffect(() => {
    checkIfIndeterminate();
  }, [checkIfIndeterminate]);

  return (
    <>
      <div className="p-4">
        <ConfirmationDialog
          confirmProps={confirmProps}
          onConfirmClick={onConfirmClick}
          onCancelClick={onCancelClick}
        />
        <AlertDialog alertProps={alertProps} onAlertClose={onAlertClose} />
        <Snackbar
          anchorOrigin={{
            vertical: snackBarState.vertical,
            horizontal: snackBarState.horizontal,
          }}
          autoHideDuration={6000}
          open={snackBarState.open}
          key={snackBarState.vertical + snackBarState.horizontal}
        >
          <Alert
            onClose={closeSnackBar}
            severity="error"
            sx={{ width: "100%" }}
          >
            {snackBarState.message}
          </Alert>
        </Snackbar>
        <div className="row pb-3">
          <div className="d-flex wrapper">
            <div className="col-sm-6 pb-3">
              <label className="pb-2"></label>
              <Tooltip
                title="Type at least three characters to get auto suggestion"
                placement="right"
                arrow
              >
                <Autocomplete
                  disablePortal
                  options={accountsList}
                  getOptionLabel={(option) => option.name || ""}
                  renderOption={(props, option) => {
                    return (
                      <li {...props} key={option.id}>
                        {option.name}
                      </li>
                    );
                  }}
                  autoComplete
                  value={poiListSelector.selectedAccount || ""}
                  onChange={onAccountSelection}
                  size="small"
                  sx={{ width: 200 }}
                  renderInput={(params) => (
                    <TextField {...params} label="Account" />
                  )}
                />
              </Tooltip>
            </div>
            <div className="col-sm-6 button-div">
              {user.permissions && user.permissions.poi_addPoi && (
                <Tooltip title="Add POI" arrow>
                  <span>
                    <Button
                      onClick={onAddPoiClick}
                      variant="contained"
                      size="small"
                    >
                      Add
                    </Button>
                  </span>
                </Tooltip>
              )}

              <>
                {selectedAccount.current.name ? (
                  <>
                    <Tooltip
                      title={
                        selectedPoiCount
                          ? "Edit POI(s)"
                          : "Select at least one POI to edit"
                      }
                      arrow
                    >
                      <span>
                        <Button
                          variant="contained"
                          size="small"
                          disabled={!selectedPoiCount}
                          onClick={() => editPoiButtonClick()}
                        >
                          Edit
                        </Button>
                      </span>
                    </Tooltip>

                    <Tooltip
                      title="Import POI(s) to bulk upload/create POIs"
                      open={showImportTooltip}
                      disableHoverListener
                      onMouseEnter={() => setShowImportTooltip(true)}
                      onMouseLeave={() => setShowImportTooltip(false)}
                      arrow
                    >
                      <span>
                        <ImportMenu
                          onImportClick={onImportClick}
                          onSelectImportOption={(ev) =>
                            selectedImportOption(ev)
                          }
                        ></ImportMenu>
                      </span>
                    </Tooltip>

                    <Tooltip
                      title={
                        selectedPoiCount
                          ? "Export POI(s) information into a CSV file"
                          : "Select at least one POI to export"
                      }
                      arrow
                    >
                      <span>
                        <Button
                          variant="contained"
                          size="small"
                          onClick={onExportClick}
                        >
                          Export
                        </Button>
                      </span>
                    </Tooltip>

                    {user.permissions &&
                      (user.permissions.poi_activatePois ||
                        user.permissions.poi_deactivatePois ||
                        user.permissions.poi_deletePois) && (
                        <Tooltip
                          title={
                            selectedPoiCount
                              ? "List view controls"
                              : "Select at least one POI to edit"
                          }
                          open={showSettingsTooltip}
                          disableHoverListener
                          onMouseEnter={() => setShowSettingsTooltip(true)}
                          onMouseLeave={() => setShowSettingsTooltip(false)}
                          arrow
                        >
                          <span>
                            <SettingsMenu
                              pageType={"dashboard"}
                              hideOptions={hideOptions.current}
                              isDisabledState={!selectedPoiCount}
                              onSettingsClick={onSettingsClick}
                              onSelectSettingsOption={(ev) =>
                                selectedSettingsOption(ev)
                              }
                            ></SettingsMenu>
                          </span>
                        </Tooltip>
                      )}
                  </>
                ) : (
                  ""
                )}
              </>
            </div>
          </div>

          <>
            {isLoading === "true" ? (
              <Spinner
                color="dark"
                style={{
                  position: "absolute",
                  margin: "auto",
                  top: "50%",
                  left: "50%",
                }}
              />
            ) : (
              <>
                {selectedAccount.current.name ? (
                  <div className="col-sm-12 wrapper">
                    {/* <Paper sx={{ width: '100%', overflow: 'hidden' }} className="p-3"> */}
                    <TableContainer
                      sx={{ maxHeight: "63vh", minHeight: "63vh" }}
                    >
                      <Table
                        stickyHeader
                        aria-label="sticky table"
                        className="poi-list-table"
                      >
                        <TableHead>
                          <TableRow>
                            <TableCell className="sticky-checkbox-header">
                              <Checkbox
                                className="mt-5"
                                checked={selectAllPOi}
                                onChange={onChangeSelectAll}
                                indeterminate={isIndeterminate}
                                inputProps={{ "aria-label": "controlled" }}
                                sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                              />
                            </TableCell>
                            {columns.map((column) => (
                              <React.Fragment key={column.id}>
                                {column.filterType === "search" ? (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                    className={
                                      column.id === "name"
                                        ? "sticky-header-name"
                                        : ""
                                    }
                                  >
                                    <label className="pb-3">
                                      {column.label}
                                    </label>
                                    <TextField
                                      id="search"
                                      label="Search"
                                      variant="outlined"
                                      size="small"
                                      onChange={(ev) =>
                                        search(ev.target.value, column.id)
                                      }
                                      sx={{ height: 39 }}
                                      value={selectedFilters.current[column.id]}
                                    />
                                  </TableCell>
                                ) : (
                                  <TableCell
                                    key={column.id}
                                    align={column.align}
                                    style={{ minWidth: column.minWidth }}
                                  >
                                    <label className="pb-2">
                                      {column.label}
                                    </label>
                                    <MultiSelectComponent
                                      allowFiltering={column.allowFiltering}
                                      dataSource={dropdownFilters[column.id]}
                                      fields={fields}
                                      placeholder="Select"
                                      mode="CheckBox"
                                      selectAllText="Select All"
                                      unSelectAllText="Unselect All"
                                      showSelectAll={column.showSelectAll}
                                      value={selectedFilters.current[column.id]}
                                      change={(ev) =>
                                        onFilterChange(ev, column.id)
                                      }
                                    >
                                      <Inject services={[CheckBoxSelection]} />
                                    </MultiSelectComponent>
                                  </TableCell>
                                )}
                              </React.Fragment>
                            ))}
                          </TableRow>
                        </TableHead>
                        {totalItemCount ? (
                          <TableBody>
                            {poisList.map((row, rowIndex) => {
                              return (
                                <TableRow
                                  hover
                                  role="checkbox"
                                  tabIndex={-1}
                                  key={rowIndex}
                                >
                                  <TableCell key={rowIndex} className="sticky">
                                    <Checkbox
                                      checked={row.checked}
                                      onChange={(e) => onPoiSelect(e, row)}
                                      inputProps={{
                                        "aria-label": "controlled",
                                      }}
                                      sx={{
                                        "& .MuiSvgIcon-root": { fontSize: 20 },
                                      }}
                                    />
                                  </TableCell>
                                  {columns.map((column) => {
                                    const value = row[column.id];
                                    return (
                                      <React.Fragment key={column.id}>
                                        <TableCell
                                          key={column.id}
                                          align={column.align}
                                          className={
                                            column.id === "name"
                                              ? "sticky-name"
                                              : ""
                                          }
                                        >
                                          <>
                                            {getUiElement(
                                              column,
                                              value,
                                              row["id"]
                                            )}
                                          </>
                                        </TableCell>
                                      </React.Fragment>
                                    );
                                  })}
                                </TableRow>
                              );
                            })}
                          </TableBody>
                        ) : (
                          <div
                            className="select-account"
                            style={{
                              display: "block",
                              left: "40vw",
                            }}
                          >
                            <p>No data in the selected filter</p>
                          </div>
                        )}
                      </Table>
                    </TableContainer>
                    <div className="pagination-container">
                      <div className="selected-count-wrapper">
                        Total {totalItemCount} | Selected {selectedPoiCount}
                      </div>
                      <div className="pagination-wrapper">
                        <TablePagination
                          rowsPerPageOptions={[]}
                          component="div"
                          count={totalItemCount}
                          rowsPerPage={rowsPerPage}
                          page={currentPage.current}
                          showFirstButton
                          showLastButton
                          onPageChange={handleChangePage}
                        />
                      </div>
                    </div>
                    {/* </Paper> */}
                  </div>
                ) : (
                  <div
                    className="select-account"
                    style={{
                      display:
                        (!selectedAccount.current.name || !totalItemCount) &&
                        !isFilterApplied
                          ? "block"
                          : "none",
                    }}
                  >
                    <p>
                      {selectedAccount.current.name && !totalItemCount
                        ? "No data in the selected Account"
                        : "Select an Account to load data"}
                    </p>
                  </div>
                )}
              </>
            )}
          </>
          <PoiTabs childFunc={childFunc} />
        </div>
      </div>
    </>
  );
};

export default PoiList;
