import { useQuery } from "@apollo/client";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from "@mui/material";
import { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { GET_INITIAL_VALUES } from "../graphql/queries";
import { configTab } from "../reducers/configTab";
import ConfigurationsService from "../services/ConfigurationsService";
import PoiService from "../services/PoiService";

const FieldNames = ({ data }) => {
  return (
    <>
      {data.is_dp_enabled?.checked && <div>Delivery Partner</div>}
      {!!data.addedPartners.length &&
        data.addedPartners.map((_, idx) => (
          <div key={idx}>Delivery Partner</div>
        ))}

      {!!data.addedPartners.length &&
        data.addedPartners.map((item, idx) => (
          <div key={idx}>{item.name} - Priority</div>
        ))}

      {!!data.addedPartners.length &&
        data.addedPartners.map((item, idx) => (
          <div key={idx}>{item.name} - Switching Time</div>
        ))}

      {!!data.deletedPartners.length &&
        data.deletedPartners.map((_, idx) => (
          <div key={idx}>Delivery Partner</div>
        ))}
      {!!data.modifiedPartners.length &&
        data.modifiedPartners.map((item, idx) => {
          return (
            <>
              {item.pty_checked && <div key={idx}>{item.name} - Priority</div>}
              {item.swt_checked && (
                <div key={idx}>{item.name} - Switching Time</div>
              )}
            </>
          );
        })}
      {data.is_delivery_partner_auto_assignment_enabled?.checked && (
        <div>Delivery partner Auto Assignment</div>
      )}
      {data.dod_request_time?.checked && (
        <div>Internal Driver Search Timeout</div>
      )}
      {data.target_dod_arrival_time?.checked && (
        <div>Target Arrival to Poi (DOD)</div>
      )}
      {data.dod_target_assign?.checked && <div>Target Assign (DOD)</div>}
    </>
  );
};
const FieldActions = ({ data }) => {
  return (
    <>
      {data.is_dp_enabled?.checked && <div>Toggle</div>}
      {!!data.addedPartners.length &&
        data.addedPartners.map((_, idx) => <div key={idx}>Add</div>)}
      {!!data.addedPartners.length &&
        data.addedPartners.map((_, idx) => <div key={idx}>Select</div>)}
      {!!data.addedPartners.length &&
        data.addedPartners.map((_, idx) => <div key={idx}>Input Text</div>)}
      {!!data.deletedPartners.length &&
        data.deletedPartners.map((_, idx) => <div key={idx}>Delete</div>)}
      {!!data.modifiedPartners.length &&
        data.modifiedPartners.map((item, idx) => {
          return (
            <>
              {item.pty_checked && <div key={idx}>Select</div>}
              {item.swt_checked && <div key={idx}>Input Text</div>}
            </>
          );
        })}

      {data.is_delivery_partner_auto_assignment_enabled?.checked && (
        <div>Toggle</div>
      )}
      {data.dod_request_time?.checked && <div>Input Text</div>}
      {data.target_dod_arrival_time?.checked && <div>Input Text</div>}
      {data.dod_target_assign?.checked && <div>Input Text</div>}
    </>
  );
};

const FieldInitialValues = ({ data }) => {
  return (
    <>
      {data.is_dp_enabled?.checked && (
        <div>
          {data.initialSettings.is_dp_enabled === "true"
            ? "Enabled"
            : "Disabled"}
        </div>
      )}
      {!!data.addedPartners.length &&
        data.addedPartners.map((_, idx) => <div key={idx}>-</div>)}
      {!!data.addedPartners.length &&
        data.addedPartners.map((_, idx) => <div key={idx}>-</div>)}
      {!!data.addedPartners.length &&
        data.addedPartners.map((_, idx) => <div key={idx}>-</div>)}
      {!!data.deletedPartners.length &&
        data.deletedPartners.map((item, idx) => (
          <div key={idx}>{item.name}</div>
        ))}
      {!!data.modifiedPartners.length &&
        data.modifiedPartners.map((item, idx) => {
          return (
            <>
              {item.pty_checked && (
                <div key={idx}>
                  {data.initialPartners.find((it) => it.id === Number(item.id))
                    ?.preference || "-"}
                </div>
              )}
              {item.swt_checked && (
                <div key={idx}>
                  {data.initialPartners.find((it) => it.id === Number(item.id))
                    ?.switching_time || "-"}
                </div>
              )}
            </>
          );
        })}

      {data.is_delivery_partner_auto_assignment_enabled?.checked && (
        <div>
          {data.initialSettings.is_delivery_partner_auto_assignment_enabled ===
          "true"
            ? "Enabled"
            : "Disabled"}
        </div>
      )}
      {data.dod_request_time?.checked && (
        <div>{data.initialSettings.dod_request_time || "-"}</div>
      )}
      {data.target_dod_arrival_time?.checked && (
        <div>{data.initialSettings.target_dod_arrival_time || "-"}</div>
      )}
      {data.dod_target_assign?.checked && (
        <div>{data.initialSettings.dod_target_assign || "-"}</div>
      )}
    </>
  );
};

const FieldUpdatedValues = ({ data }) => {
  const configTabSelector = useSelector((state) => state.configTab.value);
  return (
    <>
      {data.is_dp_enabled?.checked && (
        <div>
          {configTabSelector.is_dp_enabled === "true" ? "Enabled" : "Disabled"}
        </div>
      )}
      {!!data.addedPartners.length &&
        data.addedPartners.map((item, idx) => <div key={idx}>{item.name}</div>)}
      {!!data.addedPartners.length &&
        data.addedPartners.map((item, idx) => (
          <div key={idx}>{item.preference}</div>
        ))}
      {!!data.addedPartners.length &&
        data.addedPartners.map((item, idx) => (
          <div key={idx}>{item.switching_time || "-"}</div>
        ))}
      {!!data.deletedPartners.length &&
        data.deletedPartners.map((_, idx) => <div key={idx}>-</div>)}
      {!!data.modifiedPartners.length &&
        data.modifiedPartners.map((item, idx) => {
          return (
            <>
              {item.pty_checked && <div key={idx}>{item.preference}</div>}
              {item.swt_checked && (
                <div key={idx}>{item.switching_time || "-"}</div>
              )}
            </>
          );
        })}

      {data.is_delivery_partner_auto_assignment_enabled?.checked && (
        <div>
          {configTabSelector.is_delivery_partner_auto_assignment_enabled ===
          "true"
            ? "Enabled"
            : "Disabled"}
        </div>
      )}
      {data.dod_request_time?.checked && (
        <div>{configTabSelector.dod_request_time || "-"}</div>
      )}
      {data.target_dod_arrival_time?.checked && (
        <div>{configTabSelector.target_dod_arrival_time || "-"}</div>
      )}
      {data.dod_target_assign?.checked && (
        <div>{configTabSelector.dod_target_assign || "-"}</div>
      )}
    </>
  );
};
const ReviewTable = () => {
  const [data, setData] = useState();
  const poiListSelector = useSelector((state) => state.poiLists.value);
  const configTabSelector = useSelector((state) => state.configTab.value);
  const dispatch = useDispatch();
  const poiService = new PoiService();

  const changedDp = Object.entries(configTabSelector.multiEditDp).reduce(
    (acc, [key, value]) => {
      if (value.checked) {
        acc[key] = value;
      }
      return acc;
    },
    {}
  );

  const configService = new ConfigurationsService();

  const { loading, error } = useQuery(GET_INITIAL_VALUES, {
    variables: {
      poiIds: poiListSelector.multiEditIds.map((item) => item.id).join(","),
    },
    fetchPolicy: "network-only",
    onCompleted: ({ getPoisConfigurations }) => {
      const tempConfig = poiService.getDeepCopy(configTabSelector);
      const tempData = poiListSelector.multiEditIds.map((item) => {
        return {
          selectedPoi: item.name,
          selectedPoiId: item.id,
          ...changedDp,
        };
      });
      const initialValues = getPoisConfigurations;
      initialValues.map((val) => {
        const idx = tempData.findIndex(
          (d) => Number(d.selectedPoiId) === val.id
        );
        tempData[idx].initialPartners = val.partners;
        tempData[idx].initialSettings = val.settings;
        tempData[idx].updatedPartners = configTabSelector.delivery_partner;
      });
      const processedData = configService.processData(tempData);
      setData(processedData);
      tempConfig["dp_changes"] = processedData;
      dispatch(configTab(tempConfig));
    },
  });

  const handleDelete = (idx) => {
    const tempConfig = poiService.getDeepCopy(configTabSelector);
    const tempData = data.filter((_, i) => i !== idx);
    tempConfig["dp_changes"] = tempData;
    setData(tempData);
    dispatch(configTab(tempConfig));
  };

  if (loading) return null;
  return (
    <TableContainer>
      <Table sx={{ minWidth: 650 }} size="small" aria-label="a dense table">
        <TableHead>
          <TableRow>
            <TableCell>
              <img src={"/v3/assets/information.png"} />
            </TableCell>
            <TableCell align="left">POIs Selected</TableCell>
            <TableCell align="left">Field Name</TableCell>
            <TableCell align="left">Field Action</TableCell>
            <TableCell align="left">Initial Value</TableCell>
            <TableCell align="left">Updated Value</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data?.map((row, idx) => (
            <TableRow
              key={row.selectedPoiId}
              sx={{ "&:last-child td, &:last-child th": { border: 0 } }}
            >
              <TableCell component="th" scope="row" align="left">
                <img
                  src="/v3/assets/delete.png"
                  style={{ cursor: "pointer" }}
                  onClick={() => handleDelete(idx)}
                />
              </TableCell>
              <TableCell align="left">{row.selectedPoi}</TableCell>
              <TableCell align="left">
                <FieldNames data={row} />
              </TableCell>
              <TableCell align="left">
                <FieldActions data={row} />
              </TableCell>
              <TableCell align="left">
                <FieldInitialValues data={row} />
              </TableCell>
              <TableCell align="left">
                <FieldUpdatedValues data={row} />
              </TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ReviewTable;
