import { Tooltip } from "@mui/material";
import { useDispatch, useSelector } from "react-redux";
import { detailsFormErrorValidations } from "../reducers/detailsFormValidations";
import { formDetailsTab } from "../reducers/detailTab";
import { v4 as uuidv4 } from "uuid";
import { teamAssign } from "../reducers/assignedTeam";
import { deliveryAreas } from "../reducers/deliveryArea";
import { delBoundary } from "../reducers/deliveryBoundary";
import { configTab } from "../reducers/configTab";
import jsPDF from "jspdf";
import QRCode from "qrcode-generator";

class PoiService {
  dispatch = useDispatch();
  detailsTabSelector = useSelector((state) => state.detailTab.value);
  errorValidationSelector = useSelector(
    (state) => state.detailsFormValidations.value
  );
  configTabSelector = useSelector((state) => state.configTab.value);
  poiListSelector = useSelector((state) => state.poiLists.value);

  columnsForPoiList = [
    {
      id: "name",
      label: "POI",
      minWidth: 170,
      filterType: "search",
      showSelectAll: true,
      allowFiltering: false,
    },
    {
      id: "is_active_new",
      label: "POI Status",
      minWidth: 100,
      filterType: "select",
      showSelectAll: false,
      allowFiltering: false,
    },
    {
      id: "brands",
      label: "Brand",
      minWidth: 100,
      filterType: "selectWithSearch",
      showSelectAll: true,
      allowFiltering: true,
    },
    {
      id: "teams",
      label: "Team",
      minWidth: 100,
      filterType: "selectWithSearch",
      showSelectAll: true,
      allowFiltering: true,
    },
    {
      id: "city",
      label: "City",
      minWidth: 100,
      filterType: "selectWithSearch",
      showSelectAll: true,
      allowFiltering: true,
    },
    {
      id: "country",
      label: "Country",
      minWidth: 100,
      filterType: "selectWithSearch",
      showSelectAll: true,
      allowFiltering: true,
    },
    {
      id: "is_new_autoassign",
      label: "Assignment 2.0",
      minWidth: 100,
      filterType: "select",
      showSelectAll: false,
      allowFiltering: false,
    },
    {
      id: "postal_code",
      label: "POI ID",
      minWidth: 150,
      filterType: "search",
      showSelectAll: true,
      allowFiltering: false,
    },
    {
      id: "number_gln",
      label: "Reference ID for Order Creation",
      minWidth: 200,
      filterType: "search",
      showSelectAll: true,
      allowFiltering: false,
    },
    {
      id: "reference_id_waynaq",
      label: "Reference ID for Wayanaq",
      minWidth: 200,
      filterType: "search",
      showSelectAll: true,
      allowFiltering: false,
    },
  ];

  constructor() {
    this.baseUrl = process.env.REACT_APP_BASE_API_URL;
    this.userApiUrl = process.env.REACT_APP_USER_API_URL;
    this.latLong = { lat: 25.2048, lng: 55.2708 };
  }

  getLatLng() {
    return this.latLong;
  }

  validateMail(inMailAddr) {
    const validEmailRegEx =
      /^[A-Z0-9_'%=+!`#~$*?^{}&|-]+([\.][A-Z0-9_'%=+!`#~$*?^{}&|-]+)*@[A-Z0-9-]+(\.[A-Z0-9-]+)+$/i;
    return validEmailRegEx.test(inMailAddr);
  }

  validatePhoneNumber(phoneNumberValue) {
    const phoneNumber = (phoneNumberValue || "").trim();
    const phoneNumberPattern = /^\+?\d+$/;
    return phoneNumberPattern.test(phoneNumber);
  }

  getDeepCopy(inValue) {
    return JSON.parse(JSON.stringify(inValue));
  }

  getClients(id) {
    return fetch(this.baseUrl + "admin/GetClients").then((res) => res.json());
  }

  fetchUser(id) {
    return fetch(this.userApiUrl + id).then((res) => res.json());
  }

  prepareEdgePoints(inVal) {
    let edgePoints = "";
    inVal.forEach((el, idx) => {
      if (idx === inVal.length - 1) {
        edgePoints += "(" + el.lat + ", " + el.lng + ")";
      } else {
        edgePoints += "(" + el.lat + ", " + el.lng + "),";
      }
    });
    return edgePoints;
  }

  selectedSettingsOptionsForPoiList = (inOption) => {
    let tempData = { title: "", description: "", display: false };
    switch (inOption) {
      case "activate":
        tempData = {
          title: "Activate",
          id: "activate",
          description:
            "Selected POI(s) will be activated. Do you want to proceed?",
          display: true,
        };
        break;
      case "deactivate":
        tempData = {
          title: "Deactivate",
          id: "deactivate",
          description:
            "Selected POI(s) will be deactivated. Do you want to proceed?",
          display: true,
        };
        break;
      case "delete":
        tempData = {
          title: "Delete",
          id: "delete",
          description:
            "Selected POI(s) will be deleted. Do you want to proceed?",
          display: true,
        };
        break;
      default:
        tempData = {
          title: "Activate",
          id: "activate",
          description:
            "Selected POI(s) will be activated. Do you want to proceed?",
          display: true,
        };
    }

    return tempData;
  };

  preparePoiList = (copyPoisList) => {
    const tempPoisList = this.getDeepCopy(copyPoisList);
    tempPoisList.forEach((val) => {
      val["checked"] = false;
      if (
        val.settings &&
        val.settings.is_new_autoassign &&
        val.settings.is_new_autoassign == "true"
      ) {
        val["is_new_autoassign"] = "Enabled";
      } else {
        val["is_new_autoassign"] = "Disabled";
      }
      if (val.is_active && val.is_active == "1") {
        val["is_active_new"] = "Active";
      } else {
        val["is_active_new"] = "Inactive";
      }
      if (!val.reference_id_waynaq) {
        val["reference_id_waynaq"] = "";
      }
      if (!val.number_gln) {
        val["number_gln"] = "";
      }
      if (!val.name) {
        val["name"] = "";
      }
      if (val.city) {
        val["city"] = val.city.name;
      } else {
        val["city"] = "";
      }
      if (val.country) {
        val["country"] = val.country.name;
      } else {
        val["country"] = "";
      }
    });

    return tempPoisList;
  };

  prepareFilters = (inFIlters) => {
    return {
      is_active_new: [
        { name: "Active", id: "active" },
        { name: "Inactive", id: "inactive" },
      ],
      brands: inFIlters.listBrands,
      teams: inFIlters.listTeamsFilter,
      city: inFIlters.listCities,
      country: inFIlters.listCountries,
      is_new_autoassign: [
        { name: "Enabled", id: "enabled" },
        { name: "Disabled", id: "disabled" },
      ],
    };
  };

  onFilterChanges = (obj, filters) => {
    let valid,
      filterData = JSON.parse(JSON.stringify(obj));

    function filterAnd(key, values, data) {
      return data.filter(function (d) {
        valid = false;
        // for filtering multi select
        if (Array.isArray(values)) {
          for (let v = 0; v < values.length; v++) {
            if (Array.isArray(d[key])) {
              d[key].forEach((el) => {
                if (el.name.match(values[v])) {
                  valid = true;
                }
              });
            } else {
              if (d[key].toLowerCase() == values[v].toLowerCase()) {
                valid = true;
              }
            }
          }
        } else {
          // for filtering input search
          if (d[key].toLowerCase().match(values.toLowerCase())) {
            valid = true;
          }
        }
        return valid;
      });
    }

    Object.keys(filters).forEach((f) => {
      filterData = filterAnd(f, filters[f], filterData);
    });

    return filterData;
  };

  getUiElement = (column, value, id) => {
    switch (column.id) {
      case "is_active_new":
        return <span className="tab-font-wrap">{value}</span>;
      case "brands":
        return this.getViewMoreUI(value);
      case "teams":
        return this.getViewMoreUI(value);
      case "country":
        return (
          <span className="tab-font-wrap" title={value}>
            {value}
          </span>
        );
      case "city":
        return (
          <span className="tab-font-wrap" title={value}>
            {value}
          </span>
        );
      case "is_new_autoassign":
        return (
          <span className="tab-font-wrap" title={value}>
            {value}
          </span>
        );
      case "number_gln":
        return (
          <span className="tab-font-wrap" title={value}>
            {value}
          </span>
        );
      case "reference_id_waynaq":
        return (
          <span className="tab-font-wrap" title={value}>
            {value}
          </span>
        );
      case "id":
        return (
          <span className="tab-font-wrap" title={value}>
            {value}
          </span>
        );
      default:
        return "";
    }
  };

  getViewMoreUI = (value) => {
    let jsxElement;
    if (value && value.length) {
      if (value.length > 1) {
        jsxElement = (
          <span className="tab-font-wrap">
            {value[0].name}
            <span style={{ color: "blue", cursor: "pointer" }}>
              <Tooltip
                title={value.slice(1).map((tip) => {
                  return <p>{tip.name}</p>;
                })}
                arrow
              >
                <span>+{value.length - 1}</span>
              </Tooltip>
            </span>
          </span>
        );
      } else if (value.length === 1) {
        jsxElement = <span>{value[0].name}</span>;
      }
    }

    return jsxElement;
  };

  checkRequiredValidation = () => {
    const reqFields = [
      "name",
      "order_type",
      "client_id",
      "brand_ids",
      "country_id",
      "city_id",
      "currency",
      "timezone",
      "open_t",
      "close_t",
    ];

    // check location only in add poi
    if (this.poiListSelector.viewType === "add") {
      reqFields.push("location");
    }

    const fieldData = this.getDeepCopy(this.detailsTabSelector);
    const tempValidation = this.getDeepCopy(this.errorValidationSelector);
    let validationState = { isValid: true, message: "" };
    reqFields.forEach((req) => {
      if (req === "brand_ids") {
        if (!fieldData[req] || !fieldData[req].length) {
          validationState.isValid = false;
          validationState.message =
            "Please fill in the mandatory fields from Details Tab";
          tempValidation[req] = "Field is required";
        }
      } else {
        if (!fieldData[req]) {
          validationState.isValid = false;
          validationState.message =
            "Please fill in the mandatory fields from Details Tab";
          tempValidation[req] = "Field is required";
        } else {
          tempValidation[req] = "";
        }
      }
    });
    if (fieldData["contact_email"]) {
      const validate = this.validateMail(fieldData["contact_email"]);
      if (!validate) {
        validationState.isValid = false;
        validationState.message =
          "Please fill in the mandatory fields from Details Tab";
        tempValidation["contact_email"] = "Invalid Email";
      } else {
        tempValidation["contact_email"] = "";
      }
    }

    if (fieldData["store_manager_phone"]) {
      const validate = this.validatePhoneNumber(
        fieldData["store_manager_phone"]
      );
      if (!validate) {
        validationState.isValid = false;
        validationState.message =
          "Please fill in the mandatory fields from Details Tab";
        tempValidation["store_manager_phone"] = "Invalid phone number";
      } else {
        tempValidation["store_manager_phone"] = "";
      }
    }

    if (fieldData["area_manager_phone"]) {
      const validate = this.validatePhoneNumber(
        fieldData["area_manager_phone"]
      );
      if (!validate) {
        validationState.isValid = false;
        validationState.message =
          "Please fill in the mandatory fields from Details Tab";
        tempValidation["area_manager_phone"] = "Invalid phone number";
      } else {
        tempValidation["area_manager_phone"] = "";
      }
    }

    if (fieldData["contact_phone"]) {
      const validate = this.validatePhoneNumber(fieldData["contact_phone"]);
      if (!validate) {
        validationState.isValid = false;
        validationState.message =
          "Please fill in the mandatory fields from Details Tab";
        tempValidation["contact_phone"] = "Invalid phone number";
      } else {
        tempValidation["contact_phone"] = "";
      }
    }

    if (
      this.configTabSelector.order_partner.partner_type &&
      !this.configTabSelector.order_partner.partner_reference_id
    ) {
      validationState.isValid = false;
      validationState.message =
        "Please fill in the Reference ID of order partner";
    }
    if (this.configTabSelector.order_partner.ref_id_err) {
      validationState.isValid = false;
      validationState.message = "Please choose some other POI Reference ID";
    }
    this.dispatch(detailsFormErrorValidations(tempValidation));
    return validationState;
  };

  checkRequiredValidationMultiEdit = () => {
    let isValid = true;
    if (this.poiListSelector.viewType === "multiEdit"){
      if (this.configTabSelector.is_dp_enabled === true || this.configTabSelector.is_dp_enabled === "true"){
        if (this.configTabSelector.multiEditDp.edit_delivery_partner_sections.checked === 'template'){
          if (this.configTabSelector.delivery_partner){
            isValid =  this.configTabSelector.delivery_partner.every(
                (dp) => dp.preference !== ""
            );
          }
        } else { // customization
          if (this.configTabSelector.delivery_partners_customization){
            if (this.configTabSelector.multiEditDp.add_delivery_partner.checked === true){
              if (this.configTabSelector.delivery_partners_customization.new_dps){
                isValid =  this.configTabSelector.delivery_partners_customization.new_dps.every(
                    (dp) => dp.preference !== ""
                );
              }
            }

            if (this.configTabSelector.multiEditDp.update_delivery_partner_priority.checked === true){
              if (this.configTabSelector.delivery_partners_customization.change_priority_dps){
                isValid =  this.configTabSelector.delivery_partners_customization.change_priority_dps.every(
                    (dp) => dp.preference !== ""
                );
              }
            }
          }
        }
      }

      if (this.configTabSelector.is_delivery_partner_auto_assignment_enabled === true || this.configTabSelector.is_delivery_partner_auto_assignment_enabled === "true"){
        if (this.configTabSelector.multiEditDp.edit_delivery_partner_sections.checked === 'template'){
          if (this.configTabSelector.delivery_partner){
            isValid =  this.configTabSelector.delivery_partner.every(
                (dp) => dp.switching_time !== ""
            );
          }
        } else { // customization
          if (this.configTabSelector.delivery_partners_customization){
            if (this.configTabSelector.multiEditDp.update_delivery_partner_switching_time.checked === true){
              if (this.configTabSelector.delivery_partners_customization.update_dps){
                isValid =  this.configTabSelector.delivery_partners_customization.update_dps.every(
                    (dp) => dp.switching_time !== ""
                );
              }
            }
            if (this.configTabSelector.multiEditDp.add_delivery_partner.checked === true){
              if (this.configTabSelector.delivery_partners_customization.new_dps){
                isValid =  this.configTabSelector.delivery_partners_customization.new_dps.every(
                    (dp) => dp.switching_time !== ""
                );
              }
            }
          }
        }

        if (!this.configTabSelector.dod_request_time || this.configTabSelector.dod_request_time === ""){
          isValid = false;
        }
      }
    }

    return isValid;
  };

  initializeGetPoi = (inGetPoiData) => {
    const tempData = this.getDeepCopy(inGetPoiData.data.getPoi);
    tempData["client_id"] = this.poiListSelector.selectedAccount;
    tempData["country_id"] = inGetPoiData.data.getPoi.country;
    tempData["city_id"] = inGetPoiData.data.getPoi.city;
    tempData["open_t"] = inGetPoiData.data.getPoi.settings.open_t;
    tempData["close_t"] = inGetPoiData.data.getPoi.settings.close_t;
    tempData["currency"] = inGetPoiData.data.getPoi.settings.currency;
    tempData["golivedate"] = inGetPoiData.data.getPoi.settings.go_live_date;
    tempData["timezone"] = inGetPoiData.data.getPoi.settings.timezone;
    tempData["brand_ids"] = inGetPoiData.data.getPoi.brands;
    this.dispatch(formDetailsTab(tempData));

    const tempConfigData = this.getDeepCopy(inGetPoiData.data.getPoi.settings);
    tempConfigData["reference_id_waynaq"] =
      inGetPoiData.data.getPoi.reference_id_waynaq;
    tempConfigData["number_gln"] = inGetPoiData.data.getPoi.number_gln;
    tempConfigData["radius"] = inGetPoiData.data.getPoi.radius;
    tempConfigData["startSOS"] = tempConfigData.expected_sos_start
      ? JSON.parse(tempConfigData.expected_sos_start)
      : [];
    tempConfigData["endSOS"] = tempConfigData.expected_sos_end
      ? JSON.parse(tempConfigData.expected_sos_end)
      : [];
    if (inGetPoiData.data.getPoi.aggrigators) {
      tempConfigData["aggregator"] = inGetPoiData.data.getPoi.aggrigators
        .map((list) => {
          const matchingAccount = this.poiListSelector.accountsList.find(
            (acc) => acc.id == list.enabled_account
          );

          if (matchingAccount) {
            return {
              id: uuidv4(),
              aggregator: list.enabled_account,
              token: list.access_token,
              aggregatorValue: {
                id: list.enabled_account,
                name: matchingAccount ? matchingAccount.name : null,
              },
            };
          } else {
            return null;
          }
        })
        .filter((item) => item !== null);
    }
    tempConfigData["order_partner"] = {
      partner_type: "",
      partner_reference_id: "",
      ref_id_err: false,
    };

    let tempOrderFields = {
      DELIVERY_AREA: false,
      DESTINATION_LAT_LONG: false,
    };
    if (inGetPoiData.data.getPoi.settings.order_fields) {
      const tempArr = inGetPoiData.data.getPoi.settings.order_fields.split(",");
      tempArr.forEach((el) => {
        if (el === "DELIVERY_AREA") {
          tempOrderFields.DELIVERY_AREA = true;
        }
        if (el === "DESTINATION_LAT_LONG") {
          tempOrderFields.DESTINATION_LAT_LONG = true;
        }
      });
    }
    tempConfigData["order_fields"] = tempOrderFields;
    let tempDistanceApp = {
      ACTUAL: false,
      PLANNED: false,
    };
    if (inGetPoiData.data.getPoi.settings.distance_app) {
      const tempArr = inGetPoiData.data.getPoi.settings.distance_app.split(",");
      tempArr.forEach((el) => {
        if (el === "Actual") {
          tempDistanceApp.ACTUAL = true;
        }
        if (el === "Planned") {
          tempDistanceApp.PLANNED = true;
        }
      });
    }
    tempConfigData["distance_app"] = tempDistanceApp;
    if (tempConfigData.is_new_autoassign == "true") {
      tempConfigData["is_new_autoassign"] = "v2.0";
    }
    if (tempConfigData.is_autoassign_v1 == "true") {
      tempConfigData["is_new_autoassign"] = "v1.0";
    }

    this.dispatch(configTab(tempConfigData));

    if (
      inGetPoiData.data.getPoi.teams &&
      inGetPoiData.data.getPoi.teams.length
    ) {
      this.dispatch(teamAssign(inGetPoiData.data.getPoi.teams));
    }
    if (
      inGetPoiData.data.getPoi.delivery_areas &&
      inGetPoiData.data.getPoi.delivery_areas.length
    ) {
      this.dispatch(deliveryAreas(inGetPoiData.data.getPoi.delivery_areas));
    }
    if (inGetPoiData.data.getPoi.edge_points) {
      this.dispatch(delBoundary(inGetPoiData.data.getPoi.edge_points));
    }
  };

  getTeamTypeText = (type) => {
    let teamType;
    switch (type) {
      case "NONE":
        teamType = "";
        break;
      case "DOD":
        teamType = "DOD/Force on-demand";
        break;
      case "DEDICATED":
        teamType = "Dedicated";
        break;
      case "PREASSIGN":
        teamType = "Pre-assign";
        break;
      case "POOLING":
        teamType = "On-demand/Pooling";
        break;
      case "ONDEMAND":
        teamType = "On-demand/Pooling";
        break;
      case "SACN":
        teamType = "Scan to assign";
        break;
      default:
        teamType = "";
    }
    return teamType;
  };

  generateQrCodeFile = (qrCode, poiName) => {
    const typeNumber = 4; // adjust as needed
    const errorCorrectionLevel = "L";
    const qr = QRCode(typeNumber, errorCorrectionLevel);
    qr.addData(qrCode);
    qr.make();
    const qrCodeData = qr.createDataURL();

    // Create a PDF
    const pdf = new jsPDF();

    pdf.setLineWidth(0.3);
    pdf.line(10, 10, 200, 10);
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(12);
    pdf.text("Store:", 10, 20, {});
    pdf.setFont("helvetica", "normal");
    pdf.text(poiName, 24, 20, {}); // continue with normal text
    pdf.line(10, 27, 200, 27);

    // Calculate center position for QR code
    const pageWidth = pdf.internal.pageSize.getWidth();
    const qrSize = 100; // Adjust the size of the QR code as needed
    const xPos = (pageWidth - qrSize) / 2;
    const yPos = 35;
    // Add the QR code to the center of the page
    pdf.addImage(qrCodeData, "JPEG", xPos, yPos, qrSize, qrSize);

    pdf.save("QR_Code.pdf");
  };
}

export default PoiService;
