import { useApolloClient } from "@apollo/client";
import { useDispatch, useSelector } from "react-redux";
import {
  GET_CLIENT_CONFIGS,
  GET_CONFIG_TAB_DROPDOWNS,
  GET_DP_ACCOUNT,
} from "../graphql/queries";
import {
  clientConfigs,
  configTab,
  configDropdownOptions,
} from "../reducers/configTab";
import PoiService from "./PoiService";

class ConfigurationsService {
  poiService = new PoiService();
  client = useApolloClient();
  poiListSelector = useSelector((state) => state.poiLists.value);
  detailsTabSelector = useSelector((state) => state.detailTab.value);
  configDropdownSelector = useSelector(
    (state) => state.configTab.dropdownOptions
  );
  configTabSelector = useSelector((state) => state.configTab.value);
  clientConfigTabSelector = useSelector(
    (state) => state.configTab.clientConfigs
  );
  user = useSelector((state) => state.userInfo.value);
  dispatch = useDispatch();

  getDeliveryPartners = async () => {
    const tmpDP = await this.client.query({
      query: GET_DP_ACCOUNT,
      variables: {
        client_id: Number(this.detailsTabSelector.client_id.id),
      },
      fetchPolicy: "network-only",
    });

    const { getClientDeliveryPartners } = tmpDP.data;

    const data = getClientDeliveryPartners.map((item) => {
      return {
        preference: JSON.stringify(item.preference),
        id: JSON.stringify(item.id),
        switching_time: item.switching_time,
        pty_checked: false,
        swt_checked: false,
        name: item.name,
      };
    });
    return data;
  };

  getOrderPartnerDeliveryPartnerList = async () => {
    const tempOrderPartnerDelPartnerList = await this.client.query({
      query: GET_CONFIG_TAB_DROPDOWNS,
      variables: {
        clientId: this.detailsTabSelector.client_id.id,
        poiId: this.poiListSelector.selectedPoi,
      },
      fetchPolicy: "network-only",
    });
    const tempFields = this.poiService.getDeepCopy(this.configDropdownSelector);
    tempFields["orderPartnersList"] = tempOrderPartnerDelPartnerList.data
      .listOrderPartner
      ? tempOrderPartnerDelPartnerList.data.listOrderPartner
      : [];
    tempFields["deliveryPartnersList"] = tempOrderPartnerDelPartnerList.data
      .listDeliveryPartners
      ? tempOrderPartnerDelPartnerList.data.listDeliveryPartners
      : [];
    this.dispatch(configDropdownOptions(tempFields));
  };

  getClientConfigs = async () => {
    const tempGetClientConfigs = await this.client.query({
      query: GET_CLIENT_CONFIGS,
      variables: {
        clientId: this.detailsTabSelector.client_id.id,
        userId: this.user.id,
      },
      fetchPolicy: "network-only",
    });
    const configs = tempGetClientConfigs.data.getClient.config;
    this.dispatch(clientConfigs(configs));
  };

  isDeliveryPartnerEdit = (userPermission) => {
    const isEditAllowed = userPermission.poi_editPoiConfiguration;
    const isDpConfigAllowed =
      userPermission.poi_editDeliveryPartnerConfiguration;

    if (isEditAllowed === "true") return true;
    if (isDpConfigAllowed === "true") return true;

    return false;
  };

  isDeliveryPartnerView = (userPermission) => {
    const isViewAllowed = userPermission.poi_viewPoiConfiguration;
    const isDpConfigAllowed =
      userPermission.poi_viewDeliveryPartnerConfiguration;

    if (isViewAllowed === "true") return false;
    if (isDpConfigAllowed === "true") return true;

    return false;
  };
  processData = (data = []) => {
    const result = data.slice();
    result.map((entry) => {
      const initialPartnerIds = new Set(
        entry.initialPartners.map((partner) => partner.id)
      );
      const updatedPartnersIds = new Set(
        entry.updatedPartners.map((partner) => Number(partner.id))
      );
      const tmpPartners = entry.updatedPartners.filter(
        (partner) => !initialPartnerIds.has(parseInt(partner.id))
      );
      const addedPartners = tmpPartners.filter((item) => item.pty_checked);
      entry.addedPartners = addedPartners;

      const deletedPartners = entry.initialPartners.filter(
        (pt) => !updatedPartnersIds.has(parseInt(pt.id))
      );
      entry.deletedPartners = deletedPartners;

      const modifiedPartners = entry.updatedPartners.filter((partner) => {
        if (
          initialPartnerIds.has(parseInt(partner.id)) &&
          (partner.pty_checked || partner.swt_checked)
        ) {
          return partner;
        }
      });
      entry.modifiedPartners = modifiedPartners;
    });
    return result;
  };
}
export default ConfigurationsService;
