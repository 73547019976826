import { createSlice } from "@reduxjs/toolkit";

export const deliveryBoundarySlice = createSlice({
  name: "delBoundary",
  initialState: { value: [] },
  reducers: {
    delBoundary: (state, action) => {
      state.value = action.payload;
    },
  },
});

export const { delBoundary } = deliveryBoundarySlice.actions;

export default deliveryBoundarySlice.reducer;
