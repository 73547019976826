import * as React from "react";
import { styled, alpha } from "@mui/material/styles";
import Button from "@mui/material/Button";
import Menu from "@mui/material/Menu";
import MenuItem from "@mui/material/MenuItem";
import FileCopyIcon from "@mui/icons-material/FileCopy";
import DownloadIcon from "@mui/icons-material/Download";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import ImportDialog from "./ImportDialog";
import { useSelector } from "react-redux";

const downloadImportStyle = {
  color: "#464f5e",
  "text-decoration": "none",
};

const StyledMenu = styled((props) => (
  <Menu
    elevation={0}
    anchorOrigin={{
      vertical: "bottom",
      horizontal: "right",
    }}
    transformOrigin={{
      vertical: "top",
      horizontal: "right",
    }}
    {...props}
  />
))(({ theme }) => ({
  "& .MuiPaper-root": {
    borderRadius: 6,
    marginTop: theme.spacing(1),
    minWidth: 180,
    color:
      theme.palette.mode === "light"
        ? "rgb(55, 65, 81)"
        : theme.palette.grey[300],
    boxShadow:
      "rgb(255, 255, 255) 0px 0px 0px 0px, rgba(0, 0, 0, 0.05) 0px 0px 0px 1px, rgba(0, 0, 0, 0.1) 0px 10px 15px -3px, rgba(0, 0, 0, 0.05) 0px 4px 6px -2px",
    "& .MuiMenu-list": {
      padding: "4px 0",
    },
    "& .MuiMenuItem-root": {
      "& .MuiSvgIcon-root": {
        fontSize: 18,
        color: theme.palette.text.secondary,
        marginRight: theme.spacing(1.5),
      },
      "&:active": {
        backgroundColor: alpha(
          theme.palette.primary.main,
          theme.palette.action.selectedOpacity
        ),
      },
    },
  },
}));

export default function ImportMenu(props) {
  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);
  const user = useSelector((state) => state.userInfo.value);
  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
    props.onImportClick();
  };
  const handleClose = (ev) => {
    setAnchorEl(null);
    props.onSelectImportOption(ev);
  };

  const childFunc = React.useRef(null);

  return (
    <div>
      <Button
        id="demo-customized-button"
        aria-controls="demo-customized-menu"
        aria-haspopup="true"
        aria-expanded={open ? "true" : undefined}
        variant="contained"
        disableElevation
        size="small"
        onClick={handleClick}
        endIcon={<KeyboardArrowDownIcon />}
      >
        Import
      </Button>
      <StyledMenu
        id="demo-customized-menu"
        MenuListProps={{
          "aria-labelledby": "demo-customized-button",
        }}
        anchorEl={anchorEl}
        open={open}
        onClose={handleClose}
      >
        <MenuItem onClick={() => handleClose("download")} disableRipple>
          <DownloadIcon />

          <a
            href="/v3/assets/POI_import_template.xlsx"
            download
            style={downloadImportStyle}
          >
            Download Import Template
          </a>
        </MenuItem>
        {user.permissions && user.permissions.poi_importPoi && (
          <MenuItem
            onClick={() => (childFunc.current(), handleClose("import"))}
            disableRipple
          >
            <FileCopyIcon />
            Import POIs
          </MenuItem>
        )}
      </StyledMenu>
      <ImportDialog childFunc={childFunc} />
    </div>
  );
}
