import { useApolloClient } from "@apollo/client";
import {
  Alert,
  Button,
  Checkbox,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControl,
  MenuItem,
  Select,
  Snackbar,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TextField,
} from "@mui/material";
import CreateIcon from "@mui/icons-material/Create";
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Spinner } from "reactstrap";
import { GET_TEAMS_LIST } from "../../graphql/queries";
import { teamAssign } from "../../reducers/assignedTeam";
import PoiService from "../../services/PoiService";

import "./AssignedTeams.css";

const AssignedTeams = () => {
  const poiService = new PoiService();
  const dispatch = useDispatch();
  const assignedTeamsSelector = useSelector((state) => state.assignedTeams);
  const user = useSelector((state) => state.userInfo.value);
  const detailsTabSelector = useSelector((state) => state.detailTab.value);

  const [teamsList, setTeamsList] = useState([]);
  const [isLoading, setIsLoading] = useState("false");
  const [rowsPerPage, setRowsPerPage] = useState(20);
  const [totalItemCount, setTotalItemCount] = useState(0);
  const [selectedTeamCount, setSelectedTeamCount] = useState(0);
  const [openDialog, setOpenDialog] = React.useState(false);
  const [assignedTeam, setAssignedTeam] = React.useState("");
  const [snackBarState, setSnackBarState] = React.useState({
    open: false,
    vertical: "top",
    horizontal: "center",
    type: "success",
    message: "",
  });

  const copyTeamsList = useRef([]);
  const selectedFilters = useRef({});
  const currentPage = useRef(0);
  const client = useApolloClient();

  const tableColumns = [
    { id: "name", label: "Team", minWidth: 100 },
    { id: "team_type", label: "Team Type", minWidth: 100 },
    { id: "city", label: "City", minWidth: 100 },
  ];

  const handleChangePage = (event, newPage) => {
    currentPage.current = newPage;
    pageChanged();
  };

  async function getTeamsList() {
    if (detailsTabSelector.country_id) {
      setIsLoading("true");
      setTeamsList([]);
      copyTeamsList.current = [];
      const tempGeTeamsList = await client.query({
        query: GET_TEAMS_LIST,
        variables: {
          countryId: detailsTabSelector.country_id.id,
          userId: user.id,
        },
        fetchPolicy: "network-only",
      });
      const tempList = JSON.parse(JSON.stringify(tempGeTeamsList.data));
      if (assignedTeamsSelector.value.length) {
        tempList.listUserTeams.forEach((el) => {
          const checkExist = checkTeamExist(el);
          if (checkExist) {
            el["team_type"] = el.team_type
              ? el.team_type
              : checkExist.team_type;
            if (typeof el.city === "object" && el.city !== null) {
              el["city"] = el.city.name;
            } else {
              if (checkExist.city) {
                if (
                  typeof checkExist.city === "object" &&
                  checkExist.city !== null
                ) {
                  el["city"] = checkExist.city.name;
                } else {
                  el["city"] = checkExist.city;
                }
              } else {
                el["city"] = "";
              }
            }
          } else {
            el.team_type = el.team_type ? el.team_type : "";
            el["city"] =
              typeof el.city === "object" && el.city !== null
                ? el.city.name
                : "";
          }
        });
      } else {
        tempList.listUserTeams.forEach((el) => {
          el.team_type = el.team_type ? el.team_type : "";
          el["city"] =
            typeof el.city === "object" && el.city !== null ? el.city.name : "";
        });
      }
      copyTeamsList.current = tempList.listUserTeams;

      copyTeamsList.current.sort((a, b) => {
        const isAChecked = a.team_type && a.team_type !== "NONE";
        const isBChecked = b.team_type && b.team_type !== "NONE";

        // Check if both are checked or unchecked
        if (isAChecked === isBChecked) {
          // Sort alphabetically by name if both are checked or unchecked
          const string1 = /[a-zA-Z]+/.exec(a.name);
          const string2 = /[a-zA-Z]+/.exec(b.name);
          if (string1 && string2) {
            return string1[0].localeCompare(string2[0]);
          } else {
            return a - b;
          }
        } else {
          // Prioritize checked items
          return isAChecked ? -1 : 1;
        }
      });

      setTotalItemCount(tempList.listUserTeams.length);
      pageChanged();
      setIsLoading("false");
    } else {
      setSnackBarState({
        open: true,
        vertical: "top",
        horizontal: "center",
        type: "error",
        message: "Please select country to continue",
      });
    }
  }

  const checkTeamExist = (inVal) => {
    let tempVal = false;
    assignedTeamsSelector.value.forEach((team) => {
      if (team.id == inVal.id && team.team_type) {
        tempVal = team;
      }
    });
    return tempVal;
  };

  const search = (searchString, field) => {
    selectedFilters.current[field] = searchString;
    currentPage.current = 0;
    pageChanged();
  };

  const onTeamSelect = (ev, inRow) => {
    if (ev) {
      setOpenDialog(true);
      setAssignedTeam(inRow);
    } else {
      const tempRow = JSON.parse(JSON.stringify(inRow));
      tempRow.team_type = "NONE";
      setAssignedTeam(tempRow);
      const newTempArr = JSON.parse(JSON.stringify(copyTeamsList.current));
      newTempArr.forEach((row) => {
        if (row.id === inRow.id) {
          row.team_type = "NONE";
          row["checked"] = false;
        }
      });
      setTeamsList([...newTempArr]);
      copyTeamsList.current = newTempArr;
      checkIfIndeterminate();

      dispatch(teamAssign(copyTeamsList.current));
    }
  };

  const handleDialogClose = () => {
    setOpenDialog(false);
  };

  const applyTeamType = () => {
    const newTempArr = JSON.parse(JSON.stringify(copyTeamsList.current));
    newTempArr.forEach((row) => {
      if (row.id === assignedTeam.id) {
        row.team_type = assignedTeam.team_type;
        row["checked"] = assignedTeam.team_type !== "NONE" ? true : false;
      }
    });
    setTeamsList([...newTempArr]);
    copyTeamsList.current = newTempArr;
    checkIfIndeterminate();

    setOpenDialog(false);
    dispatch(teamAssign(copyTeamsList.current));
  };

  const onChangeTeamType = (ev) => {
    const tempData = JSON.parse(JSON.stringify(assignedTeam));
    tempData.team_type = ev.target.value;
    setAssignedTeam(tempData);
  };

  const checkIfIndeterminate = useCallback(() => {
    const tempSelectedCount = copyTeamsList.current.filter(
      (t) => t["checked"] == true
    ).length;
    if (tempSelectedCount !== 0) {
      setSelectedTeamCount(tempSelectedCount);
    } else {
      setSelectedTeamCount(0);
    }
  });

  const pageChanged = () => {
    const offset = currentPage.current * rowsPerPage;
    const filteredDataSource = poiService.onFilterChanges(
      copyTeamsList.current,
      selectedFilters.current
    );
    const paginatedData = filteredDataSource
      .slice(offset)
      .slice(0, rowsPerPage);
    setTeamsList(paginatedData);
  };

  const closeSnackBar = () => {
    setSnackBarState({
      open: false,
      vertical: "top",
      horizontal: "center",
      type: "success",
      message: "",
    });
  };

  useEffect(() => {
    getTeamsList();
  }, []);

  useEffect(() => {
    checkIfIndeterminate();
  }, [checkIfIndeterminate]);

  return (
    <>
      {isLoading === "true" ? (
        <Spinner
          color="dark"
          style={{
            position: "absolute",
            margin: "auto",
            top: "50%",
            left: "50%",
          }}
        />
      ) : (
        <>
          <Alert icon={false} sx={{ marginBottom: 2 }}>
            Select the teams for assigning it to this POI.
          </Alert>
          <Snackbar
            anchorOrigin={{
              vertical: snackBarState.vertical,
              horizontal: snackBarState.horizontal,
            }}
            autoHideDuration={6000}
            open={snackBarState.open}
            key={snackBarState.vertical + snackBarState.horizontal}
          >
            <Alert
              onClose={closeSnackBar}
              severity={snackBarState.type}
              sx={{ width: "100%" }}
            >
              {snackBarState.message}
            </Alert>
          </Snackbar>
          <div className="wrapper">
            <Dialog
              open={openDialog}
              onClose={handleDialogClose}
              sx={{ width: "100%" }}
              maxWidth="sm"
              fullWidth
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
              className="align-center"
            >
              <DialogTitle id="alert-dialog-title">Assign Team</DialogTitle>
              <DialogContent>
                <DialogContentText
                  id="alert-dialog-description"
                  className="dialog-content"
                >
                  <p>Team: {assignedTeam.name}</p>
                  <p>Select a team type:</p>
                  <FormControl sx={{ minWidth: 80 }} variant="standard">
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      label="Age"
                      autoWidth
                      value={assignedTeam.team_type}
                      onChange={(e) => onChangeTeamType(e)}
                      disabled={!user.permissions.poi_editAssignedTeamsTab}
                    >
                      <MenuItem value={"NONE"}>None</MenuItem>
                      <MenuItem value={"DOD"}>DOD/Force on-demand</MenuItem>
                      <MenuItem value={"DEDICATED"}>Dedicated</MenuItem>
                      <MenuItem value={"PREASSIGN"}>Pre-assign</MenuItem>
                      <MenuItem value={"POOLING"}>On-demand/Pooling</MenuItem>
                      <MenuItem value={"SACN"}>Scan to assign</MenuItem>
                    </Select>
                  </FormControl>
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button onClick={handleDialogClose}>Cancel</Button>
                <Button onClick={applyTeamType} autoFocus>
                  Apply
                </Button>
              </DialogActions>
            </Dialog>
            <TableContainer sx={{ maxHeight: 440 }}>
              <Table
                stickyHeader
                aria-label="sticky table"
                className="assigned-teams-table"
              >
                <TableHead>
                  <TableRow>
                    <TableCell></TableCell>
                    <TableCell></TableCell>
                    {tableColumns.map((column) => (
                      <React.Fragment key={column.id}>
                        <TableCell
                          key={column.id}
                          align={column.align}
                          style={{ minWidth: column.minWidth }}
                        >
                          <label className="pb-1 d-block">{column.label}</label>
                          <TextField
                            id="search"
                            label="Search"
                            variant="outlined"
                            size="small"
                            onChange={(ev) =>
                              search(ev.target.value, column.id)
                            }
                          />
                        </TableCell>
                      </React.Fragment>
                    ))}
                  </TableRow>
                </TableHead>
                <TableBody>
                  {teamsList.map((row, rowIndex) => {
                    return (
                      <TableRow
                        hover
                        role="checkbox"
                        tabIndex={-1}
                        key={rowIndex}
                      >
                        <TableCell key={rowIndex}>
                          <Checkbox
                            checked={
                              row.team_type && row.team_type !== "NONE"
                                ? true
                                : false
                            }
                            onChange={(e) =>
                              onTeamSelect(e.target.checked, row)
                            }
                            inputProps={{ "aria-label": "controlled" }}
                            sx={{ "& .MuiSvgIcon-root": { fontSize: 20 } }}
                            disabled={
                              !user.permissions.poi_editAssignedTeamsTab
                            }
                          />
                        </TableCell>
                        <TableCell key={rowIndex + "edit"}>
                          {row.team_type && row.team_type !== "NONE" ? (
                            <CreateIcon
                              className="edit-icon"
                              onClick={() => onTeamSelect(true, row)}
                            ></CreateIcon>
                          ) : (
                            ""
                          )}
                        </TableCell>
                        {tableColumns.map((column) => {
                          let value = "";
                          if (row[column.id]) {
                            if (column.id === "team_type") {
                              value = poiService.getTeamTypeText(
                                row[column.id]
                              );
                            } else {
                              value = row[column.id];
                            }
                          }
                          return (
                            <React.Fragment key={column.id}>
                              <TableCell key={column.id} align={column.align}>
                                {column.format && typeof value === "number"
                                  ? column.format(value)
                                  : value}
                              </TableCell>
                            </React.Fragment>
                          );
                        })}
                      </TableRow>
                    );
                  })}
                </TableBody>
              </Table>
            </TableContainer>
            <div className="pagination-container">
              <div className="selected-count-wrapper">
                Total {totalItemCount} | Selected {selectedTeamCount}
              </div>
              <div className="pagination-wrapper">
                <TablePagination
                  rowsPerPageOptions={[]}
                  component="div"
                  count={totalItemCount}
                  rowsPerPage={rowsPerPage}
                  page={currentPage.current}
                  showFirstButton
                  showLastButton
                  onPageChange={handleChangePage}
                />
              </div>
            </div>
          </div>
        </>
      )}
    </>
  );
};

export default AssignedTeams;
