import { createSlice } from "@reduxjs/toolkit";

export const poiListSlice = createSlice({
  name: "poiList",
  initialState: {
    value: {
      selectedAccount: {},
      viewType: "",
      selectedPoi: "",
      accountsList: [],
      multiEditIds: [],
    },
    reloadState: { isReload: false },
  },
  reducers: {
    poiList: (state, action) => {
      state.value = action.payload;
    },

    setInitialValues: (state, action) => {
      state.value.multiEditIds = state.value.multiEditIds.map((item) => {
        item.initialValues = action.payload;
        return item;
      });
    },
    reloadPoiList: (state, action) => {
      state.reloadState = action.payload;
    },
  },
});

export const { poiList, reloadPoiList } = poiListSlice.actions;

export default poiListSlice.reducer;
