import React, { useEffect, useRef, useState } from "react";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import AccordionDetails from "@mui/material/AccordionDetails";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import InfoIcon from "@mui/icons-material/Info";
import IconButton from "@mui/material/IconButton";
import Tooltip from "@mui/material/Tooltip";
import {
  Grid,
  Typography,
  TextField,
  FormGroup,
  MenuItem,
  Alert,
  Snackbar,
  Autocomplete,
  Switch,
} from "@mui/material";
import { Spinner } from "reactstrap";
import Checkbox from "@mui/material/Checkbox";
import InputAdornment from "@mui/material/InputAdornment";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { useApolloClient, useQuery } from "@apollo/client";

import AddIcon from "@mui/icons-material/Add";
import { v4 as uuidv4 } from "uuid";
import Link from "@mui/material/Link";
import DeleteIcon from "@mui/icons-material/Delete";
import CloseIcon from "@mui/icons-material/Close";
import { useDispatch, useSelector } from "react-redux";
import ContentCopyIcon from "@mui/icons-material/ContentCopy";
import PoiService from "../../services/PoiService";
import { configTab } from "../../reducers/configTab";
import {
  GET_CONFIG_EDIT_APIS,
  GET_DP_ACCOUNT,
  VALIDATE_POI_REF_ID,
} from "../../graphql/queries";
import { CREATE_AGGREGATOR, DELETE_AGGREGATOR } from "../../graphql/mutations";
import ConfigurationsService from "../../services/ConfigurationsService";
import { formDetailsTab } from "../../reducers/detailTab";
import { poiList } from "../../reducers/poiLists";

const PoiAdvancedConfig = ({ enabled, handleEnableChange }) => {
  const poiService = new PoiService();
  const configService = new ConfigurationsService();

  const configTabSelector = useSelector((state) => state.configTab.value);
  const configDropdownSelector = useSelector(
    (state) => state.configTab.dropdownOptions
  );
  const clientConfigsSelector = useSelector(
    (state) => state.configTab.clientConfigs
  );
  const poiListSelector = useSelector((state) => state.poiLists.value);
  const detailsTabSelector = useSelector((state) => state.detailTab.value);
  const user = useSelector((state) => state.userInfo.value);
  const dispatch = useDispatch();

  const client = useApolloClient();

  const timer = useRef(null);
  const [isLoading, setIsLoading] = useState("false");
  const [aggregatorInput, setAggregatorInput] = useState([]);
  const [startSOS, setStartSOS] = useState({});
  const [endSOS, setEndSOS] = useState({});
  const [deliveryPartnerDropDownUI, setDeliveryPartnerDropDownUI] = useState([
    { preference: "", id: "", switching_time: "" },
  ]);

  const [deliveryPartnerDropDownList, setDeliveryPartnerDropDownList] = useState({
    "new_dps": [{id: "", preference: "",  switching_time: "" }],
    "delete_dps": [{id: "", preference: "",  switching_time: "" }],
    "change_priority_dps": [{id: "", preference: "",  switching_time: "" }],
    "update_dps": [{id: "", preference: "",  switching_time: "" }],
  });

  const [expanded, setExpanded] = React.useState(false);
  const [snackBarState, setSnackBarState] = React.useState({
    open: false,
    vertical: "bottom",
    horizontal: "center",
    message: "",
  });

  const [accountsList, setAccountsList] = useState([]);


  const filterAccountsList = (accountsList, aggregatorList) => {
    if (!aggregatorList) {
      return accountsList;
    }

    return accountsList.filter((account) => {
      return !aggregatorList.some(
        (aggregator) => String(aggregator.aggregator) == String(account.id)
      );
    });
  };

  const handleAddAggregatorFields = () => {
    setAggregatorInput([
      ...aggregatorInput,
      { id: uuidv4(), aggregator: "", token: "" },
    ]);
  };

  const copyAccessToken = (inToken) => {
    navigator.clipboard.writeText(inToken);
  };

  const createAggregator = async (inVal, inField) => {
    const tempCreateAggregator = await client.mutate({
      mutation: CREATE_AGGREGATOR,
      variables: {
        client_id: detailsTabSelector.client_id.id,
        enabled_client_id: inVal,
        poi_id: poiListSelector.selectedPoi,
      },
      fetchPolicy: "network-only",
    });
    const tempAggregators = JSON.parse(JSON.stringify(aggregatorInput));
    tempAggregators.forEach((el) => {
      if (el.id === inField.id) {
        el.token = tempCreateAggregator.data.createAggregator.data;
      }
    });
    setAggregatorInput(tempAggregators);
    handleInputChange(tempAggregators, "aggregator");
    setSnackBar("Changes saved successfully!", "success");
  };

  const onAggregatorFieldChange = (ev, val, inField) => {
    const tempAggregators = JSON.parse(JSON.stringify(aggregatorInput));
    tempAggregators.forEach((el) => {
      if (el.id === inField.id) {
        el.aggregator = val.id;
        el["aggregatorValue"] = {
          id: val.id,
          name: val.name,
        };
      }
    });
    setAggregatorInput(tempAggregators);

    // Remove the selected account from accountsList
    const updatedAccountsList = filterAccountsList(
      poiListSelector.accountsList,
      tempAggregators
    );
    setAccountsList(updatedAccountsList);

    handleInputChange(tempAggregators, "aggregator");
  };

  const deleteAggregator = async (inField) => {
    const tempDeleteAggregator = await client.mutate({
      mutation: DELETE_AGGREGATOR,
      variables: {
        poi_id: poiListSelector.selectedPoi,
        enabled_client_id: inField.aggregator,
      },
      fetchPolicy: "network-only",
    });
    if (tempDeleteAggregator.data.deleteAggregator.success) {
      const values = [...aggregatorInput];
      values.splice(
        values.findIndex((value) => value.id === inField.id),
        1
      );
      setAggregatorInput(values);

      // Remove the removed aggregator from accountsList
      const updatedAccountsList = filterAccountsList(
        poiListSelector.accountsList,
        values
      );
      setAccountsList(updatedAccountsList);

      handleInputChange(values, "aggregator");
      setSnackBar("Changes saved successfully!", "success");
    } else {
      setSnackBar("Something went wrong please try again later!", "error");
    }
  };

  const handleAggregatorRemove = (inField) => {
    if (poiListSelector.viewType === "edit") {
      deleteAggregator(inField);
    } else if (poiListSelector.viewType === "add") {
      const values = [...aggregatorInput];
      values.splice(
        values.findIndex((value) => value.id === inField.id),
        1
      );
      setAggregatorInput(values);

      // Remove the removed aggregator from accountsList
      const updatedAccountsList = filterAccountsList(
        poiListSelector.accountsList,
        values
      );
      setAccountsList(updatedAccountsList);

      handleInputChange(values, "aggregator");
    }
  };

  const addNewSos = (type) => {
    const tempSos = JSON.parse(
      JSON.stringify(type === "startSOS" ? startSOS : endSOS)
    );
    const tempLength = Object.keys(tempSos).length;
    if (tempLength < 2) {
      tempSos[tempLength] = {
        is_sheduled: "1",
        field: "order_creation_time",
        formula: [],
      };
      if (type === "startSOS") {
        setStartSOS(tempSos);
      } else {
        setEndSOS(tempSos);
      }
      handleInputChange(tempSos, type);
    }
  };

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
    if (panel === "panel5" && poiListSelector.viewType === "multiEdit") {
      handleEnableChange();
    }
  };

  const handleInputChange = (value, inField) => {
    const tempFields = poiService.getDeepCopy(configTabSelector);
    tempFields[inField] = value;
    if (
      poiListSelector.viewType === "multiEdit" &&
      ["is_dp_enabled", "is_delivery_partner_auto_assignment_enabled", "dod_request_time", "target_dod_arrival_time", "dod_target_assign"].includes(inField)
    ) {
      tempFields["multiEditDp"][inField].checked = true;
    }

    // Validate target_dod_arrival_time && dod_target_assign
    if ([ "target_dod_arrival_time", "dod_target_assign", "dod_request_time"].includes(inField)){
      if(value < 0){
        return;
      }
    }
    dispatch(configTab(tempFields));
  };

  const handleDetailsInputChange = (value, inField) => {
    const tempFields = poiService.getDeepCopy(detailsTabSelector);
    tempFields[inField] = value;
    dispatch(formDetailsTab(tempFields));
  };

  const handleOrderAcceptanceCheckChange = (value) => {
    const tempFields = poiService.getDeepCopy(configTabSelector);

    if (value === "DELIVERY_BOUNDARY") {
      tempFields["Check_for_Delivery_Boundary"] = "true";
    } else if (value === "DELIVERY_AREA") {
      tempFields["Check_for_Delivery_Area"] = "true";
    } else if (value === "TEAM_BOUNDARY") {
      tempFields["Check_for_Team_Boundary"] = "true";
    } else if (value === "NONE") {
      tempFields["Check_for_Delivery_Boundary"] = "false";
      tempFields["Check_for_Delivery_Area"] = "false";
      tempFields["Check_for_Team_Boundary"] = "false";
    }

    tempFields["order_acceptance_check"] = value;
    dispatch(configTab(tempFields));
  };

  const onScheduleChange = (index, value, type) => {
    const tempSos = poiService.getDeepCopy(
      type === "startSOS" ? startSOS : endSOS
    );
    tempSos[index]["is_sheduled"] = value;
    if (type === "startSOS") {
      setStartSOS(tempSos);
    } else {
      setEndSOS(tempSos);
    }
    handleInputChange(tempSos, type);
  };

  const onMultiCheckBoxChange = (ev, mainField, subField) => {
    const tempFields = poiService.getDeepCopy(configTabSelector);
    tempFields[mainField][subField] = ev.target.checked;
    dispatch(configTab(tempFields));
  };

  const checkIfBufferTimeExists = (inValue) => {
    let isBufferTypeExists = false;
    if (inValue.field === "buffer") {
      isBufferTypeExists = true;
    }
    if (inValue.formula && inValue.formula.length) {
      for (let i = 0; i < inValue.formula.length; i++) {
        if (inValue.formula[i].field === "buffer") {
          isBufferTypeExists = true;
          break;
        }
      }
    }

    return isBufferTypeExists;
  };

  const onScheduleOptionChange = (index, value, type) => {
    const tempSos = poiService.getDeepCopy(
      type === "startSOS" ? startSOS : endSOS
    );

    if (value === "buffer") {
      if (!checkIfBufferTimeExists(tempSos[index])) {
        tempSos[index]["value"] = "0";
        tempSos[index]["field"] = value;
      } else {
        setSnackBar("Buffer time is already selected", "error");
      }
    } else {
      delete tempSos[index]["value"];
      tempSos[index]["field"] = value;
    }

    if (type === "startSOS") {
      setStartSOS(tempSos);
    } else {
      setEndSOS(tempSos);
    }
    handleInputChange(tempSos, type);
  };

  const handleBufferChange = (index, value, type) => {
    const tempSos = poiService.getDeepCopy(
      type === "startSOS" ? startSOS : endSOS
    );
    tempSos[index]["value"] = value;
    if (type === "startSOS") {
      setStartSOS(tempSos);
    } else {
      setEndSOS(tempSos);
    }
    handleInputChange(tempSos, type);
  };

  const handleAddFormulaFields = (index, type) => {
    const tempSos = poiService.getDeepCopy(
      type === "startSOS" ? startSOS : endSOS
    );
    tempSos[index]["formula"].push({
      operation: "ADD",
      field: "order_creation_time",
    });
    if (type === "startSOS") {
      setStartSOS(tempSos);
    } else {
      setEndSOS(tempSos);
    }
    handleInputChange(tempSos, type);
  };

  const onOperatorChange = (objIdx, formulaIdx, value, type) => {
    const tempSos = poiService.getDeepCopy(
      type === "startSOS" ? startSOS : endSOS
    );
    tempSos[objIdx]["formula"][formulaIdx]["operation"] = value;
    if (type === "startSOS") {
      setStartSOS(tempSos);
    } else {
      setEndSOS(tempSos);
    }
    handleInputChange(tempSos, type);
  };

  const onOperationChange = (objIdx, formulaIdx, value, type) => {
    const tempSos = poiService.getDeepCopy(
      type === "startSOS" ? startSOS : endSOS
    );
    if (value === "buffer") {
      if (!checkIfBufferTimeExists(tempSos[objIdx])) {
        tempSos[objIdx]["formula"][formulaIdx]["value"] = "0";
        tempSos[objIdx]["formula"][formulaIdx]["field"] = value;
      } else {
        setSnackBar("Buffer time is already selected", "error");
      }
    } else {
      delete tempSos[objIdx]["formula"][formulaIdx]["value"];
      tempSos[objIdx]["formula"][formulaIdx]["field"] = value;
    }

    if (type === "startSOS") {
      setStartSOS(tempSos);
    } else {
      setEndSOS(tempSos);
    }
    handleInputChange(tempSos, type);
  };

  const handleFormulaBufferChange = (objIdx, formulaIdx, value, type) => {
    const tempSos = poiService.getDeepCopy(
      type === "startSOS" ? startSOS : endSOS
    );
    tempSos[objIdx]["formula"][formulaIdx]["value"] = value;
    if (type === "startSOS") {
      setStartSOS(tempSos);
    } else {
      setEndSOS(tempSos);
    }
    handleInputChange(tempSos, type);
  };

  const handleRemoveFormulaFields = (objIdx, formulaIdx, type) => {
    const tempSos = poiService.getDeepCopy(
      type === "startSOS" ? startSOS : endSOS
    );
    tempSos[objIdx]["formula"].splice(formulaIdx, 1);
    if (type === "startSOS") {
      setStartSOS(tempSos);
    } else {
      setEndSOS(tempSos);
    }
    handleInputChange(tempSos, type);
  };

  const handleRemoveSosFields = (objIdx, type) => {
    const tempSos = poiService.getDeepCopy(
      type === "startSOS" ? startSOS : endSOS
    );
    delete tempSos[objIdx];
    if (type === "startSOS") {
      setStartSOS(tempSos);
    } else {
      setEndSOS(tempSos);
    }
    handleInputChange(tempSos, type);
  };

  const addDeliveryPartnerDropDown = () => {
    let tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownUI);
    const dp = {
      dp_list: [1],
      preference: "",
      id: "",
      switching_time: "",
    };
    tempDPList.push(dp);
    tempDPList.forEach((list) => {
      list["dp_list"] = [...Array(tempDPList.length).keys()];
    });
    setDeliveryPartnerDropDownUI(tempDPList);
    if (poiListSelector.viewType === "multiEdit") {
      handleInputChange(tempDPList, "delivery_partner");
    }
  };

  const addDeliveryPartnerDropDownList = (type) => {
    let tempDPList;
    switch (type) {
      case "new":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.new_dps);
        break;
      case "delete":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.delete_dps);
        break;
      case "change_priority":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.change_priority_dps);
        break;
      case "update":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.update_dps);
        break;
      default:
        throw new Error(`Unknown type: ${type}`);
    }

    const dp = {
      dp_list: [1],
      preference: "",
      id: "",
      switching_time: "",
    };

    // Add the new delivery partner object to the list
    tempDPList.push(dp);

    // Update the dp_list for each object in the list
    tempDPList.forEach((list) => {
      list["dp_list"] = [...Array(tempDPList.length).keys()];
    });

    // Create a new object to update the state
    const updatedList = {
      new_dps: type === "new" ? tempDPList : deliveryPartnerDropDownList.new_dps,
      delete_dps: type === "delete" ? tempDPList : deliveryPartnerDropDownList.delete_dps,
      change_priority_dps: type === "change_priority" ? tempDPList : deliveryPartnerDropDownList.change_priority_dps,
      update_dps: type === "update" ? tempDPList : deliveryPartnerDropDownList.update_dps,
    };

    // Update the state with the new list
    setDeliveryPartnerDropDownList(updatedList);
    handleInputChange(updatedList, "delivery_partners_customization");

  };

  const removeDeliveryPartnerDropDown = (inVal, idx) => {
    let tmp = poiService.getDeepCopy(deliveryPartnerDropDownUI);
    let tempDPList = [...tmp.slice(0, idx), ...tmp.slice(idx + 1)];
    tempDPList.forEach((list, idx) => {
      list["dp_list"] = [...Array(tempDPList.length).keys()];
      list["preference"] = idx + 1;
    });

    setDeliveryPartnerDropDownUI(tempDPList);
    handleInputChange(tempDPList, "delivery_partner");
  };

  const removeDeliveryPartnerDropDownList = (type, idx) => {
    let tempDPList;
    switch (type) {
      case "new":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.new_dps);
        break;
      case "delete":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.delete_dps);
        break;
      case "change_priority":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.change_priority_dps);
        break;
      case "update":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.update_dps);
        break;
      default:
        throw new Error(`Unknown type: ${type}`);
    }

    // Remove the delivery partner object at the specified index
    tempDPList = [...tempDPList.slice(0, idx), ...tempDPList.slice(idx + 1)];

    // Update the dp_list and preference for each object in the list
    tempDPList.forEach((list, idx) => {
      list["dp_list"] = [...Array(tempDPList.length).keys()];
      list["preference"] = idx + 1;
    });

    // Create a new object to update the state
    const updatedList = {
      new_dps: type === "new" ? tempDPList : deliveryPartnerDropDownList.new_dps,
      delete_dps: type === "delete" ? tempDPList : deliveryPartnerDropDownList.delete_dps,
      change_priority_dps: type === "change_priority" ? tempDPList : deliveryPartnerDropDownList.change_priority_dps,
      update_dps: type === "update" ? tempDPList : deliveryPartnerDropDownList.update_dps,
    };

    // Update the state with the new list
    setDeliveryPartnerDropDownList(updatedList);
    handleInputChange(updatedList, "delivery_partners_customization");
  };

  const closeSnackBar = () => {
    setSnackBarState({
      open: false,
      vertical: "bottom",
      horizontal: "center",
      message: "",
    });
  };

  const checkIfDPOrderAlreadyExist = (inVal, type) => {
    let isDPOrderExists = false;
    let tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownUI);
    const tempDPListLength = tempDPList.length;
    if (type === "switching_time") return isDPOrderExists;
    for (let i = 0; i < tempDPListLength; i++) {
      if (tempDPList[i][type] === inVal) {
        isDPOrderExists = true;
        break;
      }
    }

    return isDPOrderExists;
  };

  const checkIfDPAlreadyExist = (inVal, type, action) => {
    let isDPOrderExists = false;
    let tempDPList;
    switch (action) {
      case "new":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.new_dps);
        break;
      case "delete":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.delete_dps);
        break;
      case "change_priority":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.change_priority_dps);
        break;
      case "update":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.update_dps);
        break;
      default:
        throw new Error(`Unknown type: ${type}`);
    }

    const tempDPListLength = tempDPList.length;
    if (type === "switching_time") return isDPOrderExists;
    for (let i = 0; i < tempDPListLength; i++) {
      if (tempDPList[i][type] === inVal) {
        isDPOrderExists = true;
        break;
      }
    }

    return isDPOrderExists;
  };

  const onChangeDPOrder = (inVal, type, idx) => {
    let tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownUI);
    if (!checkIfDPOrderAlreadyExist(inVal, type)) {
      tempDPList[idx][type] = inVal;
      if (type === "id") {
        tempDPList[idx].name =
          configDropdownSelector.deliveryPartnersList?.find(
            (item) => item.id === inVal
          ).name;
      }

      if(type === "switching_time" && inVal < 0){
          return;
      }

      setDeliveryPartnerDropDownUI(tempDPList);
      handleInputChange(tempDPList, "delivery_partner");
    } else {
      setSnackBar("These values are already selected!", "error");
    }
  };

  const onChangeDP = (inVal, type, idx, action) => {
    let tempDPList;
    switch (action) {
      case "new":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.new_dps);
        break;
      case "delete":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.delete_dps);
        break;
      case "change_priority":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.change_priority_dps);
        break;
      case "update":
        tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownList.update_dps);
        break;
      default:
        throw new Error(`Unknown type: ${type}`);
    }

    if (!checkIfDPAlreadyExist(inVal, type, action)) {
      tempDPList[idx][type] = inVal;

      // Create a new object to update the state
      const updatedList = {
        new_dps: action === "new" ? tempDPList : deliveryPartnerDropDownList.new_dps,
        delete_dps: action === "delete" ? tempDPList : deliveryPartnerDropDownList.delete_dps,
        change_priority_dps: action === "change_priority" ? tempDPList : deliveryPartnerDropDownList.change_priority_dps,
        update_dps: action === "update" ? tempDPList : deliveryPartnerDropDownList.update_dps,
      };

      if(type === "switching_time" && inVal < 0){
        return;
      }

      setDeliveryPartnerDropDownList(updatedList);
      handleInputChange(updatedList, "delivery_partners_customization");

    } else {
      setSnackBar("These values are already selected!", "error");
    }
  };

  const onDPCheckBoxChange = (type, idx) => {
    const tempDPList = poiService.getDeepCopy(deliveryPartnerDropDownUI);
    if (type === "priority" || type === "preference") {
      tempDPList[idx].pty_checked = !tempDPList[idx].pty_checked;
    }
    if (type === "switching_time") {
      tempDPList[idx].swt_checked = !tempDPList[idx].swt_checked;
    }
    setDeliveryPartnerDropDownUI(tempDPList);
    handleInputChange(tempDPList, "delivery_partner");
  };

  const onOrderPartnerChange = async (inVal, type) => {
    const tempFields = poiService.getDeepCopy(configTabSelector);
    tempFields.order_partner[type] = inVal;
    dispatch(configTab(tempFields));
    if (type === "partner_reference_id") {
      clearTimeout(timer.current);
      timer.current = setTimeout(async () => {
        const validateID = await client.query({
          query: VALIDATE_POI_REF_ID,
          variables: {
            account_id: detailsTabSelector.client_id.id,
            partner_reference_id: inVal,
            partner_type: configDropdownSelector.orderPartnersList[0]
              .partner_type
              ? configDropdownSelector.orderPartnersList[0].partner_type
              : 0,
          },
          fetchPolicy: "network-only",
        });

        if (validateID.data.validatePartnerReferenceId.data == "false") {
          setSnackBar("Please choose some other POI Reference ID", "error");
          const field = poiService.getDeepCopy(configTabSelector);
          field.order_partner["ref_id_err"] = true;
          dispatch(configTab(field));
        }
      }, 2000);
    }
  };

  const setSnackBar = (inMessage, inType) => {
    setSnackBarState({
      open: true,
      vertical: "bottom",
      horizontal: "center",
      type: inType,
      message: inMessage,
    });
  };

  const getEditModeAPIValues = async () => {
    setIsLoading("true");
    const tempGetEditModeAPIValues = await client.query({
      query: GET_CONFIG_EDIT_APIS,
      variables: {
        poiId: poiListSelector.selectedPoi,
      },
      fetchPolicy: "network-only",
    });

    const tempFields = poiService.getDeepCopy(configTabSelector);

    let tempDPList = tempGetEditModeAPIValues.data.getPoiDeliveryPartner.map(
      (list) => ({
        // dp_list: [...Array(tempLen).keys()],
        preference: list.preference,
        id: list.id,
        switching_time: list.switching_time,
        name: list.name,
      })
    );
    if (!tempDPList.length) {
      tempDPList = [{ preference: "", id: "", switching_time: "", name: "" }];
    }
    tempFields["delivery_partner"] = tempDPList;
    setDeliveryPartnerDropDownUI(tempDPList);

    if (
      tempGetEditModeAPIValues.data.getPoiOrderPartner &&
      tempGetEditModeAPIValues.data.getPoiOrderPartner.partner_type
    ) {
      tempFields["order_partner"] =
        tempGetEditModeAPIValues.data.getPoiOrderPartner;
    }

    dispatch(configTab(tempFields));
    setIsLoading("false");
  };

  const handleMultiEditDPChange = (key, val) => {
    const tmpConfig = poiService.getDeepCopy(configTabSelector);
    tmpConfig["multiEditDp"][key].checked = val;
    dispatch(configTab(tmpConfig));
  };

  useEffect(() => {
    configService.getClientConfigs();
    configService.getOrderPartnerDeliveryPartnerList();
    if (poiListSelector.viewType === "edit") {
      getEditModeAPIValues();
      if (configTabSelector && configTabSelector.startSOS) {
        setStartSOS(configTabSelector.startSOS);
      }
      if (configTabSelector && configTabSelector.endSOS) {
        setEndSOS(configTabSelector.endSOS);
      }
      if (configTabSelector && configTabSelector.aggregator) {
        setAggregatorInput(configTabSelector.aggregator);
      }
    }

    // Update accountsList when configTabSelector changes
    const updatedAccountsList = filterAccountsList(
      poiListSelector.accountsList,
      configTabSelector.aggregator
    );
    setAccountsList(updatedAccountsList);
  }, []);

  return (
    <>
      <Spinner
        color="dark"
        style={{
          position: "absolute",
          margin: "auto",
          top: "50%",
          left: "50%",
          display: isLoading === "true" ? "block" : "none",
        }}
      />
      <div style={{ display: isLoading === "true" ? "none" : "block" }}>
        <Snackbar
          anchorOrigin={{
            vertical: snackBarState.vertical,
            horizontal: snackBarState.horizontal,
          }}
          autoHideDuration={6000}
          onClose={closeSnackBar}
          open={snackBarState.open}
          key={snackBarState.vertical + snackBarState.horizontal}
        >
          <Alert
            onClose={closeSnackBar}
            severity={snackBarState.type}
            sx={{ width: "100%" }}
          >
            {snackBarState.message}
          </Alert>
        </Snackbar>
        {!configService.isDeliveryPartnerView(user.permissions) && (
          <Accordion
            expanded={expanded === "panel1"}
            onChange={handleChange("panel1")}
            size="small"
            margin="dense"
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel1a-content"
              id="panel1a-header"
              sx={{
                backgroundColor: "#ccc",
              }}
            >
              <Typography>API</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {/* <Grid item xs={8}>
                <Typography>
                  Enable driver tracking API
                  <Tooltip title="When selected, you will be able to the track drivers using driver tracking API. It will provide you the information about driver's current location (lat, long), name, phone number, etc.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.track_api_new == "true" || false
                      }
                      value={configTabSelector.track_api_new}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "track_api_new"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.track_api_new == "false"
                      }
                    />
                  }
                  label=""
                ></FormControlLabel>
              </Grid>

              <Grid item xs={8}>
                <Typography>
                  Include ETA in driver tracking API
                  <Tooltip title="When selected, Driver Tracking API will provide you estimated time from pickup to delivery.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.track_share_eta == "true" || false
                      }
                      value={configTabSelector.track_share_eta}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "track_share_eta"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.track_share_eta == "false"
                      }
                    />
                  }
                  label=""
                ></FormControlLabel>
              </Grid>

              <Grid item xs={8}>
                <Typography>
                  Return customer view URL
                  <Tooltip title="When selected, you will get the Customer View URL in the Order Creation API response. You can use Customer View for live tracking an order.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.return_track_id == "true" || false
                      }
                      value={configTabSelector.return_track_id}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "return_track_id"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.return_track_id == "false"
                      }
                    />
                  }
                  label=""
                />
              </Grid> */}

                <Grid item xs={12}>
                  <Typography
                    sx={{
                      fontWeight: "500",
                    }}
                  >
                    Reference IDs for Integration
                  </Typography>
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    Reference ID is a unique identifier of an application (e.g.
                    order management application of nGage). You can use it to
                    request access to an API service (e.g. order creation API).
                  </Typography>
                </Grid>

                <Grid item xs={8}>
                  <Typography>
                    Reference ID for order creation
                    <Tooltip title="Enter a POI identifier for integrating this POI with an external system for order creation.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Tooltip
                    title={
                      poiListSelector.viewType === "multiEdit" &&
                      "This configuration is disabled for multiple edit of POIs."
                    }
                  >
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      value={configTabSelector.number_gln || ""}
                      label=" "
                      InputLabelProps={{ shrink: false }}
                      id="outlined-adornment-target_assign"
                      onChange={(ev) =>
                        handleInputChange(ev.target.value, "number_gln")
                      }
                      disabled={
                        poiListSelector.viewType === "multiEdit" ||
                        !user.permissions.poi_editPoiConfiguration
                      }
                      variant="outlined"
                    />
                  </Tooltip>
                </Grid>

                <Grid item xs={8}>
                  <Typography>
                    Reference ID for Waynaq
                    <Tooltip title="It is a system-generated integration ID. It can be used as a unique ID while integrating this POI with schedule management systems.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Typography sx={{ marginTop: "15px" }}>
                    {configTabSelector.reference_id_waynaq}
                  </Typography>
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {/* <Accordion
          expanded={expanded === "panel2"}
          onChange={handleChange("panel2")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel2a-content"
            id="panel2a-header"
            sx={{
              backgroundColor: "#ccc",
            }}
          >
            <Typography>Cashier</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography>
                  Upfront payment by driver
                  <Tooltip title="When selected, orders will not be visible in the Cashier module. The driver has to pay the order amount (for COD orders) upfront at the POI.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.upfront_payment_by_driver == "true" ||
                        false
                      }
                      value={configTabSelector.upfront_payment_by_driver}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "upfront_payment_by_driver"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.upfront_payment_by_driver ==
                          "false"
                      }
                    />
                  }
                  label=""
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion> */}
        {!configService.isDeliveryPartnerView(user.permissions) && (
          <Accordion
            expanded={expanded === "panel3"}
            onChange={handleChange("panel3")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel3a-content"
              id="panel3a-header"
              sx={{
                backgroundColor: "#ccc",
              }}
            >
              <Typography>Customer Location</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography>
                    Use customer location algorithm v2.0
                    <Tooltip title="When selected, customer location will be determined using customer location algorithm v2.0.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={
                          (configTabSelector.Location_Algorithm_2 == "true" &&
                            clientConfigsSelector.is_allowd_location !=
                              "false") ||
                          false
                        }
                        value={configTabSelector.Location_Algorithm_2}
                        onChange={(ev) =>
                          handleInputChange(
                            ev.target.checked.toString(),
                            "Location_Algorithm_2"
                          )
                        }
                        disabled={
                          !user.permissions.poi_editPoiConfiguration ||
                          clientConfigsSelector.is_allowd_location == "false"
                        }
                      />
                    }
                    label=""
                  />
                </Grid>

                {/* <Grid item xs={8}>
                <Typography>
                  Convert customer address into lat,long for accepting/rejecting
                  order
                  <Tooltip title="When selected, the customer address will be converted into lat, long while accepting or rejecting the order. This setting can be enabled when the lat, long is not available.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.is_convert_address == "true" || false
                      }
                      value={configTabSelector.is_convert_address}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "is_convert_address"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.is_convert_address == "false"
                      }
                    />
                  }
                  label=""
                />
              </Grid> */}

                <Grid item xs={8}>
                  <Typography>
                    Order acceptance check v2.0
                    <Tooltip
                      title="Select the option for accepting or rejecting the orders.
* Delivery area: orders will be accepted based on the configured delivery areas (it also depends on the fields to consider configuration).
* Delivery boundary: orders will be accepted if the order's destination lat, long falls within the delivery boundary configured at the POI level.
* Team boundary: Orders will be accepted if the order's destination lat, long falls within the team boundary configured at the Team level."
                    >
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    size="small"
                    select
                    variant="outlined"
                    label="Select"
                    sx={{ width: "100%" }}
                    value={
                      configTabSelector.Check_for_Delivery_Boundary === "true"
                        ? "DELIVERY_BOUNDARY"
                        : configTabSelector.Check_for_Delivery_Area === "true"
                          ? "DELIVERY_AREA"
                          : configTabSelector.Check_for_Team_Boundary === "true"
                            ? "TEAM_BOUNDARY"
                            : "NONE"
                    }
                    onChange={(ev) => {
                      handleOrderAcceptanceCheckChange(ev.target.value);
                    }}
                    disabled={
                      !user.permissions.poi_editPoiConfiguration ||
                      clientConfigsSelector.order_acceptance_check == "NONE"
                    }
                  >
                    <MenuItem value={"NONE"}>None</MenuItem>
                    {clientConfigsSelector.order_acceptance_check ===
                      "DELIVERY_BOUNDARY" && (
                      <MenuItem value={"DELIVERY_BOUNDARY"}>
                        Delivery Boundary
                      </MenuItem>
                    )}

                    {clientConfigsSelector.order_acceptance_check ===
                      "DELIVERY_AREA" && (
                      <MenuItem value={"DELIVERY_AREA"}>Delivery Area</MenuItem>
                    )}

                    {clientConfigsSelector.order_acceptance_check ===
                      "TEAM_BOUNDARY" && (
                      <MenuItem value={"TEAM_BOUNDARY"}>Team Boundary</MenuItem>
                    )}
                  </TextField>
                </Grid>

                {/* <Grid item xs={8}>
                <Typography>
                  Fields to Consider
                  <Tooltip
                    title="Select the field to consider while accepting or rejecting the orders.
* Delivery area: orders will be accepted if the order's delivery area is one of the configured delivery areas at the POI level
* Destination lat, long: orders will be accepted if the order's destination lat, long falls within one of the delivery areas configured at the POI level
* Delivery area & destination lat, long: orders will be accepted if the order's delivery area is one of the configured delivery areas at the POI level and if the order's destination lat, long falls within the order's delivery area."
                  >
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={configTabSelector.order_fields.DELIVERY_AREA}
                        onChange={(ev) =>
                          onMultiCheckBoxChange(
                            ev,
                            "order_fields",
                            "DELIVERY_AREA"
                          )
                        }
                        disabled={
                          !user.permissions.poi_editPoiConfiguration ||
                          clientConfigsSelector.order_fields == ""
                        }
                      />
                    }
                    label="DELIVERY_AREA"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={
                          configTabSelector.order_fields.DESTINATION_LAT_LONG
                        }
                        onChange={(ev) =>
                          onMultiCheckBoxChange(
                            ev,
                            "order_fields",
                            "DESTINATION_LAT_LONG"
                          )
                        }
                        disabled={
                          !user.permissions.poi_editPoiConfiguration ||
                          clientConfigsSelector.order_fields == ""
                        }
                      />
                    }
                    label="Destination Lat, Long"
                  />
                </FormGroup>
              </Grid> */}
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        <Accordion
          expanded={expanded === "panel5" || enabled}
          onChange={handleChange("panel5")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel5a-content"
            id="panel5a-header"
            sx={{
              backgroundColor: "#ccc",
            }}
          >
            <Typography>Delivery Partner</Typography>
          </AccordionSummary>
          <AccordionDetails>
            {poiListSelector.viewType === "multiEdit" && (
              <Alert icon={false} severity="primary">
                Choose the bulk action(s) you wish to perform on the selected
                POIs.
              </Alert>
            )}
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <Grid container>
                  <Grid item xs={8} display={"flex"}>
                    {poiListSelector.viewType === "multiEdit" && (
                      <Checkbox
                        checked={
                          configTabSelector.multiEditDp.is_dp_enabled.checked
                        }
                        onChange={(ev) => {
                          const value = ev.target.checked;
                          handleMultiEditDPChange("is_dp_enabled", value);
                        }}
                        sx={{ paddingLeft: 0 }}
                      />
                    )}
                    <Typography>
                      Delivery partner
                      <Tooltip title="Enabling this will allow the selection of preferred delivery partners and define the order in which they should be considered for delivery.">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Grid>

                  <Grid item xs={4}>
                    <Switch
                      checked={
                        configTabSelector.is_dp_enabled == "true" || false
                      }
                      onChange={(ev) => {
                        handleInputChange(
                          ev.target.checked.toString(),
                          "is_dp_enabled"
                        );
                      }}
                      inputProps={{ "aria-label": "controlled" }}
                      value={
                        configTabSelector.is_dp_enabled === "true" || false
                      }
                      disabled={
                        !configService.isDeliveryPartnerEdit(
                          user.permissions
                        ) ||
                        clientConfigsSelector.enable_delivery_partner ===
                          "false"
                      }
                    />
                  </Grid>
                </Grid>
              </Grid>

              {configTabSelector.is_dp_enabled === "true" && (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={8} display={"flex"}>
                      {poiListSelector.viewType === "multiEdit" && (
                        <Checkbox
                          checked={
                            configTabSelector.multiEditDp
                              .is_delivery_partner_auto_assignment_enabled.checked
                          }
                          onChange={(ev) => {
                            const value = ev.target.checked;
                            handleMultiEditDPChange(
                              "is_delivery_partner_auto_assignment_enabled",
                              value
                            );
                          }}
                          sx={{ paddingLeft: 0 }}
                        />
                      )}
                      <Typography>
                        Delivery Partner Auto Assignment
                        <Tooltip title="Enabling this will allow the system to automatically switch to and between delivery partners based on the configured settings">
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </Grid>

                    <Grid item xs={4}>
                      <Switch
                        checked={ configTabSelector.is_delivery_partner_auto_assignment_enabled == "true" || false }
                        onChange={(ev) => {
                          handleInputChange(
                            ev.target.checked.toString(),
                            "is_delivery_partner_auto_assignment_enabled"
                          );
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        value={
                          configTabSelector.is_delivery_partner_auto_assignment_enabled
                        }
                        disabled={
                          !configService.isDeliveryPartnerEdit(
                            user.permissions
                          ) ||
                          clientConfigsSelector.is_delivery_partner_auto_assignment_enabled ===
                            "false"
                        }
                      />
                    </Grid>
                  </Grid>
                </Grid>
              )}

              {poiListSelector.viewType === "multiEdit" && (
                <Grid item xs={12}>
                  <Grid container>
                    <Grid item xs={6} display={"flex"}>
                      {poiListSelector.viewType === "multiEdit" && (
                          <Checkbox
                              checked={
                                configTabSelector.multiEditDp
                                    .is_edit_delivery_partner_sections.checked ||  configTabSelector.is_dp_enabled == "true"
                              }
                              onChange={(ev) => {
                                const value = ev.target.checked;
                                handleMultiEditDPChange(
                                    "is_edit_delivery_partner_sections",
                                    value
                                );
                              }}
                              sx={{ paddingLeft: 0 }}
                          />
                      )}
                      <Typography>
                        Edit Delivery Partner Sections
                        <Tooltip title="Choose between applying the same configuration to all selected POIs, or making individual adjustments">
                          <IconButton>
                            <InfoIcon />
                          </IconButton>
                        </Tooltip>
                      </Typography>
                    </Grid>

                    <Grid item xs={6}>
                      <FormControlLabel
                          control={
                            <Radio
                                checked={
                                    configTabSelector.multiEditDp.edit_delivery_partner_sections.checked ===
                                    "template" ||false
                                }
                                disabled={
                                    !user.permissions.poi_editPoiConfiguration
                                }
                                onChange={(ev) => {
                                  handleMultiEditDPChange(
                                      "edit_delivery_partner_sections",
                                      "template"
                                  );
                                }}
                            />
                          }
                          label={<><Tooltip title="This option allows you to configure all selected POIs with the same settings. This is useful if you want all selected POIs to have identical configurations for delivery partners, priorities, and switching times">
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>Template</>}
                          dir={"rtl"}
                      />

                      <FormControlLabel
                          control={
                            <Radio
                                checked={
                                    configTabSelector.multiEditDp.edit_delivery_partner_sections.checked ===
                                    "customization" || false
                                }
                                disabled={
                                    !user.permissions.poi_editPoiConfiguration
                                }
                                onChange={(ev) => {
                                  handleMultiEditDPChange(
                                      "edit_delivery_partner_sections",
                                      "customization"
                                  );
                                }}
                            />
                          }
                          label={<><Tooltip title="This option allows you to maintain the existing configurations of each selected POI but make slight changes. This is useful if you want to adjust settings without completely overwriting the current configurations of the selected POIs.">
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>Customization</>}
                          dir={"rtl"}
                      />

                    </Grid>
                  </Grid>
                </Grid>
              )}

              {/* Template Section */}
              {configTabSelector.is_dp_enabled === "true" && (poiListSelector.viewType === "multiEdit" && configTabSelector.multiEditDp.edit_delivery_partner_sections.checked === "template" || poiListSelector.viewType !== "multiEdit") && (
                  <Grid item xs={12}>
                    {deliveryPartnerDropDownUI.map((list, idx) => (
                        <div style={{ marginBottom: 10, marginLeft: 30, marginRight: 30 }} key={idx}>
                          <Grid container spacing={3} alignItems={"flex-center"}>
                            <Grid item xs={8}>
                              <Grid container spacing={3}>
                                <Grid item xs={4}>
                                  <Typography>
                                    Priority
                                    <Tooltip title="This field is to select the priority level that the system should consider when displaying or auto-assigning delivery partners.">
                                      <IconButton sx={{ padding: 0.5 }}>
                                        <InfoIcon />
                                      </IconButton>
                                    </Tooltip>
                                  </Typography>
                                  <TextField
                                      size="small"
                                      select
                                      variant="outlined"
                                      label="Select"
                                      sx={{ width: "100%" }}
                                      style={{ paddingBottom: 8 }}
                                      onChange={(ev) =>
                                          onChangeDPOrder(
                                              ev.target.value,
                                              "preference",
                                              idx
                                          )
                                      }
                                      value={
                                        configTabSelector.delivery_partner &&
                                        configTabSelector.delivery_partner[idx]
                                            ? configTabSelector.delivery_partner[idx]
                                                .preference
                                            : ""
                                      }
                                      disabled={
                                        !configService.isDeliveryPartnerEdit(
                                            user.permissions
                                        )
                                      }
                                      error={
                                          configTabSelector.delivery_partner &&
                                          configTabSelector.delivery_partner[idx] &&
                                          configTabSelector.delivery_partner[idx].id !== "" &&
                                          configTabSelector.delivery_partner[idx].preference === ""
                                      }
                                      helperText={
                                        configTabSelector.delivery_partner &&
                                        configTabSelector.delivery_partner[idx] &&
                                        configTabSelector.delivery_partner[idx].id !== "" &&
                                        configTabSelector.delivery_partner[idx].preference === "" ? "This field is required" : ""
                                      }
                                  >
                                    {deliveryPartnerDropDownUI.map((_, idx) => (
                                        <MenuItem value={idx + 1}>{idx + 1}</MenuItem>
                                    ))}
                                  </TextField>
                                </Grid>
                                <Grid item xs={5}>
                                  <Typography>
                                    Delivery Partner{" "}
                                    <IconButton sx={{ padding: 2 }}></IconButton>
                                  </Typography>
                                  <TextField
                                      size="small"
                                      select
                                      variant="outlined"
                                      label="Select"
                                      sx={{ width: "100%" }}
                                      style={{ paddingBottom: 8 }}
                                      onChange={(ev) =>
                                          onChangeDPOrder(ev.target.value, "id", idx)
                                      }
                                      value={
                                        configTabSelector.delivery_partner &&
                                        configTabSelector.delivery_partner[idx]
                                            ? configTabSelector.delivery_partner[idx].id
                                            : ""
                                      }
                                      disabled={
                                        !configService.isDeliveryPartnerEdit(
                                            user.permissions
                                        )
                                      }
                                  >
                                    {configDropdownSelector.deliveryPartnersList.map(
                                        (list) => (
                                            <MenuItem value={list.id}>
                                              {list.name}
                                            </MenuItem>
                                        )
                                    )}
                                  </TextField>
                                </Grid>
                              </Grid>
                            </Grid>

                            <Grid item xs={4}>
                              <Grid container alignItems={"flex-end"}>
                                {configTabSelector.is_delivery_partner_auto_assignment_enabled ===
                                    "true" && configTabSelector.is_dp_enabled === "true" && (
                                        <Grid item xs={10}>
                                          <Typography>
                                            Switching Time
                                            <Tooltip title="This indicates the duration the system will wait for a response from the delivery partner before moving on to the next one">
                                              <IconButton sx={{ padding: 0.5 }}>
                                                <InfoIcon />
                                              </IconButton>
                                            </Tooltip>
                                          </Typography>
                                          <TextField
                                              size="small"
                                              variant="outlined"
                                              label="Enter"
                                              type="number"
                                              disabled={
                                                !configService.isDeliveryPartnerEdit(
                                                    user.permissions
                                                )
                                              }
                                              value={
                                                configTabSelector.delivery_partner &&
                                                configTabSelector.delivery_partner[idx]
                                                    ? configTabSelector.delivery_partner[idx]
                                                        .switching_time
                                                    : ""
                                              }
                                              onChange={(ev) =>
                                                  onChangeDPOrder(
                                                      ev.target.value,
                                                      "switching_time",
                                                      idx
                                                  )
                                              }
                                              InputProps={{
                                                inputProps: {
                                                  max: 100,
                                                  min: 0,
                                                },
                                                endAdornment: (
                                                    <InputAdornment end>mins</InputAdornment>
                                                ),
                                              }}
                                              error={
                                                  configTabSelector.delivery_partner &&
                                                  configTabSelector.delivery_partner[idx] &&
                                                  configTabSelector.delivery_partner[idx].id !== "" &&
                                                  configTabSelector.delivery_partner[idx].switching_time === ""
                                              }
                                              helperText={
                                                configTabSelector.delivery_partner &&
                                                configTabSelector.delivery_partner[idx] &&
                                                configTabSelector.delivery_partner[idx].id !== "" &&
                                                configTabSelector.delivery_partner[idx].switching_time === "" ? "This field is required" : ""
                                              }

                                          />
                                        </Grid>
                                    )}
                                <Grid item xs={2}>
                                  {(configDropdownSelector.deliveryPartnersList
                                          .length !== deliveryPartnerDropDownUI.length &&
                                      deliveryPartnerDropDownUI.length === idx + 1) ||
                                  deliveryPartnerDropDownUI.length === 1 ? (
                                      <IconButton
                                          disabled={
                                            !configService.isDeliveryPartnerEdit(
                                                user.permissions
                                            )
                                          }
                                          sx={{
                                            float: "right",
                                          }}
                                          onClick={() => addDeliveryPartnerDropDown()}
                                      >
                                        <AddIcon />
                                      </IconButton>
                                  ) : (
                                      <IconButton
                                          disabled={
                                            !configService.isDeliveryPartnerEdit(
                                                user.permissions
                                            )
                                          }
                                          onClick={() =>
                                              removeDeliveryPartnerDropDown(list, idx)
                                          }
                                          sx={{
                                            marginLeft: "-20px",
                                            float: "right",
                                          }}
                                      >
                                        <CloseIcon />
                                      </IconButton>
                                  )}
                                </Grid>
                              </Grid>
                            </Grid>
                          </Grid>
                        </div>
                    ))}
                  </Grid>
              )}

              {/* Customization Section */}
              { poiListSelector.viewType === "multiEdit" && configTabSelector.is_dp_enabled === "true" && configTabSelector.multiEditDp.edit_delivery_partner_sections.checked === "customization" && (
                  <Grid item xs={12}>
                    <div style={{ marginBottom: 10, marginLeft: 50, marginRight: 30 }}>
                      {/* Add Delivery Partners */}
                        <Grid container spacing={1} sx={{marginBottom: 2}} alignItems={"flex-center"}>
                        <Grid item xs={12} display={"flex"}>
                              <FormControlLabel control={
                                <Checkbox
                                    checked={
                                      configTabSelector.multiEditDp.add_delivery_partner.checked
                                    }
                                    onChange={(ev) => {
                                      const value = ev.target.checked;
                                      handleMultiEditDPChange(
                                          "add_delivery_partner",
                                          value
                                      );
                                    }}
                                    sx={{ paddingLeft: 0 }}
                                    label = ""
                                />
                              }
                              label="Add Delivery Partner" />
                        </Grid>

                        {configTabSelector.is_dp_enabled === "true" && configTabSelector.multiEditDp.add_delivery_partner.checked === true &&(
                            <Grid item xs={12} display={"flex"}>
                              {poiListSelector.viewType === "multiEdit" && (
                                  <Grid item xs={12}>
                                    {deliveryPartnerDropDownList.new_dps.map((list, idx) => (
                                        <div style={{ marginBottom: 10, marginLeft: 30, marginRight: 30 }} key={idx}>
                                          <Grid container spacing={3} alignItems={"flex-center"}>
                                            <Grid item xs={12}>
                                              <Grid container spacing={3} >
                                                <Grid item xs={4}>
                                                  <Typography>
                                                    Priority
                                                    <Tooltip title="This field is to select the priority level that the system should consider when displaying or auto-assigning delivery partners.">
                                                      <IconButton sx={{ padding: 0.5 }}>
                                                        <InfoIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </Typography>
                                                  <TextField
                                                      size="small"
                                                      select
                                                      variant="outlined"
                                                      label="Select"
                                                      sx={{ width: "100%" }}
                                                      style={{ paddingBottom: 8 }}
                                                      onChange={(ev) =>
                                                          onChangeDP(
                                                              ev.target.value,
                                                              "preference",
                                                              idx,
                                                              "new"
                                                          )
                                                      }
                                                      value={
                                                        configTabSelector.delivery_partners_customization &&
                                                        configTabSelector.delivery_partners_customization.new_dps &&
                                                        configTabSelector.delivery_partners_customization.new_dps[idx]
                                                            ? configTabSelector.delivery_partners_customization.new_dps[idx]
                                                                .preference
                                                            : ""
                                                      }
                                                      disabled={
                                                        !configService.isDeliveryPartnerEdit(
                                                            user.permissions
                                                        )
                                                      }
                                                      error={
                                                          configTabSelector.delivery_partners_customization &&
                                                          configTabSelector.delivery_partners_customization.new_dps &&
                                                          configTabSelector.delivery_partners_customization.new_dps[idx] &&
                                                          configTabSelector.delivery_partners_customization.new_dps[idx].id !== "" &&
                                                          configTabSelector.delivery_partners_customization.new_dps[idx].preference === ""
                                                      }
                                                      helperText={
                                                        configTabSelector.delivery_partners_customization &&
                                                        configTabSelector.delivery_partners_customization.new_dps &&
                                                        configTabSelector.delivery_partners_customization.new_dps[idx] &&
                                                        configTabSelector.delivery_partners_customization.new_dps[idx].id !== "" &&
                                                        configTabSelector.delivery_partners_customization.new_dps[idx].preference === "" ? "This field is required" : ""
                                                      }
                                                  >
                                                    {configDropdownSelector.deliveryPartnersList.map((_, idx) => (
                                                        <MenuItem value={idx + 1}>{idx + 1}</MenuItem>
                                                    ))}
                                                    <MenuItem value={configDropdownSelector.deliveryPartnersList.length + 1}>Last</MenuItem>
                                                  </TextField>
                                                </Grid>
                                                <Grid item xs={4}>
                                                  <Grid container alignItems={"flex-end"}>
                                                    <Grid item xs={10}>
                                                      <Typography>
                                                        Delivery Partner{" "}
                                                        <IconButton sx={{ padding: 2 }}></IconButton>
                                                      </Typography>
                                                      <TextField
                                                          size="small"
                                                          select
                                                          variant="outlined"
                                                          label="Select"
                                                          sx={{ width: "100%" }}
                                                          style={{ paddingBottom: 8 }}
                                                          onChange={(ev) =>
                                                              onChangeDP(ev.target.value, "id", idx, "new")
                                                          }
                                                          value={
                                                            configTabSelector.delivery_partners_customization &&
                                                            configTabSelector.delivery_partners_customization.new_dps &&
                                                            configTabSelector.delivery_partners_customization.new_dps[idx]
                                                                ? configTabSelector.delivery_partners_customization.new_dps[idx].id
                                                                : ""
                                                          }
                                                          disabled={
                                                            !configService.isDeliveryPartnerEdit(
                                                                user.permissions
                                                            )
                                                          }
                                                      >
                                                        {configDropdownSelector.deliveryPartnersList.map(
                                                            (list) => (
                                                                <MenuItem value={list.id}>
                                                                  {list.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                      </TextField>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                {configTabSelector.is_delivery_partner_auto_assignment_enabled ===
                                                    "true" && (
                                                        <Grid item xs={3}>
                                                          <Typography>
                                                            Switching Time
                                                            <Tooltip title="This indicates the duration the system will wait for a response from the delivery partner before moving on to the next one">
                                                              <IconButton sx={{ padding: 0.5 }}>
                                                                <InfoIcon />
                                                              </IconButton>
                                                            </Tooltip>
                                                          </Typography>
                                                          <TextField
                                                              size="small"
                                                              variant="outlined"
                                                              label="Enter"
                                                              type="number"
                                                              disabled={
                                                                !configService.isDeliveryPartnerEdit(
                                                                    user.permissions
                                                                )
                                                              }
                                                              value={
                                                                configTabSelector.delivery_partners_customization &&
                                                                configTabSelector.delivery_partners_customization.new_dps &&
                                                                configTabSelector.delivery_partners_customization.new_dps[idx]
                                                                    ? configTabSelector.delivery_partners_customization.new_dps[idx]
                                                                        .switching_time
                                                                    : ""
                                                              }
                                                              onChange={(ev) =>
                                                                  onChangeDP(
                                                                      ev.target.value,
                                                                      "switching_time",
                                                                      idx,
                                                                      "new"
                                                                  )
                                                              }
                                                              InputProps={{
                                                                inputProps: {
                                                                  max: 100,
                                                                  min: 0,
                                                                },
                                                                endAdornment: (
                                                                    <InputAdornment end>mins</InputAdornment>
                                                                ),
                                                              }}
                                                              error={
                                                                  configTabSelector.delivery_partners_customization &&
                                                                  configTabSelector.delivery_partners_customization.new_dps &&
                                                                  configTabSelector.delivery_partners_customization.new_dps[idx] &&
                                                                  configTabSelector.delivery_partners_customization.new_dps[idx].id !== "" &&
                                                                  configTabSelector.delivery_partners_customization.new_dps[idx].switching_time === ""
                                                              }
                                                              helperText={
                                                                configTabSelector.delivery_partners_customization &&
                                                                configTabSelector.delivery_partners_customization.new_dps &&
                                                                configTabSelector.delivery_partners_customization.new_dps[idx] &&
                                                                configTabSelector.delivery_partners_customization.new_dps[idx].id !== "" &&
                                                                configTabSelector.delivery_partners_customization.new_dps[idx].switching_time === "" ? "This field is required" : ""
                                                              }
                                                          />
                                                        </Grid>
                                                    )}
                                                <Grid item xs={1}>
                                                  {(configDropdownSelector.deliveryPartnersList
                                                          .length !== deliveryPartnerDropDownList.new_dps.length &&
                                                      deliveryPartnerDropDownList.new_dps.length === idx + 1) ||
                                                  deliveryPartnerDropDownList.new_dps.length === 1 ? (
                                                      <IconButton
                                                          disabled={
                                                            !configService.isDeliveryPartnerEdit(
                                                                user.permissions
                                                            )
                                                          }
                                                          sx={{
                                                            float: "right",
                                                            marginBottom: 1
                                                          }}
                                                          onClick={() => addDeliveryPartnerDropDownList("new")}
                                                      >
                                                        <AddIcon />
                                                      </IconButton>
                                                  ) : (
                                                      <IconButton
                                                          disabled={
                                                            !configService.isDeliveryPartnerEdit(
                                                                user.permissions
                                                            )
                                                          }
                                                          onClick={() =>
                                                              removeDeliveryPartnerDropDownList("new", idx)
                                                          }
                                                          sx={{
                                                            marginLeft: "-20px",
                                                            float: "right",
                                                            marginBottom: 1
                                                          }}
                                                      >
                                                        <CloseIcon />
                                                      </IconButton>
                                                  )}
                                                </Grid>
                                              </Grid>
                                            </Grid>
                                          </Grid>
                                        </div>
                                    ))}
                                  </Grid>
                              )}
                            </Grid>
                        )}
                      </Grid>

                      {/* Delete Delivery Partners */}
                        <Grid container spacing={1} sx={{marginBottom: 2}} alignItems={"flex-center"}>
                          <Grid item xs={12} display={"flex"}>
                            {poiListSelector.viewType === "multiEdit" && (
                                <FormControlLabel control={
                                  <Checkbox
                                      checked={
                                        configTabSelector.multiEditDp.delete_delivery_partner.checked
                                      }
                                      onChange={(ev) => {
                                        const value = ev.target.checked;
                                        handleMultiEditDPChange(
                                            "delete_delivery_partner",
                                            value
                                        );
                                      }}
                                      sx={{ paddingLeft: 0 }}
                                  />
                                }
                                 label="Delete Delivery Partner" />
                            )}
                          </Grid>

                          {configTabSelector.is_dp_enabled === "true" && configTabSelector.multiEditDp.delete_delivery_partner.checked === true &&(
                              <Grid item xs={12} display={"flex"}>
                                {poiListSelector.viewType === "multiEdit" && (
                                    <Grid item xs={12}>
                                      {deliveryPartnerDropDownList.delete_dps.map((list, idx) => (
                                          <div style={{ marginBottom: 10, marginLeft: 30, marginRight: 30 }} key={idx}>
                                            <Grid container spacing={3} alignItems={"flex-center"}>
                                              <Grid item xs={8}>
                                                <Grid container spacing={3} >
                                                  <Grid item xs={6}>
                                                    <Grid container alignItems={"flex-end"}>
                                                      <Grid item xs={10}>
                                                        <Typography>
                                                          Delivery Partner{" "}
                                                          <IconButton sx={{ padding: 2 }}></IconButton>
                                                        </Typography>
                                                        <TextField
                                                            size="small"
                                                            select
                                                            variant="outlined"
                                                            label="Select"
                                                            sx={{ width: "100%" }}
                                                            style={{ paddingBottom: 8 }}
                                                            onChange={(ev) =>
                                                                onChangeDP(ev.target.value, "id", idx, "delete")
                                                            }
                                                            value={
                                                              configTabSelector.delivery_partners_customization &&
                                                              configTabSelector.delivery_partners_customization.delete_dps &&
                                                              configTabSelector.delivery_partners_customization.delete_dps[idx]
                                                                  ? configTabSelector.delivery_partners_customization.delete_dps[idx].id
                                                                  : ""
                                                            }
                                                            disabled={
                                                              !configService.isDeliveryPartnerEdit(
                                                                  user.permissions
                                                              )
                                                            }
                                                        >
                                                          {configDropdownSelector.deliveryPartnersList.map(
                                                              (list) => (
                                                                  <MenuItem value={list.id}>
                                                                    {list.name}
                                                                  </MenuItem>
                                                              )
                                                          )}
                                                        </TextField>
                                                      </Grid>
                                                      <Grid item xs={2}>
                                                        {(configDropdownSelector.deliveryPartnersList
                                                                .length !== deliveryPartnerDropDownList.delete_dps.length &&
                                                            deliveryPartnerDropDownList.delete_dps.length === idx + 1) ||
                                                        deliveryPartnerDropDownList.delete_dps.length === 1 ? (
                                                            <IconButton
                                                                disabled={
                                                                  !configService.isDeliveryPartnerEdit(
                                                                      user.permissions
                                                                  )
                                                                }
                                                                sx={{
                                                                  float: "right",
                                                                  marginBottom: 1
                                                                }}
                                                                onClick={() => addDeliveryPartnerDropDownList("delete")}
                                                            >
                                                              <AddIcon />
                                                            </IconButton>
                                                        ) : (
                                                            <IconButton
                                                                disabled={
                                                                  !configService.isDeliveryPartnerEdit(
                                                                      user.permissions
                                                                  )
                                                                }
                                                                onClick={() =>
                                                                    removeDeliveryPartnerDropDownList("delete", idx)
                                                                }
                                                                sx={{
                                                                  marginLeft: "-20px",
                                                                  float: "right",
                                                                  marginBottom: 1
                                                                }}
                                                            >
                                                              <CloseIcon />
                                                            </IconButton>
                                                        )}
                                                      </Grid>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>

                                            </Grid>
                                          </div>
                                      ))}
                                    </Grid>
                                )}
                              </Grid>
                          )}
                        </Grid>

                      {/* Update Delivery Partners Priority */}
                      <Grid container spacing={1} sx={{marginBottom: 2}} alignItems={"flex-center"}>
                        <Grid item xs={12} display={"flex"}>
                          {poiListSelector.viewType === "multiEdit" && (
                              <FormControlLabel control={
                                  <Checkbox
                                      checked={
                                        configTabSelector.multiEditDp.update_delivery_partner_priority.checked
                                      }
                                      onChange={(ev) => {
                                        const value = ev.target.checked;
                                        handleMultiEditDPChange(
                                            "update_delivery_partner_priority",
                                            value
                                        );
                                      }}
                                      sx={{ paddingLeft: 0 }}
                                  />
                                }
                                label="Change Delivery Partner Priority"
                              />
                          )}
                        </Grid>

                        {configTabSelector.is_dp_enabled === "true" && configTabSelector.multiEditDp.update_delivery_partner_priority.checked === true &&(
                            <Grid item xs={12} display={"flex"}>
                              {poiListSelector.viewType === "multiEdit" && (
                                  <Grid item xs={12}>
                                    {deliveryPartnerDropDownList.change_priority_dps.map((list, idx) => (
                                        <div style={{ marginBottom: 10, marginLeft: 30, marginRight: 30 }} key={idx}>
                                          <Grid container spacing={3} alignItems={"flex-center"}>
                                            <Grid item xs={8}>
                                              <Grid container spacing={3} >
                                                <Grid item xs={4}>
                                                  <Typography>
                                                    Priority
                                                    <Tooltip title="This field is to select the priority level that the system should consider when displaying or auto-assigning delivery partners.">
                                                      <IconButton sx={{ padding: 0.5 }}>
                                                        <InfoIcon />
                                                      </IconButton>
                                                    </Tooltip>
                                                  </Typography>
                                                  <TextField
                                                      size="small"
                                                      select
                                                      variant="outlined"
                                                      label="Select"
                                                      sx={{ width: "100%" }}
                                                      style={{ paddingBottom: 8 }}
                                                      onChange={(ev) =>
                                                          onChangeDP(
                                                              ev.target.value,
                                                              "preference",
                                                              idx,
                                                              "change_priority"
                                                          )
                                                      }
                                                      value={
                                                        configTabSelector.delivery_partners_customization &&
                                                        configTabSelector.delivery_partners_customization.change_priority_dps &&
                                                        configTabSelector.delivery_partners_customization.change_priority_dps[idx]
                                                            ? configTabSelector.delivery_partners_customization.change_priority_dps[idx]
                                                                .preference
                                                            : ""
                                                      }
                                                      disabled={
                                                        !configService.isDeliveryPartnerEdit(
                                                            user.permissions
                                                        )
                                                      }
                                                      error={
                                                          configTabSelector.delivery_partners_customization &&
                                                          configTabSelector.delivery_partners_customization.change_priority_dps &&
                                                          configTabSelector.delivery_partners_customization.change_priority_dps[idx] &&
                                                          configTabSelector.delivery_partners_customization.change_priority_dps[idx].id !== "" &&
                                                          configTabSelector.delivery_partners_customization.change_priority_dps[idx].preference === ""
                                                      }
                                                      helperText={
                                                        configTabSelector.delivery_partners_customization &&
                                                        configTabSelector.delivery_partners_customization.change_priority_dps &&
                                                        configTabSelector.delivery_partners_customization.change_priority_dps[idx] &&
                                                        configTabSelector.delivery_partners_customization.change_priority_dps[idx].id !== "" &&
                                                        configTabSelector.delivery_partners_customization.change_priority_dps[idx].preference === "" ? "This field is required" : ""
                                                      }
                                                  >
                                                    {configDropdownSelector.deliveryPartnersList.map((_, idx) => (
                                                        <MenuItem value={idx + 1}>{idx + 1}</MenuItem>
                                                    ))}
                                                    <MenuItem value={configDropdownSelector.deliveryPartnersList.length + 1}>Last</MenuItem>
                                                  </TextField>
                                                </Grid>
                                                <Grid item xs={6}>
                                                  <Grid container alignItems={"flex-end"}>
                                                    <Grid item xs={10}>
                                                      <Typography>
                                                        Delivery Partner{" "}
                                                        <IconButton sx={{ padding: 2 }}></IconButton>
                                                      </Typography>
                                                      <TextField
                                                          size="small"
                                                          select
                                                          variant="outlined"
                                                          label="Select"
                                                          sx={{ width: "100%" }}
                                                          style={{ paddingBottom: 8 }}
                                                          onChange={(ev) =>
                                                              onChangeDP(ev.target.value, "id", idx, "change_priority")
                                                          }
                                                          value={
                                                            configTabSelector.delivery_partners_customization &&
                                                            configTabSelector.delivery_partners_customization.change_priority_dps &&
                                                            configTabSelector.delivery_partners_customization.change_priority_dps[idx]
                                                                ? configTabSelector.delivery_partners_customization.change_priority_dps[idx].id
                                                                : ""
                                                          }
                                                          disabled={
                                                            !configService.isDeliveryPartnerEdit(
                                                                user.permissions
                                                            )
                                                          }
                                                      >
                                                        {configDropdownSelector.deliveryPartnersList.map(
                                                            (list) => (
                                                                <MenuItem value={list.id}>
                                                                  {list.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                      </TextField>
                                                    </Grid>
                                                    <Grid item xs={2}>
                                                      {(configDropdownSelector.deliveryPartnersList
                                                              .length !== deliveryPartnerDropDownList.change_priority_dps.length &&
                                                          deliveryPartnerDropDownList.change_priority_dps.length === idx + 1) ||
                                                      deliveryPartnerDropDownList.change_priority_dps.length === 1 ? (
                                                          <IconButton
                                                              disabled={
                                                                !configService.isDeliveryPartnerEdit(
                                                                    user.permissions
                                                                )
                                                              }
                                                              sx={{
                                                                float: "right",
                                                                marginBottom: 1
                                                              }}
                                                              onClick={() => addDeliveryPartnerDropDownList("change_priority")}
                                                          >
                                                            <AddIcon />
                                                          </IconButton>
                                                      ) : (
                                                          <IconButton
                                                              disabled={
                                                                !configService.isDeliveryPartnerEdit(
                                                                    user.permissions
                                                                )
                                                              }
                                                              onClick={() =>
                                                                  removeDeliveryPartnerDropDownList("change_priority", idx)
                                                              }
                                                              sx={{
                                                                marginLeft: "-20px",
                                                                float: "right",
                                                                marginBottom: 1
                                                              }}
                                                          >
                                                            <CloseIcon />
                                                          </IconButton>
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                          </Grid>
                                        </div>
                                    ))}
                                  </Grid>
                              )}
                            </Grid>
                        )}
                      </Grid>

                      {/* Update Delivery Partners Switching Time */}
                      <Grid container spacing={1} sx={{marginBottom: 2}} alignItems={"flex-center"}>
                        <Grid item xs={12} display={"flex"}>
                          {poiListSelector.viewType === "multiEdit" && (
                              <FormControlLabel control={
                                <Checkbox
                                    checked={
                                      configTabSelector.multiEditDp.update_delivery_partner_switching_time.checked
                                    }
                                    onChange={(ev) => {
                                      const value = ev.target.checked;
                                      handleMultiEditDPChange(
                                          "update_delivery_partner_switching_time",
                                          value
                                      );
                                    }}
                                    sx={{ paddingLeft: 0 }}
                                />
                              }
                              label="Change Delivery Partner Switching Time"
                              />
                          )}
                        </Grid>

                        {configTabSelector.is_dp_enabled === "true" && configTabSelector.multiEditDp.update_delivery_partner_switching_time.checked === true &&(
                            <Grid item xs={12} display={"flex"}>
                              {poiListSelector.viewType === "multiEdit" && (
                                  <Grid item xs={12}>
                                    {deliveryPartnerDropDownList.update_dps.map((list, idx) => (
                                        <div style={{ marginBottom: 10, marginLeft: 30, marginRight: 30 }} key={idx}>
                                          <Grid container spacing={3} alignItems={"flex-center"}>
                                            <Grid item xs={12}>
                                              <Grid container spacing={3} >
                                                <Grid item xs={4}>
                                                  <Grid container alignItems={"flex-end"}>
                                                    <Grid item xs={10}>
                                                      <Typography>
                                                        Delivery Partner{" "}
                                                        <IconButton sx={{ padding: 2 }}></IconButton>
                                                      </Typography>
                                                      <TextField
                                                          size="small"
                                                          select
                                                          variant="outlined"
                                                          label="Select"
                                                          sx={{ width: "100%" }}
                                                          style={{ paddingBottom: 8 }}
                                                          onChange={(ev) =>
                                                              onChangeDP(ev.target.value, "id", idx, "update")
                                                          }
                                                          value={
                                                            configTabSelector.delivery_partners_customization &&
                                                            configTabSelector.delivery_partners_customization.update_dps &&
                                                            configTabSelector.delivery_partners_customization.update_dps[idx]
                                                                ? configTabSelector.delivery_partners_customization.update_dps[idx].id
                                                                : ""
                                                          }
                                                          disabled={
                                                            !configService.isDeliveryPartnerEdit(
                                                                user.permissions
                                                            )
                                                          }
                                                      >
                                                        {configDropdownSelector.deliveryPartnersList.map(
                                                            (list) => (
                                                                <MenuItem value={list.id}>
                                                                  {list.name}
                                                                </MenuItem>
                                                            )
                                                        )}
                                                      </TextField>
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                                <Grid item xs={4}>
                                                  <Grid container alignItems={"flex-end"}>
                                                    {configTabSelector.is_delivery_partner_auto_assignment_enabled ===
                                                        "true" && (
                                                            <Grid item xs={10}>
                                                              <Typography>
                                                                Switching Time
                                                                <Tooltip title="This indicates the duration the system will wait for a response from the delivery partner before moving on to the next one">
                                                                  <IconButton sx={{ padding: 0.5 }}>
                                                                    <InfoIcon />
                                                                  </IconButton>
                                                                </Tooltip>
                                                              </Typography>
                                                              <TextField
                                                                  size="small"
                                                                  variant="outlined"
                                                                  label="Enter"
                                                                  type="number"
                                                                  disabled={
                                                                    !configService.isDeliveryPartnerEdit(
                                                                        user.permissions
                                                                    )
                                                                  }
                                                                  value={
                                                                    configTabSelector.delivery_partners_customization &&
                                                                    configTabSelector.delivery_partners_customization.update_dps &&
                                                                    configTabSelector.delivery_partners_customization.update_dps[idx]
                                                                        ? configTabSelector.delivery_partners_customization.update_dps[idx]
                                                                            .switching_time
                                                                        : ""
                                                                  }
                                                                  onChange={(ev) =>
                                                                      onChangeDP(
                                                                          ev.target.value,
                                                                          "switching_time",
                                                                          idx,
                                                                          "update"
                                                                      )
                                                                  }
                                                                  InputProps={{
                                                                    inputProps: {
                                                                      max: 100,
                                                                      min: 0,
                                                                    },
                                                                    endAdornment: (
                                                                        <InputAdornment end>mins</InputAdornment>
                                                                    ),
                                                                  }}
                                                                  error={
                                                                      configTabSelector.delivery_partners_customization &&
                                                                      configTabSelector.delivery_partners_customization.update_dps &&
                                                                      configTabSelector.delivery_partners_customization.update_dps[idx] &&
                                                                      configTabSelector.delivery_partners_customization.update_dps[idx].id !== "" &&
                                                                      configTabSelector.delivery_partners_customization.update_dps[idx].switching_time === ""
                                                                  }
                                                                  helperText={
                                                                    configTabSelector.delivery_partners_customization &&
                                                                    configTabSelector.delivery_partners_customization.update_dps &&
                                                                    configTabSelector.delivery_partners_customization.update_dps[idx] &&
                                                                    configTabSelector.delivery_partners_customization.update_dps[idx].id !== "" &&
                                                                    configTabSelector.delivery_partners_customization.update_dps[idx].switching_time === "" ? "This field is required" : ""
                                                                  }
                                                              />
                                                            </Grid>
                                                        )}
                                                    <Grid item xs={2}>
                                                      {(configDropdownSelector.deliveryPartnersList
                                                              .length !== deliveryPartnerDropDownList.update_dps.length &&
                                                          deliveryPartnerDropDownList.update_dps.length === idx + 1) ||
                                                      deliveryPartnerDropDownList.update_dps.length === 1 ? (
                                                          <IconButton
                                                              disabled={
                                                                !configService.isDeliveryPartnerEdit(
                                                                    user.permissions
                                                                )
                                                              }
                                                              sx={{
                                                                float: "right",
                                                              }}
                                                              onClick={() => addDeliveryPartnerDropDownList("update")}
                                                          >
                                                            <AddIcon />
                                                          </IconButton>
                                                      ) : (
                                                          <IconButton
                                                              disabled={
                                                                !configService.isDeliveryPartnerEdit(
                                                                    user.permissions
                                                                )
                                                              }
                                                              onClick={() =>
                                                                  removeDeliveryPartnerDropDownList("update", idx)
                                                              }
                                                              sx={{
                                                                marginLeft: "-20px",
                                                                float: "right",
                                                              }}
                                                          >
                                                            <CloseIcon />
                                                          </IconButton>
                                                      )}
                                                    </Grid>
                                                  </Grid>
                                                </Grid>
                                              </Grid>
                                            </Grid>

                                          </Grid>
                                        </div>
                                    ))}
                                  </Grid>
                              )}
                            </Grid>
                        )}
                      </Grid>

                    </div>
                  </Grid>
              )}

              {/* <Grid item xs={8}>
                <Typography>
                  DOD request indication time
                  <Tooltip title="Enter target time in minutes for DOD (Driver on Demand) request indication time. It is the time from SOS (Speed of Service) until order assignment to a home fleet driver.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  sx={{ width: "100%" }}
                  size="small"
                  type="number"
                  value={configTabSelector.dod_request_time || ""}
                  InputProps={{
                    inputProps: {
                      max: 100,
                      min: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="end"> mins</InputAdornment>
                    ),
                  }}
                  label="Enter"
                  onChange={(ev) =>
                    handleInputChange(ev.target.value, "dod_request_time")
                  }
                  disabled={
                    !user.permissions.poi_editPoiConfiguration ||
                    clientConfigsSelector.dod_request_time == ""
                  }
                />
              </Grid> */}

              {/* <Grid item xs={8}>
                <Typography>
                  DOD withdrawal indication time
                  <Tooltip title="Enter target time in minutes for DOD (Driver on Demand) request withdrawal time. It is the time from DOD requested time until order assignment to a delivery partner driver.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  sx={{ width: "100%" }}
                  size="small"
                  type="number"
                  value={configTabSelector.dod_withdrawal || ""}
                  InputProps={{
                    inputProps: {
                      max: 100,
                      min: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="end"> mins</InputAdornment>
                    ),
                  }}
                  label="Enter"
                  onChange={(ev) =>
                    handleInputChange(ev.target.value, "dod_withdrawal")
                  }
                  disabled={
                    !user.permissions.poi_editPoiConfiguration ||
                    clientConfigsSelector.dod_withdrawal == ""
                  }
                />
              </Grid> */}
              {configTabSelector.is_delivery_partner_auto_assignment_enabled ===
                "true" && configTabSelector.is_dp_enabled === "true" && (
                <>
                  <Grid item xs={8} display={"flex"}>
                    {poiListSelector.viewType === "multiEdit" && (
                      <Checkbox
                        checked={
                          configTabSelector.multiEditDp.dod_request_time.checked
                        }
                        onChange={(ev) => {
                          const value = ev.target.checked;

                          handleMultiEditDPChange("dod_request_time", value);
                        }}
                        sx={{ paddingLeft: 0 }}
                      />
                    )}
                    <Typography>
                      Internal Driver Search Timeout
                      <Tooltip title="This specifies the time duration for the system to search for an available internal driver. If no driver is found within this time, the order will be automatically sent to delivery partners for fulfillment">
                        <IconButton>
                          <InfoIcon />
                        </IconButton>
                      </Tooltip>
                    </Typography>
                  </Grid>
                  <Grid item xs={4}>
                    <TextField
                      sx={{ width: "100%" }}
                      size="small"
                      type="number"
                      value={configTabSelector.dod_request_time || ""}
                      InputProps={{
                        inputProps: {
                          max: 100,
                          min: 0,
                        },
                        endAdornment: (
                          <InputAdornment position="end"> mins</InputAdornment>
                        ),
                      }}
                      label="Enter"
                      onChange={(ev) => {
                        handleInputChange(ev.target.value, "dod_request_time");
                      }}
                      disabled={
                        !configService.isDeliveryPartnerEdit(user.permissions)
                      }
                      error={
                          configTabSelector.is_delivery_partner_auto_assignment_enabled &&
                          configTabSelector.is_delivery_partner_auto_assignment_enabled === "true" &&
                          (!configTabSelector.dod_request_time || configTabSelector.dod_request_time === "")

                      }
                      helperText={
                        configTabSelector.is_delivery_partner_auto_assignment_enabled &&
                        configTabSelector.is_delivery_partner_auto_assignment_enabled === "true" &&
                        (!configTabSelector.dod_request_time || configTabSelector.dod_request_time === "") ? "This field is required" : ""
                      }
                    />
                  </Grid>
                </>
              )}

              <Grid item xs={8} display={"flex"}>
                {poiListSelector.viewType === "multiEdit" && (
                  <Checkbox
                    checked={
                      configTabSelector.multiEditDp.target_dod_arrival_time
                        .checked
                    }
                    onChange={(ev) => {
                      const value = ev.target.checked;
                      handleMultiEditDPChange("target_dod_arrival_time", value);
                    }}
                    sx={{ paddingLeft: 0 }}
                  />
                )}
                <Typography>
                  Target arrival to POI (DOD)
                  <Tooltip title="Enter target time in minutes for arrival to POI time for DOD (Driver on Demand) orders. It is the time from order assignment until driver arrives at the POI for pickup.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  sx={{ width: "100%" }}
                  size="small"
                  type="number"
                  value={configTabSelector.target_dod_arrival_time || ""}
                  InputProps={{
                    inputProps: {
                      max: 100,
                      min: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="end"> mins</InputAdornment>
                    ),
                  }}
                  label="Enter"
                  onChange={(ev) => {
                    handleInputChange(
                      ev.target.value,
                      "target_dod_arrival_time"
                    );
                  }}
                  disabled={
                    !configService.isDeliveryPartnerEdit(user.permissions)
                  }
                />
              </Grid>

              <Grid item xs={8} display={"flex"}>
                {poiListSelector.viewType === "multiEdit" && (
                  <Checkbox
                    checked={
                      configTabSelector.multiEditDp.dod_target_assign.checked
                    }
                    onChange={(ev) => {
                      const value = ev.target.checked;
                      handleMultiEditDPChange("dod_target_assign", value);
                    }}
                    sx={{ paddingLeft: 0 }}
                  />
                )}
                <Typography>
                  Target assign (DOD)
                  <Tooltip title="Enter target time in minutes for the assignment for DOD (Driver on Demand) orders. It is the time from assignement trigger until order assignment for DOD orders.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  sx={{ width: "100%" }}
                  size="small"
                  type="number"
                  value={configTabSelector.dod_target_assign || ""}
                  InputProps={{
                    inputProps: {
                      max: 100,
                      min: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="end"> mins</InputAdornment>
                    ),
                  }}
                  label="Enter"
                  onChange={(ev) => {
                    handleInputChange(ev.target.value, "dod_target_assign");
                  }}
                  disabled={
                    !configService.isDeliveryPartnerEdit(user.permissions) ||
                    clientConfigsSelector.dod_target_assign == "false"
                  }
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion>
        {/* <Accordion
          expanded={expanded === "panel6"}
          onChange={handleChange("panel6")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel6a-content"
            id="panel6a-header"
            sx={{
              backgroundColor: "#ccc",
            }}
          >
            <Typography>Driver Application</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography>
                  Show drive distance on Driver App
                  <Tooltip title="Planned is the system calculated drive distance. Actual is the drive distance of the driver.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormGroup>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={configTabSelector.distance_app.PLANNED}
                        onChange={(ev) =>
                          onMultiCheckBoxChange(ev, "distance_app", "PLANNED")
                        }
                        disabled={
                          !user.permissions.poi_editPoiConfiguration ||
                          clientConfigsSelector.distance_app == ""
                        }
                      />
                    }
                    label="Planned KMs"
                  />
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={configTabSelector.distance_app.ACTUAL}
                        onChange={(ev) =>
                          onMultiCheckBoxChange(ev, "distance_app", "ACTUAL")
                        }
                        disabled={
                          !user.permissions.poi_editPoiConfiguration ||
                          clientConfigsSelector.distance_app == ""
                        }
                      />
                    }
                    label="Actual KMs"
                  />
                </FormGroup>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion
          expanded={expanded === "panel7"}
          onChange={handleChange("panel7")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel7a-content"
            id="panel7a-header"
            sx={{
              backgroundColor: "#ccc",
            }}
          >
            <Typography>ETA</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                {" "}
                <Typography
                  sx={{
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  For Assignment
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Driver App
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  ETA & drive distance: Driver to reach POI (Order broadcast
                  message)
                  <Tooltip title="When selected, estimated time and drive distance of the driver to reach POI will be shown in the Order Broadcast Message of the Driver App.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.eta_for_broadcast == "true" || false
                      }
                      value={configTabSelector.eta_for_broadcast}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "eta_for_broadcast"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.eta_for_broadcast == "false"
                      }
                    />
                  }
                  label=""
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Map TV
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  ETA: Free driver to reach POI (Driver queue)
                  <Tooltip title="When selected, estimated time of the free driver to reach POI will be shown in the Driver Queue of the Map TV.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.is_show_waiting_eta == "true" || false
                      }
                      value={configTabSelector.is_show_waiting_eta}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "is_show_waiting_eta"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.is_show_waiting_eta == "false"
                      }
                    />
                  }
                  label=""
                />
              </Grid>

              <Grid item xs={8}>
                <Typography>
                  ETA: Returning driver to reach POI (Driver queue)
                  <Tooltip title="When selected, estimated time of the returning driver to reach POI will be shown in the Driver Queue of the Map TV.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.is_show_return_eta == "true" || false
                      }
                      value={configTabSelector.is_show_return_eta}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "is_show_return_eta"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.is_show_return_eta == "false"
                      }
                    />
                  }
                  label=""
                />
              </Grid>

              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  For Pickup
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Map TV
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  ETA: Assigned driver to reach POI (Driver queue)
                  <Tooltip title="When selected, estimated time of the assigned driver to reach POI will be shown in the Driver Queue of the Map TV.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.is_show_reach_eta == "true" || false
                      }
                      value={configTabSelector.is_show_reach_eta}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "is_show_reach_eta"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.is_show_reach_eta == "false"
                      }
                    />
                  }
                  label=""
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Order Grid
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  ETA: Driver to reach POI for all orders except DOD orders
                  <Tooltip title="When selected, estimated time of the returning driver to reach POI will be shown in the Driver Queue of the Map TV.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.orders_reach_eta == "true" || false
                      }
                      value={configTabSelector.orders_reach_eta}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "orders_reach_eta"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.orders_reach_eta == "false"
                      }
                    />
                  }
                  label=""
                />
              </Grid>

              <Grid item xs={8}>
                <Typography>
                  ETA: Driver to reach POI for DOD orders
                  <Tooltip title="When selected, estimated time of the assigned driver to reach POI will be shown in the Order Grid.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.orders_reach_dod_eta == "true" ||
                        false
                      }
                      value={configTabSelector.orders_reach_dod_eta}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "orders_reach_dod_eta"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.orders_reach_dod_eta == "false"
                      }
                    />
                  }
                  label=""
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  For Delivery
                </Typography>
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Customer View
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  ETA & route: Driver to reach customer location
                  <Tooltip title="When selected, estimated time and route of the driver to reach customer location will be shown in the Customer View.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.eta_tracking_url == "true" || false
                      }
                      value={configTabSelector.eta_tracking_url}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "eta_tracking_url"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.eta_tracking_url == "false"
                      }
                    />
                  }
                  label=""
                />
              </Grid>
              <Grid item xs={12}>
                <Typography
                  sx={{
                    fontWeight: "500",
                    marginBottom: "10px",
                  }}
                >
                  Order Grid
                </Typography>
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  ETA & Route: POI to customer location
                  <Tooltip title="When selected, estimated time and route from POI to customer location will be shown in the Customer View.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.route_tracking_url == "true" || false
                      }
                      value={configTabSelector.route_tracking_url}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "route_tracking_url"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.route_tracking_url == "false"
                      }
                    />
                  }
                  label=""
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion> */}
        {!configService.isDeliveryPartnerView(user.permissions) && (
          <Accordion
            expanded={expanded === "panel8"}
            onChange={handleChange("panel8")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel8a-content"
              id="panel8a-header"
              sx={{
                backgroundColor: "#ccc",
              }}
            >
              <Typography>Order Assignment</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {/* <Grid item xs={8}>
                <Typography>
                  Scan orders through Drive App
                  <Tooltip title="When selected, drivers will be able assign the orders themselves by scanning the barcode of the order.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.is_scan_orders == "true" || false
                      }
                      value={configTabSelector.is_scan_orders}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "is_scan_orders"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.is_scan_orders == "false"
                      }
                    />
                  }
                  label=""
                />
              </Grid> */}

                <Grid item xs={8}>
                  <Typography>
                    Assignment radius
                    <Tooltip title="Enter the radius in meters for checking if the driver (dedicated) is within this radius for auto assignment.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    sx={{ width: "100%" }}
                    size="small"
                    type="number"
                    value={configTabSelector.radius || ""}
                    InputProps={{
                      inputProps: {
                        min: 0,
                      },
                      endAdornment: (
                        <InputAdornment position="end"> mtrs</InputAdornment>
                      ),
                    }}
                    label="Enter"
                    onChange={(ev) =>
                      handleInputChange(ev.target.value, "radius")
                    }
                    disabled={!user.permissions.poi_editPoiConfiguration}
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {!configService.isDeliveryPartnerView(user.permissions) && (
          <Accordion
            expanded={expanded === "panel9"}
            onChange={handleChange("panel9")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel9a-content"
              id="panel9a-header"
              sx={{
                backgroundColor: "#ccc",
              }}
            >
              <Typography>Order Flow</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography>
                    Arrived for pickup check
                    <Tooltip title="Select an option for checking if driver has arrived for pickup (AFP). Geofence checks if driver is within the AFP radius and QR Code checks if driver has scanned the right QR code for the POI along with the geofence check.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <RadioGroup
                    aria-labelledby="demo-radio-buttons-group-label"
                    defaultValue="female"
                    name="radio-buttons-group"
                  >
                    <FormControlLabel
                      control={
                        <Radio
                          checked={
                            configTabSelector.arrived_for_pickup_check === "" ||
                            configTabSelector.arrived_for_pickup_check ===
                              null ||
                            (configTabSelector.arrived_for_pickup_check &&
                              configTabSelector.arrived_for_pickup_check.toLowerCase() ===
                                "none")
                          }
                          value={configTabSelector.arrived_for_pickup_check}
                          onChange={() =>
                            handleInputChange("", "arrived_for_pickup_check")
                          }
                          disabled={
                            !user.permissions.poi_editPoiConfiguration ||
                            clientConfigsSelector.arrived_for_pickup_check == ""
                          }
                        />
                      }
                      label="None"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={
                            configTabSelector.arrived_for_pickup_check ===
                              "QR" || false
                          }
                          value={configTabSelector.arrived_for_pickup_check}
                          onChange={() =>
                            handleInputChange("QR", "arrived_for_pickup_check")
                          }
                          disabled={
                            !user.permissions.poi_editPoiConfiguration ||
                            clientConfigsSelector.arrived_for_pickup_check == ""
                          }
                        />
                      }
                      label="QR Code"
                    />
                    <FormControlLabel
                      control={
                        <Radio
                          checked={
                            configTabSelector.arrived_for_pickup_check ===
                              "GEOFENCE" || false
                          }
                          value={configTabSelector.arrived_for_pickup_check}
                          onChange={() =>
                            handleInputChange(
                              "GEOFENCE",
                              "arrived_for_pickup_check"
                            )
                          }
                          disabled={
                            !user.permissions.poi_editPoiConfiguration ||
                            clientConfigsSelector.arrived_for_pickup_check == ""
                          }
                        />
                      }
                      label="Geofence"
                    />
                  </RadioGroup>
                </Grid>
                <Grid item xs={8}>
                  <Typography>
                    Arrived for pickup radius
                    <Tooltip title="Enter the radius in meters for checking if the driver is within this radius to mark as arrived for pickup.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <TextField
                    sx={{ width: "100%" }}
                    size="small"
                    type="number"
                    value={configTabSelector.arrived_for_pickup_radius || ""}
                    InputProps={{
                      inputProps: {
                        max: 100,
                        min: 0,
                      },
                      endAdornment: (
                        <InputAdornment position="end"> mtrs</InputAdornment>
                      ),
                    }}
                    label="Enter"
                    onChange={(ev) =>
                      handleInputChange(
                        ev.target.value,
                        "arrived_for_pickup_radius"
                      )
                    }
                    disabled={!user.permissions.poi_editPoiConfiguration}
                  />
                </Grid>

                {/* <Grid item xs={8}>
                <Typography>
                  Enable pickup after ready to pickup
                  <Tooltip title="When selected, driver is allowed to mark pickup only after ready to pickup event is received, which can be triggered using confirm view, make ready button of order grid or API.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.enable_pickup_after == "true" || false
                      }
                      value={configTabSelector.enable_pickup_after}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "enable_pickup_after"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.enable_pickup_after == ""
                      }
                    />
                  }
                  label=""
                />
              </Grid> */}

                <Grid item xs={8}>
                  <Typography>
                    Photo on pickup
                    <Tooltip title="When selected, taking photo will become mandatory while driver picks up an order.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={
                          configTabSelector.is_photo_on_pickup == "true" ||
                          false
                        }
                        value={configTabSelector.is_photo_on_pickup}
                        onChange={(ev) =>
                          handleInputChange(
                            ev.target.checked.toString(),
                            "is_photo_on_pickup"
                          )
                        }
                        disabled={!user.permissions.poi_editPoiConfiguration}
                      />
                    }
                    label=""
                  />
                </Grid>

                <Grid item xs={8}>
                  <Typography>
                    Photo on delivery
                    <Tooltip title="When selected, taking photo will become mandatory while driver delivers an order.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={configTabSelector.is_photo == "true" || false}
                        value={configTabSelector.is_photo}
                        onChange={(ev) =>
                          handleInputChange(
                            ev.target.checked.toString(),
                            "is_photo"
                          )
                        }
                        disabled={
                          !user.permissions.poi_editPoiConfiguration ||
                          clientConfigsSelector.is_photo == "false"
                        }
                      />
                    }
                    label=""
                  />
                </Grid>

                <Grid item xs={8}>
                  <Typography>
                    Photo of legal ID on delivery
                    <Tooltip title="When selected, taking photo of legal ID will become mandatory while driver delivers an order.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={
                          configTabSelector.is_emirateId == "true" || false
                        }
                        value={configTabSelector.is_emirateId}
                        onChange={(ev) =>
                          handleInputChange(
                            ev.target.checked.toString(),
                            "is_emirateId"
                          )
                        }
                        disabled={
                          !user.permissions.poi_editPoiConfiguration ||
                          clientConfigsSelector.is_emirateId == "false"
                        }
                      />
                    }
                    label=""
                  />
                </Grid>

                <Grid item xs={8}>
                  <Typography>
                    Signature on delivery
                    <Tooltip title="When selected, taking signature will become mandatory while driver delivers an order.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={
                          configTabSelector.is_signature == "true" || false
                        }
                        value={configTabSelector.is_signature}
                        onChange={(ev) =>
                          handleInputChange(
                            ev.target.checked.toString(),
                            "is_signature"
                          )
                        }
                        disabled={
                          !user.permissions.poi_editPoiConfiguration ||
                          clientConfigsSelector.is_signature == "false"
                        }
                      />
                    }
                    label=""
                  />
                </Grid>

                <Grid item xs={8}>
                  <Typography>
                    Enable partial delivery flow
                    <Tooltip title="When selected, partial delivery flow and associated statuses will be added to the Order Flow. Partial delivery per item as well as quantity is supported.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={configTabSelector.is_return == "true" || false}
                        value={configTabSelector.is_return}
                        onChange={(ev) =>
                          handleInputChange(
                            ev.target.checked.toString(),
                            "is_return"
                          )
                        }
                        disabled={
                          !user.permissions.poi_editPoiConfiguration ||
                          clientConfigsSelector.is_return == "false"
                        }
                      />
                    }
                    label=""
                  />
                </Grid>

                {configTabSelector.is_return === "true" && (
                    <>
                      <Grid item xs={8}>
                        <Typography>
                          Full Order Acceptance/Return
                          <Tooltip title="Enable this setting to prevent drivers from partially rejecting orders for stores with the E-commerce Returning Flow activated.">
                            <IconButton>
                              <InfoIcon />
                            </IconButton>
                          </Tooltip>
                        </Typography>
                      </Grid>
                      <Grid item xs={4}>
                        <FormControlLabel
                            control={
                              <Checkbox
                                  inputProps={{ "aria-label": "controlled" }}
                                  checked={configTabSelector.is_full_acceptance_or_return == "true" || false}
                                  value={configTabSelector.is_full_acceptance_or_return}
                                  onChange={(ev) =>
                                      handleInputChange(
                                          ev.target.checked.toString(),
                                          "is_full_acceptance_or_return"
                                      )
                                  }
                                  disabled={
                                      !user.permissions.poi_editPoiConfiguration ||
                                      clientConfigsSelector.is_full_acceptance_or_return == "false"
                                  }
                              />
                            }
                            label=""
                        />
                      </Grid>
                    </>
              )}

                {/* Mandatory Scanning for Pickup */}
                <Grid item xs={8}>
                  <Typography>
                    Mandatory Scanning for Pickup
                    <Tooltip title="Enable this option to require mandatory scanning at this location before pickup. Scanning is only enforced if scannable barcodes are provided by the client via the update order API. This configuration does not apply to return orders.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                      control={
                        <Checkbox
                            inputProps={{ "aria-label": "controlled" }}
                            checked={configTabSelector.is_mandatory_scanning_pickup == "true" || false}
                            value={configTabSelector.is_mandatory_scanning_pickup}
                            onChange={(ev) =>
                                handleInputChange(
                                    ev.target.checked.toString(),
                                    "is_mandatory_scanning_pickup"
                                )
                            }
                            disabled={
                                !user.permissions.poi_editPoiConfiguration ||
                                clientConfigsSelector.is_mandatory_scanning_pickup == "false"
                            }
                        />
                      }
                      label=""
                  />
                </Grid>

                {/* Mandatory Scanning for Delivery */}
                <Grid item xs={8}>
                  <Typography>
                    Mandatory Scanning for Delivery
                    <Tooltip title="Enable this option to require mandatory barcode scanning at the delivery step. Scanning is only enforced if scannable barcodes are provided by the client via the update order API.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                      control={
                        <Checkbox
                            inputProps={{ "aria-label": "controlled" }}
                            checked={configTabSelector.is_mandatory_scanning_delivery == "true" || false}
                            value={configTabSelector.is_mandatory_scanning_delivery}
                            onChange={(ev) =>
                                handleInputChange(
                                    ev.target.checked.toString(),
                                    "is_mandatory_scanning_delivery"
                                )
                            }
                            disabled={
                                !user.permissions.poi_editPoiConfiguration ||
                                clientConfigsSelector.is_mandatory_scanning_delivery == "false"
                            }
                        />
                      }
                      label=""
                  />
                </Grid>

              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {/* <Accordion
          expanded={expanded === "panel10"}
          onChange={handleChange("panel10")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel10a-content"
            id="panel10a-header"
            sx={{
              backgroundColor: "#ccc",
            }}
          >
            <Typography>Order Grid</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography>
                  Wait at POI time
                  <Tooltip title="When selected, wait time of the driver at POI will be shown in the Order Grid.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.is_show_waiting == "true" || false
                      }
                      value={configTabSelector.is_show_waiting}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "is_show_waiting"
                        )
                      }
                      disabled={!user.permissions.poi_editPoiConfiguration}
                    />
                  }
                  label=""
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion> */}

        {!configService.isDeliveryPartnerView(user.permissions) && (
          <Accordion
            expanded={expanded === "panel11"}
            onChange={handleChange("panel11")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel11a-content"
              id="panel11a-header"
              sx={{
                backgroundColor: "#ccc",
              }}
            >
              <Typography>Order Source Integration</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                {/* <Grid item xs={8}>
                <Typography>
                  Order partner
                  <Tooltip title="Select order partners. Order partners are the source of the orders (e.g. POS systems).">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Tooltip
                  title={
                    poiListSelector.viewType === "multiEdit" &&
                    "This configuration is disabled for multiple edit of POIs."
                  }
                >
                  <TextField
                    size="small"
                    select
                    variant="outlined"
                    label="Select"
                    sx={{ width: "100%" }}
                    style={{ paddingBottom: 8 }}
                    disabled={
                      poiListSelector.viewType === "multiEdit" ||
                      !user.permissions.poi_editPoiConfiguration ||
                      clientConfigsSelector.order_partner == ""
                    }
                    onChange={(ev) =>
                      onOrderPartnerChange(ev.target.value, "partner_type")
                    }
                    value={configTabSelector.order_partner.partner_type || ""}
                  >
                    {configDropdownSelector.orderPartnersList.map((list) => (
                      <MenuItem value={list.partner_type}>
                        {list.partner_name}
                      </MenuItem>
                    ))}
                  </TextField>
                </Tooltip>
              </Grid> */}

                <Grid item xs={8}>
                  <Typography>
                    Reference ID of order partner
                    <Tooltip title="Reference ID of the order partner used for integration.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <Tooltip
                    title={
                      poiListSelector.viewType === "multiEdit" &&
                      "This configuration is disabled for multiple edit of POIs."
                    }
                  >
                    <TextField
                      disabled={
                        !configDropdownSelector.orderPartnersList ||
                        configDropdownSelector.orderPartnersList.length ==
                          "0" ||
                        poiListSelector.viewType === "multiEdit" ||
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.order_partner == ""
                      }
                      sx={{ width: "100%" }}
                      size="small"
                      value={
                        configTabSelector.order_partner.partner_reference_id ||
                        ""
                      }
                      label=" "
                      InputLabelProps={{ shrink: false }}
                      id="outlined-adornment-target_assign"
                      onChange={(ev) =>
                        onOrderPartnerChange(
                          ev.target.value,
                          "partner_reference_id"
                        )
                      }
                      variant="outlined"
                    />
                  </Tooltip>
                </Grid>

                <Grid item xs={8}>
                  <Typography>
                    Aggregator
                    <Tooltip title="The aggregator is a type of order source channel. Add an aggregator and generate the access token for integrating this POI with them.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  {aggregatorInput.map((field) => (
                    <div key={field.id}>
                      <Grid container spacing={2}>
                        <Grid item xs={11}>
                          <Autocomplete
                            disablePortal
                            options={accountsList}
                            getOptionLabel={(option) => option.name || ""}
                            renderOption={(props, option) => {
                              return (
                                <li {...props} key={option.id}>
                                  {option.name}
                                </li>
                              );
                            }}
                            autoComplete
                            value={field.aggregatorValue || ""}
                            onChange={(ev, val) =>
                              onAggregatorFieldChange(ev, val, field)
                            }
                            style={{ marginBottom: 12 }}
                            size="small"
                            sx={{ width: "100%" }}
                            disabled={
                              !user.permissions.poi_editPoiConfiguration
                            }
                            renderInput={(params) => (
                              <TextField {...params} label="Account" />
                            )}
                          />
                        </Grid>
                        <Grid item xs={1}>
                          <IconButton
                            disabled={
                              !user.permissions.poi_editPoiConfiguration
                            }
                            onClick={() => handleAggregatorRemove(field)}
                            sx={{
                              marginLeft: "-20px",
                            }}
                          >
                            <DeleteIcon />
                          </IconButton>
                        </Grid>
                      </Grid>

                      <>
                        <div className="d-flex">
                          {!field.token && field.aggregator ? (
                            <p
                              onClick={() =>
                                createAggregator(field.aggregator, field)
                              }
                              style={{
                                paddingTop: 0,
                                paddingBottom: 12,
                                margin: 0,
                                color: "#1976d2",
                                cursor: "pointer",
                                display:
                                  poiListSelector.viewType === "edit"
                                    ? "block"
                                    : "none",
                              }}
                            >
                              {"Generate access token"}
                            </p>
                          ) : (
                            <>
                              <p className="access-token">{field.token}</p>
                              <IconButton
                                style={{
                                  display: field.token ? "inline-flex" : "none",
                                  bottom: "5px",
                                }}
                                onClick={() => copyAccessToken(field.token)}
                              >
                                <ContentCopyIcon />
                              </IconButton>
                            </>
                          )}
                        </div>
                      </>
                    </div>
                  ))}

                  {poiListSelector.viewType === "multiEdit" ||
                  !user.permissions.poi_editPoiConfiguration ? (
                    <Tooltip
                      title={
                        poiListSelector.viewType === "multiEdit" &&
                        "This configuration is disabled for multiple edit of POIs."
                      }
                    >
                      <Link href="#" underline="none">
                        {"Add new aggregator"}
                      </Link>
                    </Tooltip>
                  ) : (
                    <Link
                      className={
                        poiListSelector.viewType === "multiEdit" &&
                        "disable-link"
                      }
                      href="#"
                      underline="none"
                      onClick={handleAddAggregatorFields}
                      disabled={poiListSelector.viewType === "multiEdit"}
                    >
                      {"Add new aggregator"}
                    </Link>
                  )}
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
        {/* <Accordion
          expanded={expanded === "panel12"}
          onChange={handleChange("panel12")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel12a-content"
            id="panel12a-header"
            sx={{
              backgroundColor: "#ccc",
            }}
          >
            <Typography>SLAs</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography>
                  Use formula based SOS calculation
                  <Tooltip title="When selected, formula which is configured below will be used to calculate the SOS (Speed of Service). SOS is the time from expected start to end of the delivery of an order.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.sos_compliance == "true" || false
                      }
                      value={configTabSelector.sos_compliance}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "sos_compliance"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.sos_compliance == "false"
                      }
                    />
                  }
                  label=""
                />
              </Grid>

              <Grid item xs={4}>
                <Typography>
                  Expected SOS start time
                  <Tooltip title="Add formula for calculating SOS (Speed of Service) start time">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {Object.keys(startSOS).map((newKey) => (
                  <div style={{ marginBottom: 10 }} key={newKey}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={startSOS[newKey]["field"] === "buffer" ? 3 : 5}
                      >
                        <TextField
                          size="small"
                          select
                          variant="outlined"
                          label="Select"
                          sx={{ width: "100%" }}
                          onChange={(ev) =>
                            onScheduleChange(
                              newKey,
                              ev.target.value,
                              "startSOS"
                            )
                          }
                          value={startSOS[newKey]["is_sheduled"]}
                          disabled={
                            !user.permissions.poi_editPoiConfiguration ||
                            clientConfigsSelector.expected_sos_start == "false"
                          }
                        >
                          <MenuItem value={"1"}>Scheduled Yes</MenuItem>
                          <MenuItem value={"0"}>Scheduled No/NA</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid
                        item
                        xs={startSOS[newKey]["field"] === "buffer" ? 4 : 5}
                      >
                        <TextField
                          size="small"
                          select
                          variant="outlined"
                          label="Select"
                          sx={{ width: "100%" }}
                          onChange={(ev) =>
                            onScheduleOptionChange(
                              newKey,
                              ev.target.value,
                              "startSOS"
                            )
                          }
                          value={startSOS[newKey]["field"]}
                          disabled={
                            !user.permissions.poi_editPoiConfiguration ||
                            clientConfigsSelector.expected_sos_start == "false"
                          }
                        >
                          <MenuItem value={"order_creation_time"}>
                            Order Creation Time
                          </MenuItem>
                          <MenuItem value={"order_creation_at_client"}>
                            Order Creation at Client Time
                          </MenuItem>
                          <MenuItem value={"planned_delivery"}>
                            Planned Delivery Time
                          </MenuItem>
                          <MenuItem value={"planned_start"}>
                            Planned Start Time
                          </MenuItem>
                          <MenuItem value={"target_sos"}>Target SOS</MenuItem>
                          <MenuItem value={"preparation_time"}>
                            Food Preparation Time
                          </MenuItem>
                          <MenuItem value={"buffer"}>Buffer Time</MenuItem>
                          <MenuItem value={"ready_time"}>Ready Time</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display:
                            startSOS[newKey]["field"] === "buffer"
                              ? "block"
                              : "none",
                        }}
                        xs={3}
                      >
                        <TextField
                          sx={{ width: "100%" }}
                          size="small"
                          type="number"
                          value={startSOS[newKey]["value"] || ""}
                          InputProps={{
                            inputProps: {
                              max: 60,
                              min: 0,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                {" "}
                                mins
                              </InputAdornment>
                            ),
                          }}
                          label="Enter"
                          onChange={(ev) =>
                            handleBufferChange(
                              newKey,
                              ev.target.value,
                              "startSOS"
                            )
                          }
                          disabled={
                            !user.permissions.poi_editPoiConfiguration ||
                            clientConfigsSelector.expected_sos_start == "false"
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          disabled={
                            !user.permissions.poi_editPoiConfiguration ||
                            clientConfigsSelector.expected_sos_start == "false"
                          }
                          onClick={() =>
                            handleRemoveSosFields(newKey, "startSOS")
                          }
                          sx={{
                            marginLeft: "-20px",
                            float: "right",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                      {startSOS[newKey].formula.map((el, index) => (
                        <>
                          <Grid item xs={3}>
                            <TextField
                              size="small"
                              select
                              variant="outlined"
                              label="Select"
                              sx={{ width: "100%" }}
                              onChange={(ev) =>
                                onOperatorChange(
                                  newKey,
                                  index,
                                  ev.target.value,
                                  "startSOS"
                                )
                              }
                              value={el.operation}
                              disabled={
                                !user.permissions.poi_editPoiConfiguration ||
                                clientConfigsSelector.expected_sos_start ==
                                  "false"
                              }
                            >
                              <MenuItem value={"ADD"}>+</MenuItem>
                              <MenuItem value={"MINUS"}>-</MenuItem>
                            </TextField>
                          </Grid>

                          <Grid item xs={el["field"] === "buffer" ? 4 : 7}>
                            <TextField
                              size="small"
                              select
                              variant="outlined"
                              label="Select"
                              sx={{ width: "100%" }}
                              onChange={(ev) =>
                                onOperationChange(
                                  newKey,
                                  index,
                                  ev.target.value,
                                  "startSOS"
                                )
                              }
                              value={el.field}
                              disabled={
                                !user.permissions.poi_editPoiConfiguration ||
                                clientConfigsSelector.expected_sos_start ==
                                  "false"
                              }
                            >
                              <MenuItem value={"order_creation_time"}>
                                Order Creation Time
                              </MenuItem>
                              <MenuItem value={"order_creation_at_client"}>
                                Order Creation at Client Time
                              </MenuItem>
                              <MenuItem value={"planned_delivery"}>
                                Planned Delivery Time
                              </MenuItem>
                              <MenuItem value={"planned_start"}>
                                Planned Start Time
                              </MenuItem>
                              <MenuItem value={"target_sos"}>
                                Target SOS
                              </MenuItem>
                              <MenuItem value={"preparation_time"}>
                                Food Preparation Time
                              </MenuItem>
                              <MenuItem value={"buffer"}>Buffer Time</MenuItem>
                              <MenuItem value={"ready_time"}>
                                Ready Time
                              </MenuItem>
                            </TextField>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display:
                                el["field"] === "buffer" ? "block" : "none",
                            }}
                            xs={3}
                          >
                            <TextField
                              sx={{ width: "100%" }}
                              size="small"
                              type="number"
                              value={el.value || ""}
                              InputProps={{
                                inputProps: {
                                  max: 60,
                                  min: 0,
                                },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {" "}
                                    mins
                                  </InputAdornment>
                                ),
                              }}
                              label="Enter"
                              onChange={(ev) =>
                                handleFormulaBufferChange(
                                  newKey,
                                  index,
                                  ev.target.value,
                                  "startSOS"
                                )
                              }
                              disabled={
                                !user.permissions.poi_editPoiConfiguration ||
                                clientConfigsSelector.expected_sos_start ==
                                  "false"
                              }
                            />
                          </Grid>

                          <Grid item xs={2}>
                            <IconButton
                              disabled={
                                !user.permissions.poi_editPoiConfiguration ||
                                clientConfigsSelector.expected_sos_start ==
                                  "false"
                              }
                              onClick={() =>
                                handleRemoveFormulaFields(
                                  newKey,
                                  index,
                                  "startSOS"
                                )
                              }
                              sx={{
                                marginLeft: "-20px",
                                float: "right",
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        </>
                      ))}

                      <Grid item xs={12}>
                        <IconButton
                          disabled={
                            !user.permissions.poi_editPoiConfiguration ||
                            clientConfigsSelector.expected_sos_start == "false"
                          }
                          style={{ float: "right" }}
                          onClick={() =>
                            handleAddFormulaFields(newKey, "startSOS")
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ))}
                {!user.permissions.poi_editPoiConfiguration ? (
                  <Link
                    href="#"
                    underline="none"
                    sx={{ float: "right" }}
                    style={{
                      display:
                        Object.keys(startSOS).length < 2 ? "block" : "none",
                    }}
                  >
                    {"Add new Line"}
                  </Link>
                ) : (
                  <Link
                    href="#"
                    sx={{ float: "right" }}
                    underline="none"
                    onClick={() => addNewSos("startSOS")}
                    style={{
                      display:
                        Object.keys(startSOS).length < 2 ? "block" : "none",
                    }}
                  >
                    {"Add New Line"}
                  </Link>
                )}
              </Grid>

              <Grid item xs={4}>
                <Typography>
                  Expected SOS end time
                  <Tooltip title="Add formula for calculating SOS (Speed of Service) end time">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={8}>
                {Object.keys(endSOS).map((newKey) => (
                  <div style={{ marginBottom: 10 }} key={newKey}>
                    <Grid container spacing={2}>
                      <Grid
                        item
                        xs={endSOS[newKey]["field"] === "buffer" ? 3 : 5}
                      >
                        <TextField
                          size="small"
                          select
                          variant="outlined"
                          label="Select"
                          sx={{ width: "100%" }}
                          onChange={(ev) =>
                            onScheduleChange(newKey, ev.target.value, "endSOS")
                          }
                          value={endSOS[newKey]["is_sheduled"]}
                          disabled={
                            !user.permissions.poi_editPoiConfiguration ||
                            clientConfigsSelector.expected_sos_end == "false"
                          }
                        >
                          <MenuItem value={"1"}>Scheduled Yes</MenuItem>
                          <MenuItem value={"0"}>Scheduled No/NA</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid
                        item
                        xs={endSOS[newKey]["field"] === "buffer" ? 4 : 5}
                      >
                        <TextField
                          size="small"
                          select
                          variant="outlined"
                          label="Select"
                          sx={{ width: "100%" }}
                          onChange={(ev) =>
                            onScheduleOptionChange(
                              newKey,
                              ev.target.value,
                              "endSOS"
                            )
                          }
                          disabled={
                            !user.permissions.poi_editPoiConfiguration ||
                            clientConfigsSelector.expected_sos_end == "false"
                          }
                          value={endSOS[newKey]["field"]}
                        >
                          <MenuItem value={"order_creation_time"}>
                            Order Creation Time
                          </MenuItem>
                          <MenuItem value={"order_creation_at_client"}>
                            Order Creation at Client Time
                          </MenuItem>
                          <MenuItem value={"planned_delivery"}>
                            Planned Delivery Time
                          </MenuItem>
                          <MenuItem value={"planned_start"}>
                            Planned Start Time
                          </MenuItem>
                          <MenuItem value={"target_sos"}>Target SOS</MenuItem>
                          <MenuItem value={"preparation_time"}>
                            Food Preparation Time
                          </MenuItem>
                          <MenuItem value={"buffer"}>Buffer Time</MenuItem>
                          <MenuItem value={"ready_time"}>Ready Time</MenuItem>
                        </TextField>
                      </Grid>
                      <Grid
                        item
                        style={{
                          display:
                            endSOS[newKey]["field"] === "buffer"
                              ? "block"
                              : "none",
                        }}
                        xs={3}
                      >
                        <TextField
                          sx={{ width: "100%" }}
                          size="small"
                          type="number"
                          value={endSOS[newKey]["value"] || ""}
                          InputProps={{
                            inputProps: {
                              max: 60,
                              min: 0,
                            },
                            endAdornment: (
                              <InputAdornment position="end">
                                {" "}
                                mins
                              </InputAdornment>
                            ),
                          }}
                          label="Enter"
                          onChange={(ev) =>
                            handleBufferChange(
                              newKey,
                              ev.target.value,
                              "endSOS"
                            )
                          }
                          disabled={
                            !user.permissions.poi_editPoiConfiguration ||
                            clientConfigsSelector.expected_sos_end == "false"
                          }
                        />
                      </Grid>
                      <Grid item xs={2}>
                        <IconButton
                          onClick={() =>
                            handleRemoveSosFields(newKey, "endSOS")
                          }
                          sx={{
                            marginLeft: "-20px",
                            float: "right",
                          }}
                        >
                          <CloseIcon />
                        </IconButton>
                      </Grid>
                      {endSOS[newKey].formula.map((el, index) => (
                        <>
                          <Grid item xs={3}>
                            <TextField
                              size="small"
                              select
                              variant="outlined"
                              label="Select"
                              sx={{ width: "100%" }}
                              onChange={(ev) =>
                                onOperatorChange(
                                  newKey,
                                  index,
                                  ev.target.value,
                                  "endSOS"
                                )
                              }
                              value={el.operation}
                              disabled={
                                !user.permissions.poi_editPoiConfiguration ||
                                clientConfigsSelector.expected_sos_end ==
                                  "false"
                              }
                            >
                              <MenuItem value={"ADD"}>+</MenuItem>
                              <MenuItem value={"MINUS"}>-</MenuItem>
                            </TextField>
                          </Grid>

                          <Grid item xs={el["field"] === "buffer" ? 4 : 7}>
                            <TextField
                              size="small"
                              select
                              variant="outlined"
                              label="Select"
                              sx={{ width: "100%" }}
                              onChange={(ev) =>
                                onOperationChange(
                                  newKey,
                                  index,
                                  ev.target.value,
                                  "endSOS"
                                )
                              }
                              value={el.field}
                              disabled={
                                !user.permissions.poi_editPoiConfiguration ||
                                clientConfigsSelector.expected_sos_end ==
                                  "false"
                              }
                            >
                              <MenuItem value={"order_creation_time"}>
                                Order Creation Time
                              </MenuItem>
                              <MenuItem value={"order_creation_at_client"}>
                                Order Creation at Client Time
                              </MenuItem>
                              <MenuItem value={"planned_delivery"}>
                                Planned Delivery Time
                              </MenuItem>
                              <MenuItem value={"planned_start"}>
                                Planned Start Time
                              </MenuItem>
                              <MenuItem value={"target_sos"}>
                                Target SOS
                              </MenuItem>
                              <MenuItem value={"preparation_time"}>
                                Food Preparation Time
                              </MenuItem>
                              <MenuItem value={"buffer"}>Buffer Time</MenuItem>
                              <MenuItem value={"ready_time"}>
                                Ready Time
                              </MenuItem>
                            </TextField>
                          </Grid>

                          <Grid
                            item
                            style={{
                              display:
                                el["field"] === "buffer" ? "block" : "none",
                            }}
                            xs={3}
                          >
                            <TextField
                              sx={{ width: "100%" }}
                              size="small"
                              type="number"
                              value={el.value || ""}
                              InputProps={{
                                inputProps: {
                                  max: 60,
                                  min: 0,
                                },
                                endAdornment: (
                                  <InputAdornment position="end">
                                    {" "}
                                    mins
                                  </InputAdornment>
                                ),
                              }}
                              label="Enter"
                              onChange={(ev) =>
                                handleFormulaBufferChange(
                                  newKey,
                                  index,
                                  ev.target.value,
                                  "endSOS"
                                )
                              }
                              disabled={
                                !user.permissions.poi_editPoiConfiguration ||
                                clientConfigsSelector.expected_sos_end ==
                                  "false"
                              }
                            />
                          </Grid>

                          <Grid item xs={2}>
                            <IconButton
                              disabled={
                                !user.permissions.poi_editPoiConfiguration ||
                                clientConfigsSelector.expected_sos_end ==
                                  "false"
                              }
                              onClick={() =>
                                handleRemoveFormulaFields(
                                  newKey,
                                  index,
                                  "endSOS"
                                )
                              }
                              sx={{
                                marginLeft: "-20px",
                                float: "right",
                              }}
                            >
                              <CloseIcon />
                            </IconButton>
                          </Grid>
                        </>
                      ))}
                      <Grid item xs={12}>
                        <IconButton
                          disabled={
                            !user.permissions.poi_editPoiConfiguration ||
                            clientConfigsSelector.expected_sos_end == "false"
                          }
                          style={{ float: "right" }}
                          onClick={() =>
                            handleAddFormulaFields(newKey, "endSOS")
                          }
                        >
                          <AddIcon />
                        </IconButton>
                      </Grid>
                    </Grid>
                  </div>
                ))}
                {!user.permissions.poi_editPoiConfiguration ? (
                  <Link
                    href="#"
                    sx={{ float: "right" }}
                    underline="none"
                    style={{
                      display:
                        Object.keys(endSOS).length < 2 ? "block" : "none",
                    }}
                  >
                    {"Add New Line"}
                  </Link>
                ) : (
                  <Link
                    href="#"
                    sx={{ float: "right" }}
                    underline="none"
                    onClick={() => addNewSos("endSOS")}
                    style={{
                      display:
                        Object.keys(endSOS).length < 2 ? "block" : "none",
                    }}
                  >
                    {"Add New Line"}
                  </Link>
                )}
              </Grid>

              <Grid item xs={8}>
                <Typography>
                  SOS to be compared against
                  <Tooltip title="Configured target SOS option checks SOS (Speed of Service) against target SOS configured at the POI level. SOS calculated through expected start & end time option checks SOS against the time between SOS start & end time based on the formula.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <RadioGroup
                  aria-labelledby="demo-radio-buttons-group-label"
                  defaultValue="female"
                  name="radio-buttons-group"
                >
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          configTabSelector.sos_compared_against ===
                            "Configured SOS" || false
                        }
                        value={configTabSelector.sos_compared_against}
                        onChange={() =>
                          handleInputChange(
                            "Configured SOS",
                            "sos_compared_against"
                          )
                        }
                        disabled={
                          !user.permissions.poi_editPoiConfiguration ||
                          clientConfigsSelector.sos_compared_against == ""
                        }
                      />
                    }
                    label="Configured SOS"
                  />
                  <FormControlLabel
                    control={
                      <Radio
                        checked={
                          configTabSelector.sos_compared_against ===
                            "SOS calculated by expected start & end time" ||
                          false
                        }
                        value={configTabSelector.sos_compared_against}
                        onChange={() =>
                          handleInputChange(
                            "SOS calculated by expected start & end time",
                            "sos_compared_against"
                          )
                        }
                        disabled={
                          !user.permissions.poi_editPoiConfiguration ||
                          clientConfigsSelector.sos_compared_against == ""
                        }
                      />
                    }
                    label="SOS calculated by expected start & end time"
                  />
                </RadioGroup>
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion
          expanded={expanded === "panel13"}
          onChange={handleChange("panel13")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel13a-content"
            id="panel12a-header"
            sx={{
              backgroundColor: "#ccc",
            }}
          >
            <Typography>Zone Controller</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography>Include Waiting to Confirm orders in ZC</Typography>
              </Grid>
              <Grid item xs={4}>
                <FormControlLabel
                  control={
                    <Checkbox
                      inputProps={{ "aria-label": "controlled" }}
                      checked={
                        configTabSelector.zc_waiting_orders == "true" || false
                      }
                      value={configTabSelector.zc_waiting_orders}
                      onChange={(ev) =>
                        handleInputChange(
                          ev.target.checked.toString(),
                          "zc_waiting_orders"
                        )
                      }
                      disabled={
                        !user.permissions.poi_editPoiConfiguration ||
                        clientConfigsSelector.zc_waiting_orders == "false"
                      }
                    />
                  }
                  label=""
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion> */}

        {/* <Accordion
          expanded={expanded === "panel14"}
          onChange={handleChange("panel14")}
        >
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel14a-content"
            id="panel14a-header"
            sx={{
              backgroundColor: "#ccc",
            }}
          >
            <Typography>Delivery Partner Config</Typography>
          </AccordionSummary>
          <AccordionDetails>
            <Grid container spacing={2}>
              <Grid item xs={8}>
                <Typography>
                  Enable Delivery Partner Auto Assignment
                  <Tooltip title="Enabling this config will allow the system to automatically assign an order to a DP.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <Switch
                  checked={
                    configTabSelector.is_delivery_partner_auto_assignment_enabled ==
                      "true" || false
                  }
                  onChange={(ev) =>
                    handleInputChange(
                      ev.target.checked.toString(),
                      "is_delivery_partner_auto_assignment_enabled"
                    )
                  }
                  inputProps={{ "aria-label": "controlled" }}
                  value={
                    configTabSelector.is_delivery_partner_auto_assignment_enabled
                  }
                  disabled={
                    !user.permissions.poi_editPoiConfiguration ||
                    clientConfigsSelector.enable_delivery_partner == "false"
                  }
                />
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  DOD Request Indication Time
                  <Tooltip title="Target time for an order to get assigned to a driver from DOD, if no own fleet driver is assigned.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  sx={{ width: "100%" }}
                  size="small"
                  type="number"
                  value={configTabSelector.dod_request_time || ""}
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="end"> mins</InputAdornment>
                    ),
                  }}
                  label="Enter"
                  onChange={(ev) =>
                    handleInputChange(ev.target.value, "dod_request_time")
                  }
                  disabled={!user.permissions.poi_editPoiConfiguration}
                />
              </Grid>
              <Grid item xs={8}>
                <Typography>
                  Time to Switch Delivery Partner
                  <Tooltip title="This will allow users to set a time to switch to another DP if a driver is not assigned from the previous DP within the allowed time.">
                    <IconButton>
                      <InfoIcon />
                    </IconButton>
                  </Tooltip>
                </Typography>
              </Grid>
              <Grid item xs={4}>
                <TextField
                  sx={{ width: "100%" }}
                  size="small"
                  type="number"
                  value={
                    configTabSelector.switching_time_delivery_partner || ""
                  }
                  InputProps={{
                    inputProps: {
                      min: 0,
                    },
                    endAdornment: (
                      <InputAdornment position="end"> mins</InputAdornment>
                    ),
                  }}
                  label="Enter"
                  onChange={(ev) =>
                    handleInputChange(
                      ev.target.value,
                      "switching_time_delivery_partner"
                    )
                  }
                  disabled={!user.permissions.poi_editPoiConfiguration}
                />
              </Grid>
            </Grid>
          </AccordionDetails>
        </Accordion> */}

        {!configService.isDeliveryPartnerView(user.permissions) && (
          <Accordion
            expanded={expanded === "panel15"}
            onChange={handleChange("panel15")}
          >
            <AccordionSummary
              expandIcon={<ExpandMoreIcon />}
              aria-controls="panel15a-content"
              id="panel15a-header"
              sx={{
                backgroundColor: "#ccc",
              }}
            >
              <Typography>Others</Typography>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container spacing={2}>
                <Grid item xs={8}>
                  <Typography>
                    Compliance for pick up
                    <Tooltip title="When selected, driver needs to mark the order as picked up when the driver is within the store radius. It helps to reduce false marking of pickup.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={
                          configTabSelector.compliance_pickup == "true" || false
                        }
                        value={configTabSelector.compliance_pickup}
                        onChange={(ev) =>
                          handleInputChange(
                            ev.target.checked.toString(),
                            "compliance_pickup"
                          )
                        }
                        disabled={
                          !user.permissions.poi_editPoiConfiguration ||
                          clientConfigsSelector.compliance_pickup == "false"
                        }
                      />
                    }
                    label=""
                  />
                </Grid>

                <Grid item xs={8}>
                  <Typography>
                    Compliance for delivery
                    <Tooltip title="When selected, driver needs to mark the order as delivered when the driver is within the customer location radius. It helps to reduce false marking of delivery.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={
                          configTabSelector.compliance_delivery == "true" ||
                          false
                        }
                        value={configTabSelector.compliance_delivery}
                        onChange={(ev) =>
                          handleInputChange(
                            ev.target.checked.toString(),
                            "compliance_delivery"
                          )
                        }
                        disabled={
                          !user.permissions.poi_editPoiConfiguration ||
                          clientConfigsSelector.compliance_delivery == "false"
                        }
                      />
                    }
                    label=""
                  />
                </Grid>

                <Grid item xs={8}>
                  <Typography>
                    Mark this POI as a cash collection center
                    <Tooltip title="It helps to mark a POI as a cash collection center. Cashiers will be collecting cash from here.">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={
                          configTabSelector.is_cash_center == "true" || false
                        }
                        value={configTabSelector.is_cash_center}
                        onChange={(ev) =>
                          handleInputChange(
                            ev.target.checked.toString(),
                            "is_cash_center"
                          )
                        }
                        disabled={!user.permissions.poi_editPoiConfiguration}
                      />
                    }
                    label=""
                  />
                </Grid>

                {/* Delivery Area Validation */}
                <Grid item xs={8}>
                  <Typography>
                    Delivery Area Validation
                    <Tooltip title="If this is enabled orders will only be accepted from pre-configured delivery areas">
                      <IconButton>
                        <InfoIcon />
                      </IconButton>
                    </Tooltip>
                  </Typography>
                </Grid>
                <Grid item xs={4}>
                  <FormControlLabel
                    control={
                      <Checkbox
                        inputProps={{ "aria-label": "controlled" }}
                        checked={
                          detailsTabSelector.is_force_del_area == "1" ||
                          detailsTabSelector.is_force_del_area == "true" ||
                          false
                        }
                        value={detailsTabSelector.is_force_del_area}
                        onChange={(ev) =>
                          handleDetailsInputChange(
                            ev.target.checked.toString(),
                            "is_force_del_area"
                          )
                        }
                        disabled={!user.permissions.poi_editPoiConfiguration}
                      />
                    }
                    label=""
                  />
                </Grid>
              </Grid>
            </AccordionDetails>
          </Accordion>
        )}
      </div>
    </>
  );
};

export default PoiAdvancedConfig;
