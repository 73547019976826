import "./App.css";
import PoiList from "./pages/PoiList/PoiList";

function App() {
  return (
    <PoiList />
  );
}

export default App;
